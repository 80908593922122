import NoRSVPComing from "src/assets/icons/NoRSVPComing";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
import styles from "./styles.module.scss";

function EmptyState() {
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        <Icon iconName="bulb" />

        <p className={styles["EmptyState--title"]}>
          Not Coming shows the guests who have declined your invitation.
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        <NoRSVPComing />

        <p>Your guests haven't reserved a spot at your event yet.</p>
      </div>
    </div>
  );
}

export default EmptyState;
