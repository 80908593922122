import { useQuery } from "@apollo/client";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import toast from "react-hot-toast";
import Bubble from "src/components/Bubble";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import { InvitationListByCategory } from "src/graphql/queries/invitations.queries";
import useAppStore from "src/store/utils";
import {
  invitationListByCategory,
  noGuestPerCategory,
  noGuestPerCategory_noGuestPerCategory,
} from "src/types/api";
import { v4 as uuidv4 } from "uuid";
import AddGuest from "../../../invitations/invite-list/modals/AddGuest";
import EditCategory from "../modals/EditCategory";
import RemoveCategory from "../modals/RemoveCategory";
import SetMessages from "../modals/SetMessages";
import ViewGuests from "../modals/ViewGuests";
import categoriesCard from "./categoriesCard.module.scss";

interface CategoriesCardProps {
  category: noGuestPerCategory_noGuestPerCategory | null;
  categories: noGuestPerCategory | null;
}

const CategoriesCard: React.FC<CategoriesCardProps> = ({
  category,
  categories,
}) => {
  const [openAddGuest, setOpenAddGuest] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openMessages, setOpenMessages] = useState<boolean>(false);
  const [openGuests, setOpenGuests] = useState<boolean>(false);

  const { event } = useAppStore((state) => state);

  const { data: guestList } = useQuery<invitationListByCategory>(
    InvitationListByCategory,
    {
      variables: {
        eventId: event.id,
        categoryId: category?.category_id,
        page: 1,
        pageSize: 100,
      },
      onCompleted() {},
      fetchPolicy: "cache-first",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        }
      },
    }
  );

  return (
    <div className={categoriesCard["categoriesCard"]}>
      <div className={categoriesCard["categoriesCard__circle__group"]}>
        {!!guestList &&
          !!guestList?.invitationListByCategory.length &&
          guestList?.invitationListByCategory.length > 0 &&
          guestList?.invitationListByCategory
            .slice(0, 3)
            .map((item) => (
              <Bubble
                key={uuidv4()}
                side="top"
                firstName={item.first_name}
                lastName={item.last_name}
              />
            ))}

        {!!guestList &&
          !!guestList?.invitationListByCategory.length &&
          guestList?.invitationListByCategory.length > 3 && (
            <div className={categoriesCard["categoriesCard__circle"]}>
              {Number(category?.num_guest) - 3}+
            </div>
          )}
      </div>

      <div
        className={
          categoriesCard["categoriesCard__bottom"] +
          " " +
          "sm:absolute sm:bottom-0 sm:px-[18px] sm:pb-6"
        }
      >
        <h4>
          {category?.category_name === "regular"
            ? "Regular"
            : category?.category_name}
        </h4>

        <div className={categoriesCard["categoriesCard__dropdown-container"]}>
          <Popover.Root>
            <Popover.Trigger
              className={
                categoriesCard["categoriesCard__dropdown-container-button"]
              }
            >
              <Icon iconName="kebab" />
              <span className="visually-hidden">open dropdown</span>
            </Popover.Trigger>
            <Popover.Content
              className={categoriesCard["categoriesCard__dropdown"]}
            >
              <div className={categoriesCard["categoriesCard__dropdown-list"]}>
                {!!category && (
                  <>
                    <DialogModal
                      trigger={
                        <div className={categoriesCard["categoriesCard__edit"]}>
                          Add Guest
                        </div>
                      }
                      open={openAddGuest}
                      onOpenChange={setOpenAddGuest}
                    >
                      <AddGuest
                        categories={categories}
                        category={category}
                        setOpen={setOpenAddGuest}
                        page={1}
                        pageSize={6}
                      />
                    </DialogModal>
                    {category?.category_name.toLowerCase() !== "regular" && (
                      <DialogModal
                        trigger={
                          <div
                            className={categoriesCard["categoriesCard__edit"]}
                          >
                            Edit Category
                          </div>
                        }
                        open={openEdit}
                        onOpenChange={setOpenEdit}
                      >
                        <EditCategory
                          category={category}
                          setOpen={setOpenEdit}
                        />
                      </DialogModal>
                    )}
                    {category?.category_name.toLowerCase() !== "regular" && (
                      <DialogModal
                        trigger={
                          <div
                            className={categoriesCard["categoriesCard__edit"]}
                          >
                            Set Message
                          </div>
                        }
                        open={openMessages}
                        onOpenChange={setOpenMessages}
                      >
                        <SetMessages
                          category={category}
                          setOpen={setOpenMessages}
                        />
                      </DialogModal>
                    )}
                    <DialogModal
                      trigger={
                        <div className={categoriesCard["categoriesCard__edit"]}>
                          View Guests
                        </div>
                      }
                      open={openGuests}
                      onOpenChange={setOpenGuests}
                    >
                      <ViewGuests
                        category={category}
                        setOpen={setOpenGuests}
                        guestList={guestList}
                      />
                    </DialogModal>
                    {category?.category_name.toLowerCase() !== "regular" && (
                      <DialogModal
                        trigger={
                          <div
                            className={categoriesCard["categoriesCard__remove"]}
                          >
                            Delete Category
                          </div>
                        }
                        open={openDelete}
                        onOpenChange={setOpenDelete}
                      >
                        <RemoveCategory
                          category={category}
                          setOpen={setOpenDelete}
                        />
                      </DialogModal>
                    )}
                  </>
                )}
              </div>
            </Popover.Content>
          </Popover.Root>
        </div>
      </div>
    </div>
  );
};

export default CategoriesCard;
