import { isEmpty } from "lodash";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Dialog, DialogContent } from "src/components/CustomDialog";
import Video from "src/components/Video";
import { cn } from "src/lib/utils";
import { GetPov } from "src/types/api";

const GalleryModal = ({
  selectedPov,
  closeModal,
  selectedItem,
  setSelectedItem,
}: {
  selectedPov?: Partial<GetPov["getPov"][0]>;
  closeModal: () => void;
  selectedItem?: {
    item: GetPov["getPov"][0]["media"][0];
    group: GetPov["getPov"][0]["media"];
  };
  setSelectedItem: React.Dispatch<
    React.SetStateAction<
      | {
          item: GetPov["getPov"][0]["media"][0];
          group: GetPov["getPov"][0]["media"];
        }
      | undefined
    >
  >;
}) => {
  return (
    <Dialog open={!isEmpty(selectedPov)} onOpenChange={closeModal}>
      <DialogContent
        className="bg-modal-liners shadow-inawo-3 transition-height fixed z-50 grid h-screen w-screen max-w-none place-items-center overflow-hidden !bg-transparent p-[1px] backdrop-blur-lg duration-300 ease-in-out"
        closeBtnClassName="rounded-full p-[18px]  dark:!bg-black/50 !bg-[#49494966] w-fit mx-auto left-0  md:left-auto [&_svg]:!text-white"
      >
        <div className="w-full">
          <div className="max-w max-h relative mx-auto flex items-center justify-center overflow-hidden md:h-[483px] md:w-[784px]">
            {selectedItem?.item?.type?.includes("image/") ? (
              <LazyLoadImage
                src={selectedItem?.item?.url as string}
                alt="pov-image"
                className="h-full w-full object-cover"
              />
            ) : selectedItem?.item?.type?.includes("video/") ? (
              <Video
                autoPlay
                controls
                preload="none"
                url={selectedItem?.item?.url as string}
              />
            ) : (
              <div className="bg-inawo-black-500 h-full w-full"></div>
            )}
          </div>
          <div className="absolute bottom-[13px] left-1/2 mx-auto w-screen -translate-x-1/2 transform">
            <div className="scrollbar-hide mx-2 flex flex-nowrap items-center justify-center space-x-4 overflow-x-auto md:mx-6">
              {selectedItem?.group.map((item, i) => (
                <div
                  className={cn(
                    "relative flex flex-none items-center justify-center overflow-hidden",
                    selectedItem?.item?.id === item.id
                      ? "h-[85px] w-[85px] transition-all duration-300 ease-in-out"
                      : "h-[68px] w-[68px] transition-all duration-300 ease-in-out hover:h-[85px] hover:w-[85px]"
                  )}
                  key={i}
                  onClick={() => {
                    setSelectedItem({ item, group: selectedItem?.group });
                  }}
                >
                  {item?.type?.includes("image/") ? (
                    <LazyLoadImage
                      src={item?.url as string}
                      alt="pov-image"
                      className="h-full w-full object-cover"
                    />
                  ) : item?.type?.includes("video/") ? (
                    <Video url={item?.url as string} />
                  ) : (
                    <div className="bg-inawo-black-500 h-full w-full"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GalleryModal;
