import { useMutation } from "@apollo/client";
import { Dispatch, useState } from "react";
import toast from "react-hot-toast";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { ResetWalletPin } from "src/graphql/mutations/wallets.mutations";
import { resetWalletPin, resetWalletPinVariables } from "src/types/api";
import styles from "./confirmPin.module.scss";

interface WalletPinProps {
  setOpenResetPin: Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  pin: string;
  passcode: any;
}

const ConfirmPinReset = ({
  setOpenResetPin,
  eventId,
  pin,
  passcode,
}: WalletPinProps) => {
  const navigate = useNavigate();

  const [resetPinFn, { loading, error }] = useMutation<
    resetWalletPin,
    resetWalletPinVariables
  >(ResetWalletPin, {
    onCompleted(res) {
      toast.success(<p>Your Pin has been set.</p>);
      setOpenResetPin(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });
  const [confirmPin, setConfirmPin] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (confirmPin === pin) {
      resetPinFn({
        variables: {
          eventId: eventId,
          passcode: passcode,
          newPin: pin,
        },
      });
    } else {
      toast.error(<p className="toast">Pins do not match!</p>);
    }
  };

  return (
    <div className={styles["confirmPin__main"]}>
      <div className={styles["confirmPin__main-container"]}>
        <div className={styles["confirmPin__main-container-inner"]}></div>
        <div className={styles["confirmPin__title"]}>
          <h1>Confirm PIN</h1>
          <p>You would need a 4-digit pin to perform transactions</p>
        </div>
        <form
          onSubmit={handleSubmit}
          className={styles["confirmPin__field-form"]}
        >
          <PinInput
            length={4}
            focus
            initialValue=""
            secret
            onChange={(value, index) => {
              setConfirmPin(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "12px",
              display: " flex",
              justifyContent: " center",
              alignItems: " center",
              gap: "0.5rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            inputStyle={{
              borderColor: "#808080",
              width: "17.5%",
              height: "78px",
              fontSize: "2.25rem",
              borderRadius: "2px",
            }}
            inputFocusStyle={{
              borderColor: "#1fad3e",
              caretColor: "#1fad3e",
            }}
            onComplete={(value, index) => {
              if (value === pin) {
                resetPinFn({
                  variables: {
                    newPin: value,
                    eventId: eventId,
                    passcode: passcode,
                  },
                });
              } else {
                toast.error("Pins do not match");
              }
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <div className={styles["confirmPin__button"]}>
            <Button loader={loading && !error} type="submit" label="I'm done">
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmPinReset;
