import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Addmore from "src/assets/icons/add";
import CollapseIcon from "src/assets/icons/collapse";
import ExpandIcon from "src/assets/icons/expand";
import ImageGallery from "src/components/AddPictures/ImageGallery";
import UploadBox from "src/components/AddPictures/UploadBox";
import UploadingView from "src/components/AddPictures/Uploading";
import CollapsedUpload from "src/components/AddPictures/Uploading/Collapsed";
import Button from "src/components/Button";
import DialogModal from "src/components/Dialog";
import Input from "src/components/Input";
import CustomSelect from "src/components/InputSelect";
import {
  CreateAlbum,
  GetMediaPresignedUrl,
  VerifyMediaUpload,
  AddMediaToAlbum as addMediaToAlbum,
} from "src/graphql/mutations";
import {
  GetMedia,
  GetMediaTags,
  GetAlbums as getAlbums,
} from "src/graphql/queries";
import { getFileDimension, objectKeyToWord } from "src/helper/functions";
import useMediaUpload from "src/hooks/useMediaUpload";
import useAppStore from "src/store/utils";
import {
  GetMediaPresignedUrlVariables,
  VerifyMediaUploadVariables,
  GetMediaPresignedUrl as getMediaPresignedUrl,
  VerifyMediaUpload as verifyMediaUpload,
} from "src/types/api";
import {
  AddMediaToAlbum,
  AddMediaToAlbumVariables,
  AlbumMediaTag,
  GetAlbums,
  createAlbum,
  createAlbumVariables,
} from "src/types/api.d";
import {
  AddPicturesProps,
  TagType,
  UploadResult,
  UploadValue,
} from "src/utils/types";
import styles from "./addPictures.module.scss";

type FormValues = {
  name?: string;
  description?: string;
};

const AddPictures: React.FC<AddPicturesProps> = ({
  onClose: closePopOver,
  type,
  trigger,
  album,
}) => {
  const [selectedTags, setSelectedTags] = useState<TagType[]>([] as TagType[]);
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const [fileUploaded, setFileUploaded] = useState<UploadValue>([]);
  const [extraUpload, setExtraUpload] = useState<UploadValue>([]);
  const [submitting, setSubmitting] = useState(false);
  const page = 1;
  const pageSize = 200;

  const [selectedAlbum, setSelectedAlbum] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >(undefined);
  const [collapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState("");
  const [step, setStep] = useState(1);
  const [opened, setOpened] = useState(false);

  const {
    percentageCompleted,
    totalTimeLeft,
    isCompleted,
    handleUpload,
    uploadedMedia,
    totalUploaded,
    setCompleted,
  } = useMediaUpload();

  const { data: albums } = useQuery<GetAlbums>(getAlbums, {
    variables: { eventId: event.uuid },
  });

  const { data: albumtags } = useQuery<{ getMediaTags: AlbumMediaTag[] }>(
    GetMediaTags
  );
  const options = albumtags?.getMediaTags.map((tag) => {
    return { label: objectKeyToWord(tag), value: tag };
  });

  const onCloseModal = () => {
    setStep(1);
    setCollapsed(false);
    setFileUploaded([]);
    setExtraUpload([]);
    setCompleted([]);
  };

  const onClose = () => {
    setOpened(false);
    onCloseModal();
    closePopOver?.();
  };

  const [addMedia, { loading: addMediaLoading }] = useMutation<
    AddMediaToAlbum,
    AddMediaToAlbumVariables
  >(addMediaToAlbum, {
    onCompleted() {
      toast.success(
        <p className="toast">
          {type === "photo"
            ? "You have successfully added photo(s)."
            : "You have successfully added a new album."}
        </p>
      );
      onClose();
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const onUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    addMore?: boolean
  ) => {
    const files = event.target.files as FileList;
    const filesArray = Array.from(files);

    if (addMore) {
      if (filesArray?.length) {
        const uploadedFiles = await Promise.all(
          filesArray.map(async (file) => {
            if (file.type.includes("video")) {
              return {
                preview: URL?.createObjectURL(file),
                file,
              };
            }
            const res = await getFileDimension(file);
            return {
              preview: URL?.createObjectURL(file),
              file,
              height: res.height,
              width: res.width,
            };
          })
        );
        setExtraUpload((prev) => [...prev, ...uploadedFiles]);
      }
      return;
    }

    if (filesArray?.length) {
      const uploadedFiles = await Promise.all(
        filesArray.map(async (file) => {
          if (file.type.includes("video")) {
            return {
              preview: URL?.createObjectURL(file),
              file,
            };
          }
          const res = await getFileDimension(file);
          return {
            preview: URL?.createObjectURL(file),
            file,
            width: res.width,
            height: res.height,
          };
        })
      );
      handleUpload(uploadedFiles);
      setFileUploaded(uploadedFiles);
    } else {
      setFileUploaded([]);
    }
  };

  useEffect(() => {
    if (isCompleted && fileUploaded?.length) {
      setStep(2);
      setActive([...fileUploaded, ...extraUpload][0]?.preview);
      setCollapsed(false);
    }
  }, [extraUpload, fileUploaded, isCompleted]);

  const [uploadMedia] = useMutation<
    getMediaPresignedUrl,
    GetMediaPresignedUrlVariables
  >(GetMediaPresignedUrl);

  const [addAlbum, { loading: addAlbumLoading }] = useMutation<
    createAlbum,
    createAlbumVariables
  >(CreateAlbum);

  const [verifyMediaUpload] = useMutation<
    verifyMediaUpload,
    VerifyMediaUploadVariables
  >(VerifyMediaUpload);

  const handleSubmit = async (values: FormValues) => {
    let errorOccured = false;
    let createResponse: { data?: createAlbum | null } = { data: null };
    setSubmitting(true);

    if (type === "album") {
      createResponse = await addAlbum({
        variables: {
          eventId: event.uuid,
          name: values.name ?? "",
          description: values.description ?? "",
        },
      });
    }

    const extraUploads = [] as UploadResult;
    const uploaded = uploadedMedia.filter((item) =>
      fileUploaded.some((media) => media?.preview === item?.preview)
    );

    if (extraUpload.length) {
      await Promise.all(
        extraUpload.map(async ({ file, preview, width, height }) => {
          try {
            const res = await uploadMedia({
              variables: {
                eventId: event.uuid,
                mediaType: file.type.split("/")[1],
              },
            });
            if (res.data?.getMediaPresignedUrl?.upload_url) {
              await axios.put(
                res.data?.getMediaPresignedUrl?.upload_url,
                file,
                {
                  headers: {
                    "Content-Type": file.type,
                    "x-amz-acl": "public-read",
                  },
                }
              );
              extraUploads.push({
                preview,
                mediaId: res?.data?.getMediaPresignedUrl?.media_id ?? "",
                url: res?.data?.getMediaPresignedUrl?.file_uri ?? "",
                width,
                height,
              });
            }
          } catch (err) {
            setSubmitting(false);
            errorOccured = true;
            toast.error("An error occurred while uploading");
          }
        })
      );
    }

    const totalUploads = [...uploaded, ...extraUploads];
    await Promise.all(
      totalUploads.map(async ({ url, height, width }) => {
        try {
          await verifyMediaUpload({
            variables: {
              eventId: event.uuid,
              fileUri: `${url}?height=${height}&width=${width}`,
              tags: [],
            },
            refetchQueries: [
              {
                query: GetMedia,
                variables: {
                  eventId: event.uuid,
                  filter: { no_album: true, page, pageSize },
                },
              },
              {
                query: GetMedia,
                variables: {
                  eventId: event.uuid,
                  filter: {
                    album_id: album?.id,
                    page,
                    pageSize,
                  },
                },
              },
              {
                query: getAlbums,
                variables: { eventId: event.uuid },
              },
            ],
          });
        } catch (err) {
          errorOccured = true;
          setSubmitting(false);
          toast.error("An error occurred while uploading; please try again");
          return;
        }
      })
    );
    if (errorOccured) return;

    if (
      selectedAlbum?.value ||
      album?.id ||
      createResponse?.data?.createAlbum?.id
    ) {
      await addMedia({
        variables: {
          eventId: event.uuid,
          tags: selectedTags.map((tag) => tag.value) as AlbumMediaTag[],
          albumId:
            createResponse?.data?.createAlbum?.id ??
            selectedAlbum?.value ??
            album?.id ??
            "",
          mediaIds: totalUploads.map((item) => item.mediaId),
        },
        refetchQueries: [
          {
            query: GetMedia,
            variables: {
              eventId: event.uuid,
              filter: { no_album: true, page, pageSize },
            },
          },
          {
            query: GetMedia,
            variables: {
              eventId: event.uuid,
              filter: {
                album_id: album?.id,
                page,
                pageSize,
              },
            },
          },
          {
            query: getAlbums,
            variables: { eventId: event.uuid },
          },
        ],
      });
    } else {
      toast.success(
        <p className="toast">
          {type === "photo"
            ? "You have successfully added photo(s)."
            : "You have successfully added a new album."}
        </p>
      );
      onClose();
    }

    setSubmitting(false);
  };

  return (
    <DialogModal
      trigger={
        trigger
          ? trigger
          : type === "photo"
            ? "Add photos/videos"
            : "Add new album"
      }
      icon={
        [...fileUploaded, ...extraUpload]?.length &&
        step !== 2 &&
        (!collapsed ? <CollapseIcon /> : <ExpandIcon />)
      }
      onCollapse={() => {
        setCollapsed(!collapsed);
      }}
      onOpenChange={(value) => {
        setOpened(value);
        if (!value) {
          onClose?.();
        }
      }}
      open={opened}
    >
      <AnimatePresence initial={false}>
        {!collapsed ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: collapsed ? 0 : "auto",
              opacity: collapsed ? 0 : 1,
            }}
            transition={{ duration: 0.3 }}
            className={styles["addPictures"]}
          >
            <div className={styles["addPictures-heading"]}>
              <h4>{type === "photo" ? "Add Photo/Video" : "Add New Album"}</h4>
            </div>
            <Formik
              onSubmit={handleSubmit}
              initialValues={{
                name: "",
                description: "",
              }}
            >
              {(props) => (
                <Form className={styles["addPictures-form"]}>
                  <div className={styles["addPictures-form-container"]}>
                    {step === 2 ? (
                      <>
                        <div className={styles["addPictures-image-box2"]}>
                          <ImageGallery
                            uploadResult={[...fileUploaded, ...extraUpload]}
                            active={active}
                            onRemoveImage={(preview) => {
                              const removeImage = (
                                uploads: UploadValue,
                                setUploads: Dispatch<
                                  SetStateAction<UploadValue>
                                >
                              ) => {
                                const newArray = uploads.filter(
                                  (item) => item?.preview !== preview
                                );
                                setUploads(newArray);
                              };

                              const allUploads = [
                                ...fileUploaded,
                                ...extraUpload,
                              ];

                              if (
                                fileUploaded.some(
                                  (item) => item?.preview === preview
                                )
                              ) {
                                removeImage(fileUploaded, setFileUploaded);
                              } else if (
                                extraUpload.some(
                                  (item) => item?.preview === preview
                                )
                              ) {
                                removeImage(extraUpload, setExtraUpload);
                              }

                              if (allUploads.length <= 1) setStep(1);

                              setActive(
                                allUploads.find(
                                  (item) => item.preview !== preview
                                )?.preview ?? ""
                              );
                            }}
                          />
                        </div>
                        <div className="relative mb-8 flex h-[70px] w-full items-end">
                          <div className="group flex w-full items-end overflow-x-auto">
                            {[...fileUploaded, ...extraUpload].map(
                              (img, index) => (
                                <div
                                  key={index}
                                  className={`-mr-5 h-[59px] w-[89px] max-w-[89px] flex-shrink-0 transition-all duration-300 ${
                                    img?.preview === active
                                      ? "z-10 h-[71px] w-[89px] filter-none transition-all duration-300"
                                      : ""
                                  } contrast-[60%] filter hover:z-10 hover:h-[71px] hover:w-[100px] hover:filter-none`}
                                >
                                  {img?.file?.type?.includes("video/") ? (
                                    <video
                                      preload="none"
                                      className="h-full w-full rounded-md object-cover transition-all duration-300"
                                      onClick={() => setActive(img?.preview)}
                                    >
                                      <source
                                        src={img?.preview}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={img?.preview}
                                      alt={`Thumbnail ${index + 1}`}
                                      className="h-full w-full rounded-md object-cover transition-all duration-300"
                                      onClick={() => setActive(img?.preview)}
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                          <input
                            id="add-file"
                            multiple
                            type="file"
                            className={styles["file"]}
                            name="image"
                            accept="*"
                            onChange={(e) => onUpload(e, true)}
                            onBlur={props.handleBlur}
                          ></input>
                          <label htmlFor="add-file">
                            <div
                              className="absolute -right-4 top-1/2 z-10 flex -translate-y-1/2 items-center justify-center rounded-md"
                              aria-label="add more images"
                              role="button"
                            >
                              <Addmore />
                            </div>
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        {![...fileUploaded, ...extraUpload]?.length ? (
                          <label
                            className={`${styles["addPictures-image-box"]} cursor-pointer`}
                            htmlFor="add-file"
                          >
                            <UploadBox
                              handleBlur={props.handleBlur}
                              onUpload={onUpload}
                            />
                          </label>
                        ) : (
                          step === 1 && (
                            <div className={styles["addPictures-image-box"]}>
                              <UploadingView
                                fileUploaded={fileUploaded}
                                setFileUploaded={setFileUploaded}
                                setStep={setStep}
                                percentageCompleted={percentageCompleted}
                                totalTimeLeft={totalTimeLeft}
                                isCompleted={isCompleted}
                                setRetryUploads={() =>
                                  handleUpload(fileUploaded)
                                }
                                onClose={onCloseModal}
                              />
                            </div>
                          )
                        )}
                      </>
                    )}

                    {type === "album" && (
                      <Input
                        type="text"
                        name="name"
                        label={"Name your Album"}
                        placeholder="e.g My First Album"
                        required
                      />
                    )}

                    {step === 2 && (
                      <>
                        {!album && (
                          <>
                            {type === "photo" ? (
                              <CustomSelect
                                placeholder="Select an album"
                                options={
                                  albums?.getAlbums?.data.map((album) => ({
                                    label: album.name,
                                    value: album.id,
                                  })) ?? []
                                }
                                value={{
                                  label:
                                    (selectedAlbum?.label ?? "")
                                      .charAt(0)
                                      .toUpperCase() +
                                    (selectedAlbum?.label ?? "").slice(1),
                                  value: selectedAlbum?.value ?? "",
                                }}
                                label={"Album"}
                                onChange={(option) => {
                                  setSelectedAlbum({
                                    label: option?.label ?? "",
                                    value: option?.value ?? "",
                                  });
                                }}
                              />
                            ) : (
                              <Input
                                type="text"
                                name="description"
                                label="Description (optional)"
                                placeholder="Brief description about the album"
                              />
                            )}
                            <CustomSelect
                              placeholder="e.g Bride"
                              isMulti
                              options={options ?? []}
                              closeMenuOnSelect={false}
                              value={selectedTags}
                              label={"Tag"}
                              onChange={(option) => {
                                setSelectedTags([...option]);
                              }}
                            />
                          </>
                        )}
                        <div className={styles["addPictures-button-container"]}>
                          <div className={styles["addPictures-button"]}>
                            <Button
                              type="submit"
                              label="Add Photos/Videos"
                              loader={
                                addMediaLoading || addAlbumLoading || submitting
                              }
                            >
                              Add all Photos (
                              {[...fileUploaded, ...extraUpload]?.length})
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </motion.div>
        ) : (
          <CollapsedUpload
            collapsed={collapsed}
            totalItems={fileUploaded?.length}
            totalUploaded={totalUploaded}
            fileUploaded={fileUploaded}
            percent={percentageCompleted}
            timeLeft={totalTimeLeft}
            isCompleted={isCompleted}
            setRetryUploads={() => handleUpload(fileUploaded)}
            onClose={onCloseModal}
          />
        )}
      </AnimatePresence>
    </DialogModal>
  );
};

export default AddPictures;
