import { useMutation } from "@apollo/client";
import React, { Dispatch, useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { AppContext, ValueProps } from "src/context";
import { DeleteForeignAccountDetails } from "src/graphql/mutations/wallets.mutations";
import { GetForeignWalletDetails } from "src/graphql/queries/wallets.queries";
import { objectKeyToWord } from "src/helper/functions";
import useAppStore from "src/store/utils";
import {
  Currency,
  deleteForeignAccountDetails,
  deleteForeignAccountDetailsVariables,
  getForeignAccountDetails_getForeignAccountDetails,
} from "src/types/api.d";
import { v4 as uuidv4 } from "uuid";
import EditForiegnAcct from "../EditForeignAcct";
import styles from "./foreignAcctDetails.module.scss";

interface ForeignAcctDetailsInterface {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  foreignDetails: getForeignAccountDetails_getForeignAccountDetails;
}

type ViewType = "details" | "edit";

const ForeignAcctDetails = ({
  setOpen,
  foreignDetails,
}: ForeignAcctDetailsInterface) => {
  const [currView, setCurrView] = useState<ViewType>("details");
  const contextValue = useContext<ValueProps | null>(AppContext);
  const { event } = useAppStore((state) => state);
  const walletId = contextValue?.walletCode;

  const navigate = useNavigate();

  const [deleteForeignAccFn, { loading: deleteLoading, error: deleteError }] =
    useMutation<
      deleteForeignAccountDetails,
      deleteForeignAccountDetailsVariables
    >(DeleteForeignAccountDetails, {
      onCompleted(res) {
        toast.success(
          <p>{`Your ${
            foreignDetails.currency === Currency.usd ? `Dollar` : `Pound`
          }Account has been deleted`}</p>
        );
        setOpen(false);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    });

  const handleDelete = () => {
    if (walletId) {
      deleteForeignAccFn({
        variables: {
          foreignAccountId: `${foreignDetails?.id}`,
        },
        refetchQueries: [
          {
            query: GetForeignWalletDetails,
            variables: {
              eventId: event.id,
            },
          },
        ],
      });
    }
  };

  return currView === "details" ? (
    <div className={styles["foreignAcctDetails"]}>
      <div className={styles["foreignAcctDetails_top"]}>
        <h1>{`${
          foreignDetails.currency === Currency.usd ? `Dollar` : `Pound`
        } Account Details`}</h1>
      </div>
      <div className={styles["foreignAcctDetails_details"]}>
        {Object.entries(foreignDetails)
          .filter(
            ([key, value]) =>
              !(
                key === "eventId" ||
                key === "__typename" ||
                key === "walletId" ||
                key === "id"
              )
          )
          .map(([key, value]) => (
            <div
              key={uuidv4()}
              className={styles["foreignAcctDetails_details_item"]}
            >
              <div className={styles["foreignAcctDetails_details_item_key"]}>
                {objectKeyToWord(key)}
              </div>
              <div className={styles["foreignAcctDetails_details_item_value"]}>
                {value}
              </div>
            </div>
          ))}
      </div>
      <div className={styles["foreignAcctDetails_bottom"]}>
        <Button
          design="primary"
          type="submit"
          label="Transfer"
          onClick={() => {
            setCurrView("edit");
          }}
        >
          Edit Details
        </Button>
        <Button
          design="tertiary"
          type="submit"
          label="Transfer"
          disabled={false}
          loader={deleteLoading && !deleteError}
          onClick={() => {
            handleDelete();
          }}
        >
          Delete Account
        </Button>
      </div>
    </div>
  ) : (
    <div className={styles["foreignAcctDetails"]}>
      <div className={styles["foreignAcctDetails_top"]}>
        <h1>{`Edit ${
          foreignDetails.currency === Currency.usd ? `Dollar` : `Pound`
        } Account Details`}</h1>
      </div>
      <EditForiegnAcct setOpen={setOpen} foreignDetails={foreignDetails} />
    </div>
  );
};

export default ForeignAcctDetails;
