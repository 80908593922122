import { Dispatch, SetStateAction } from "react";
import { GuestCheckedInIcon } from "src/components/Icons";
import {
  filterInvitationListBy_filterInvitationListBy,
  invitationListFilter,
  noGuestPerCategory,
} from "src/types/api";
import styles from "./guestList.module.scss";

interface GuestListProps {
  guestList: any[];
  accessControl: boolean | undefined | null;
}

interface GuestListCardProps {
  categories: noGuestPerCategory | undefined;
  invitee: filterInvitationListBy_filterInvitationListBy | null;
  page: number;
  pageSize: number;
  setShow: Dispatch<React.SetStateAction<boolean>>;
  show: any;
  selectedIDs: string[];
  setSelectedIDs: React.Dispatch<SetStateAction<string[]>>;
  filter: invitationListFilter;
}

const Dot = () => {
  return <div className={styles.dot}></div>;
};

interface GuestCardProps {
  guestName: string;
  category: string;
  phoneNumber: string;
  accessControl: boolean;
  email: string;
}

const GuestCard = ({
  guestName,
  category,
  phoneNumber,
  accessControl,
  email,
}: GuestCardProps) => {
  return (
    <div className={styles.guest__card__container}>
      {/* <img src={placeholderImage} alt="user image" /> */}
      <div className={styles.guest__avatar}>
        {guestName
          .split(" ")
          .map((name) => name.charAt(0))
          .join("")}
      </div>
      <div className={styles.guest__info__container}>
        <div className={styles.first__row}>
          <h3>{guestName}</h3>
          {accessControl && <GuestCheckedInIcon />}
        </div>
        <div className={styles.second__row}>
          <p>{category}</p>
          <Dot />
          <p>{phoneNumber}</p>
          <Dot />
          <p>{email}</p>
          {accessControl && (
            <>
              <Dot />
              <p>Reception</p>
              <Dot />
              <p>Twe2512</p>
              <Dot />
              <p>Table 3</p>
              <Dot />
              <p>Seat 12</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const GuestList = ({ guestList, accessControl }: GuestListProps) => {
  return (
    <div className={styles.guestlist__container}>
      <h3>All guests</h3>
      <div className={styles.guest__cards__container}>
        {guestList.map((guest) => (
          <GuestCard
            key={guest?.id}
            guestName={`${guest?.first_name} ${guest?.last_name}`}
            category={guest?.category?.name}
            phoneNumber={guest?.phone}
            accessControl={accessControl ?? false}
            email={guest?.email}
          />
        ))}
      </div>
    </div>
  );
};

export default GuestList;
