import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "src/components/Icon";
import SendInvitesModalFooter from "./SendInvitesModalFooter";

interface InviteSentProps {
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  channel: string;
}

const InviteSent = ({ setOpenInviteModal, channel }: InviteSentProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center gap-4 px-5 pb-4 pt-10 lg:px-10">
        <div>
          <Icon iconName="circleCheck" />
        </div>
        <h1 className="text-2xl font-medium">Invites sent</h1>
        <p className="text-center">
          Guests that have received your invites via {channel} and have been
          moved to your guest list
        </p>
      </div>
      <SendInvitesModalFooter
        firstButtonText={"Add Guests"}
        firstButtonFunction={() => setOpenInviteModal(false)}
        secondButtonText={"Go to Guest List"}
        secondButtonFunction={() => {
          navigate("/invitations?tab=inviteList");
          setOpenInviteModal(false);
        }}
      />
    </>
  );
};

export default InviteSent;
