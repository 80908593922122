import { useMutation } from "@apollo/client";
import { Form, Formik, FormikProps } from "formik";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/Input";
import { UpdateGuestCategory } from "src/graphql/mutations";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import {
  noGuestPerCategory_noGuestPerCategory,
  updateGuestCategory,
  updateGuestCategoryVariables,
} from "src/types/api";
import * as Yup from "yup";
import styles from "./styles.module.scss";

interface Values {
  categoryName: string;
}

const EditCategoryFormSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category Name is required"),
});

interface EditCategoryProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  category: noGuestPerCategory_noGuestPerCategory;
}

const EditCategory = ({ category, setOpen }: EditCategoryProps) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [editGuestCategory, { loading }] = useMutation<
    updateGuestCategory,
    updateGuestCategoryVariables
  >(UpdateGuestCategory, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">
          You have successfully updated the category information.
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div
      className={`${styles["editCategoryModal--content"]} ${styles["show"]}`}
    >
      <h1> Edit Category </h1>
      <Formik
        initialValues={{
          categoryName: category?.category_name || "",
        }}
        validationSchema={EditCategoryFormSchema}
        onSubmit={(values) => {
          editGuestCategory({
            variables: {
              eventId: event.id,
              name: values?.categoryName,
              categoryId: category?.category_id,
            },
            refetchQueries: [
              {
                query: NumberOfGuestsByCategory,
                variables: { eventId: event.id },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["editCategoryModal--content-form"]}>
            <div
              className={styles["editCategoryModal--content-form_container"]}
            >
              <Input
                type="text"
                name="categoryName"
                label="Category Name"
                placeholder="Enter Category Name"
              />
            </div>
            <div className={styles["editCategoryModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Category"
                  disabled={loading || props.values.categoryName === ""}
                  loader={loading}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCategory;
