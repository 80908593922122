import Button from "src/components/Button";
import styles from "./wishlistDeleteItem.module.scss";

interface WishlistDeleteItemProps {
  handleRemoveWishList: () => void;
  loading: boolean;
}

const WishlistDeleteItem: React.FC<WishlistDeleteItemProps> = ({
  handleRemoveWishList,
  loading,
}) => {
  const handleDeleteButton = () => {
    handleRemoveWishList();
  };
  return (
    <div className={styles["wishlist__deleteItem"]}>
      <div className={styles["wishlist__deleteItem-container"]}>
        <div className={styles["wishlist__deleteItem-title"]}>
          <h4>Delete Item</h4>
          <p>
            You are about to delete this item from your wishlist. Do you wish to
            proceed?
          </p>
        </div>
        <div className={styles["wishlist__deleteItem-button"]}>
          <Button
            loader={loading}
            label="delete"
            onClick={handleDeleteButton}
            type="button"
          >
            Yes, Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WishlistDeleteItem;
