import { useQuery } from "@apollo/client";
import { SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import { SearchInput } from "src/components/Input";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { noGuestPerCategory } from "src/types/api";
import { v4 as uuidv4 } from "uuid";
import EmptyState from "../../invitations/EmptyState";
import CategoriesCard from "./CategoriesCard";
import styles from "./categories.module.scss";
import AddCategory from "./modals/AddCategory";

function Categories() {
  const [manualModal, setManualModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");

  const { event } = useAppStore((state) => state);

  const { data: categories, loading: categoryListLoading } =
    useQuery<noGuestPerCategory>(NumberOfGuestsByCategory, {
      variables: {
        eventId: event.id,
      },
      onCompleted() {},
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        }
      },
    });

  const results =
    categories?.noGuestPerCategory?.filter((category) =>
      category?.category_name
        .toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase())
    ) ?? [];

  return (
    <div className={styles["categories"]}>
      <div className={styles["categories__main"]}>
        {categoryListLoading ? (
          <div className={styles["categories__loading"]}>
            <InnerLoading />
          </div>
        ) : !!categories?.noGuestPerCategory &&
          categories?.noGuestPerCategory.length > 0 ? (
          <div>
            <header className={styles["categories__header"]}>
              <div className={styles["categories__header--search"]}>
                <SearchInput
                  icon={<Icon iconName="search" />}
                  name="Search Categories"
                  placeholder="Search Categories"
                  value={searchValue}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <div>
                <DialogModal
                  trigger={
                    <div
                      onClick={() => setManualModal(true)}
                      className={styles["categories__header--btn__trigger"]}
                    >
                      <span>Add Category</span>
                    </div>
                  }
                  open={manualModal}
                  onOpenChange={setManualModal}
                >
                  <AddCategory setOpen={setManualModal} />
                </DialogModal>
              </div> */}
            </header>

            <div className={styles["categories__deck"]}>
              {results?.map((item, index) => (
                <CategoriesCard
                  key={uuidv4()}
                  categories={categories}
                  category={item}
                />
              ))}
              <DialogModal
                trigger={
                  <div className="flex w-full flex-col items-center rounded-[26.2px] border-[0.6px] border-dashed border-[#9F9F9F] px-8 pb-4 pt-[19.5px] md:w-max">
                    <div
                      className={
                        "h-[68.2px] w-[175px]" +
                        " " +
                        styles["categories__svg-full"]
                      }
                    >
                      <Icon iconName="activityComingSoon" />
                    </div>
                    <div className="mt-2.5">
                      <Icon iconName="plus" />
                    </div>
                    <div className="mt-[25px]">
                      <h4 className="text-center text-[11.7px] font-semibold tracking-[0.5px] text-[#919191]">
                        ADD A NEW CATEGORY
                      </h4>
                    </div>
                    <div className="mt-[15.7px]">
                      <p className="max-w-[205.2px] text-center text-[13px] leading-[19px] text-[#898989]">
                        Organize your people and guests, and start treating them
                        as groups e.g brides family
                      </p>
                    </div>
                  </div>
                }
                open={manualModal}
                onOpenChange={setManualModal}
              >
                <AddCategory setOpen={setManualModal} />
              </DialogModal>
            </div>
          </div>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

export default Categories;
