import {
  accessToken,
  expiryTime,
  loggedIn,
  refreshToken,
  username,
} from "./authState";

interface jwtInterface {
  access_token: string;
  refresh_token: string;
  expiresIn: number;
  refresh_expiry: string;
  access_expiry: string;
}

export const updateStorageStatus = (value: boolean, user: string = "") => {
  localStorage["loggedIn"] = value;
  localStorage["username"] = user;
  loggedIn(value);
  username(user);
};

export const updateAuthState = (user: string = "user", jwt: any) => {
  updateStorageStatus(true, user);
  updateJWT(jwt);
};

export const updateJWT = (jwt: jwtInterface) => {
  accessToken(jwt?.access_token);
  refreshToken(jwt?.refresh_token);
  expiryTime(new Date(jwt?.access_expiry).getTime() - new Date().getTime());
};
