import { ComponentType, FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { saveToStorage } from "src/helper/storage";

const withAuth =
  <P extends any>(Component: ComponentType<P>): FC<P> =>
  (props) => {
    const { token } = useParams();
    if (token && Number(token)) {
      // console.log("Are you getting here");
      // Save token in local storage to enable redirect to share wallet when user is logged in
      saveToStorage("shareToken", token);
    }
    const isAuthenticated = localStorage.getItem("loggedIn");
    if (isAuthenticated === "true") {
      return <Component {...props} />;
    }
    return <Navigate to="/login" />;
  };

export default withAuth;
