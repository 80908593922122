import { CSVLink } from "react-csv";
import { GuestCountIcon, SearchIcon } from "src/components/Icons";
import styles from "./header.module.scss";

interface HeaderProps {
  guestCount: number | undefined;
  guestsData: any[];
}

const Header = ({ guestCount, guestsData }: HeaderProps) => {
  return (
    <div className={styles.header__container}>
      <div className={styles.info__btns__container}>
        <div className={styles.guest__count}>
          <GuestCountIcon />
          {guestCount}
        </div>
        <button className={styles.search__btn}>
          <SearchIcon />
        </button>
      </div>
      <div className={styles.action__btns__container}>
        {/* <button className={styles.btn}>Select</button>
        <button className={styles.btn}>Create Reservation</button> */}
        <CSVLink data={guestsData} filename={"guest-list.csv"}>
          <button className={styles.btn}>Download List</button>
        </CSVLink>
      </div>
    </div>
  );
};

export default Header;
