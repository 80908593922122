import { Dispatch } from "react";
import Button from "src/components/Button";
import { paystackChargeCalculator } from "src/helper/functions";
import { icons } from "src/utils/icon";
import styles from "./confirmTransfer.module.scss";

interface ConfirmTransferProps {
  setOpenConfirmDetails: Dispatch<React.SetStateAction<boolean>>;
  amount: any;
  accountNumber: string;
  accountBank: any;
  name: any;
  bankCode: any;
  bankName: any;
  walletPin: string;
  setOpenPin: Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmTransfer = ({
  setOpenConfirmDetails,
  amount,
  accountNumber,
  accountBank,
  bankName,
  name,
  bankCode,
  setOpenPin,
}: ConfirmTransferProps) => {
  const serviceCharge = process.env.REACT_APP_INAWO_SERVICE_CHARGE as string;
  return (
    <div className={styles["confirmTransfer__main"]}>
      <div className={styles["confirmTransfer__main-container"]}>
        <div className={styles["confirmTransfer__title"]}>
          <h1>Confirm Transaction</h1>
        </div>
        <form
          className={styles["confirmTransfer__field-form"]}
          onSubmit={(e) => {
            e.preventDefault();
            setOpenPin(true);
            setOpenConfirmDetails(false);
          }}
          onReset={(e) => {
            e.preventDefault();
            setOpenConfirmDetails(false);
          }}
        >
          <div className={styles["confirmTransfer__details-receiver"]}>
            <div>Receiver</div>
            <div className={styles["confirmTransfer__details__name"]}>
              {name}
            </div>
          </div>
          <div className={styles["confirmTransfer__details-account"]}>
            <div>Account Number</div>
            <div className={styles["confirmTransfer__details__line"]}>
              {accountNumber}
            </div>
          </div>
          <div className={styles["confirmTransfer__details-bank"]}>
            <div>Bank</div>
            <div className={styles["confirmTransfer__details__name"]}>
              {bankName}
            </div>
          </div>
          <div className={styles["confirmTransfer__details-account"]}>
            <div>Amount</div>
            <div className={styles["confirmTransfer__details__line"]}>
              {`₦${amount}`}
            </div>
          </div>
          <div className={styles["confirmTransfer__details-account"]}>
            <div className={styles["confirmTransfer__details-account__title"]}>
              <span>Paystack Charges</span>
              {icons["alertIconBlue"]}
            </div>
            <div className={styles["confirmTransfer__details__line"]}>
              {`₦${paystackChargeCalculator(parseInt(amount))}`}
            </div>
          </div>
          <div className={styles["confirmTransfer__button"]}>
            <Button type="reset" label="I'm done" design="secondary">
              Cancel
            </Button>
            <Button type="submit" label="I'm done">
              Transfer Now
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmTransfer;
