import getSymbolFromCurrency from "currency-symbol-map";
import { AccessControlIcon } from "src/components/Icons";
import { formatMoney } from "src/helper/functions";
import { ActivateAccessControlIcon } from "src/layouts/dashboard/Icons/AccessControlIcon";
import PayForLuxeModalFooter from "./PayForLuxeModalFooter";

const PaymentPrompt = ({
  setStep,
  setOpen,
  step,
  cost,
  currency,
}: {
  setOpen: (value: boolean) => void;
  step: number;
  setStep: (value: number) => void;
  cost: number | undefined;
  currency: string | undefined;
}) => {
  return (
    <div className="flex h-auto max-w-[500px] flex-col items-center gap-[30px] rounded-[5px] bg-white pt-[45px]">
      <div className="flex items-center gap-[20px]">
        <AccessControlIcon />
        <h3 className="text-[20px] font-medium text-[#298576]">
          Access control
        </h3>
      </div>
      <div className="px-[35px]">
        <p className="text-center text-[20px] text-[#707070]">
          You are about to pay {getSymbolFromCurrency(currency ?? "")}{" "}
          {formatMoney(cost ?? 0)} for Inawo Luxe for this event?
        </p>
      </div>
      <ActivateAccessControlIcon />
      <PayForLuxeModalFooter
        firstButtonText="Cancel"
        firstButtonFunction={() => setOpen(false)}
        secondButtonText="Proceed"
        secondButtonFunction={() => setStep(2)}
        disableSecondButton={false}
        secondButtonLoader={false}
      />
    </div>
  );
};

export default PaymentPrompt;
