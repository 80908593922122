import React from "react";
import DialogModal from "src/components/Dialog";
import CustomQrCode from "src/components/QrCode";
import { getRefreshToken } from "src/helper/storage";
import { InawoPassIcon } from "src/layouts/dashboard/Icons/PassDesign";
import PassImagePreview from "../../Modals/PassImagePreview";

interface PassCardProps {
  passImage?: string;
  eventSlug?: string;
  guestCode?: string;
  qrCodeValue?: string;
}

const PassCard = ({
  passImage,
  eventSlug,
  guestCode,
  qrCodeValue,
}: PassCardProps) => {
  const refreshToken = getRefreshToken();
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  return (
    <div className="flex h-[437px] w-[340px] flex-col items-center justify-center gap-[20px] rounded-[7px] bg-gradient-to-br from-[#026051] via-[#47A092CC] to-[#3B5F5900] px-[50px]">
      <div
        className="flex h-[321px] w-[201px] flex-col items-center justify-between rounded-[21px] border border-[#D9D9D9] px-[15px] py-[9px]"
        style={{
          backgroundImage: `url(${passImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="my-[11px] text-[11px] font-medium text-white">
          #{eventSlug}
        </p>
        <div
          className="flex h-[211px] w-full flex-col items-center justify-between rounded-[22px] bg-[#FFFFFFCC] px-[10px] py-[10px]"
          style={{ backdropFilter: "blur(3px)" }}
        >
          <div>
            <p className="mb-[2px] text-[9px] text-[#4D4D4DE8]">Guest code</p>
            <p className="text-[10px] text-[#4D4D4D4D]">
              {guestCode || "TWE2110"}
            </p>
          </div>
          <div>
            <CustomQrCode code="TWE2110" />
            {/* <QRCodeCanvas value="TWE2110" fgColor="#4F7770" size={65} /> */}
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="mb-[2px] text-center text-[10px] text-[#4D4D4D99]">
              This is your pass to the wedding
            </p>
            <InawoPassIcon />
          </div>
        </div>
      </div>
      <DialogModal
        open={openPreview}
        onOpenChange={setOpenPreview}
        trigger={
          <button
            type="button"
            style={{
              background: "transparent",
              border: "1px solid #ffffff",
              borderRadius: "8px",
              width: "85px",
              height: 40,
              fontSize: 12,
              fontWeight: 500,
              color: "#ffffff",
            }}
          >
            Preview
          </button>
        }
      >
        <PassImagePreview passImage={passImage} eventSlug={eventSlug} />
      </DialogModal>
    </div>
  );
};

export default PassCard;
