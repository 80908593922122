import { useMutation } from "@apollo/client";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { GetMediaPresignedUrl } from "src/graphql/mutations";
import useAppStore from "src/store/utils";
import {
  GetMediaPresignedUrlVariables,
  GetMediaPresignedUrl as getMediaPresignedUrl,
} from "src/types/api";
import { UploadResult, UploadValue } from "src/utils/types";

interface UploadStatus {
  file: File;
  progress: number;
  error: string | null;
  timeRemaining: number;
}

export default function useMediaUpload() {
  const { event } = useAppStore((state) => state);
  const [uploadStatuses, setUploadStatuses] = useState<UploadStatus[]>([]);
  const [completed, setCompleted] = useState<boolean[]>([false]);
  const [uploadedMedia, setUploadedMedia] = useState<UploadResult>([]);

  const [uploadMedia] = useMutation<
    getMediaPresignedUrl,
    GetMediaPresignedUrlVariables
  >(GetMediaPresignedUrl);

  const uploadFile = useCallback(
    async (
      file: File,
      preview: string,
      index: number,
      width?: number,
      height?: number
    ) => {
      const res = await uploadMedia({
        variables: {
          eventId: event.uuid,
          mediaType: file.type.split("/")[1],
        },
      });
      if (res.data?.getMediaPresignedUrl?.upload_url) {
        const startTimeForFile = Date.now();

        await axios.put(res.data?.getMediaPresignedUrl?.upload_url, file, {
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read",
          },
          onUploadProgress: (progressEvent) => {
            const progress =
              (progressEvent.loaded / (progressEvent?.total ?? 0)) * 100;

            const timeElapsed = (Date.now() - startTimeForFile) / 1000;
            const uploadSpeed = progressEvent.loaded / timeElapsed;
            const totalSize = progressEvent.total ?? 0;
            const timeRemaining =
              (totalSize - progressEvent.loaded) / uploadSpeed;

            setUploadStatuses((prev) => {
              const updated = prev.map((status) =>
                status.file === file
                  ? { ...status, progress, timeRemaining }
                  : status
              );
              return updated;
            });
          },
        });
        setUploadedMedia((prev) => [
          ...prev,
          {
            preview,
            mediaId: res?.data?.getMediaPresignedUrl?.media_id ?? "",
            url: res?.data?.getMediaPresignedUrl?.file_uri ?? "",
            width,
            height,
          },
        ]);
        setCompleted((prev) => {
          const updated = [...prev];
          updated[index] = true;
          return updated;
        });
      }
    },
    [event.uuid, uploadMedia]
  );

  const handleUpload = useCallback(
    (uploadResult: UploadValue) => {
      setCompleted([]);
      const initialStatuses = uploadResult.map(({ file }) => ({
        file,
        progress: 0,
        error: null,
        timeRemaining: 0,
      }));
      setUploadStatuses(initialStatuses);

      const uploadFiles = async () => {
        await Promise.all(
          uploadResult.map(({ file, preview, width, height }, index) =>
            uploadFile(file, preview, index, width, height).catch((err) => {
              toast.error(err.message);
            })
          )
        );
      };
      uploadFiles();
    },
    [uploadFile]
  );

  const percentageCompleted = useMemo(
    () =>
      uploadStatuses?.length
        ? Math.round(
            uploadStatuses?.reduce((tot, { progress }) => tot + progress, 0) /
              uploadStatuses?.length
          )
        : 0,
    [uploadStatuses]
  );

  const totalTimeLeft = useMemo(
    () =>
      uploadStatuses?.length
        ? Math.round(
            (uploadStatuses.reduce(
              (tot, { timeRemaining }) => tot + timeRemaining,
              0
            ) /
              uploadStatuses.length) *
              100
          ) / 100
        : 0,
    [uploadStatuses]
  );
  return {
    uploadStatuses,
    percentageCompleted,
    totalTimeLeft,
    isCompleted:
      !!completed.length &&
      completed.every((c) => c) &&
      percentageCompleted === 100,
    handleUpload,
    totalUploaded: completed.filter((c) => c).length,
    uploadedMedia,
    setCompleted,
  };
}
