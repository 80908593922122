export const LoveIcon = () => {
  return (
    <svg
      width="65"
      height="53"
      viewBox="0 0 65 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.6059 27.5023L44.7836 46.2465C41.709 49.9431 37.1489 52.0809 32.3398 52.0809C27.5307 52.0809 22.9708 49.9431 19.8948 46.2465L4.74187 27.9898C2.02788 24.7348 0.646344 20.5721 0.871091 16.3409C1.09725 12.108 2.91423 8.11773 5.95988 5.17001C9.25457 2.13359 13.6662 0.611688 18.1299 0.970814C22.6135 1.29784 26.7485 3.50907 29.51 7.056L32.6748 10.829L35.3521 7.60306C37.9851 4.2824 41.7796 2.08356 45.9698 1.45059C50.16 0.819457 54.4343 1.79901 57.9289 4.19512C61.3779 6.93356 63.5724 10.9512 64.0141 15.3323C64.5887 19.6845 63.357 24.0824 60.6064 27.5023H60.6059Z"
        fill="white"
      />
    </svg>
  );
};

export const WeddingLoveIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4969 5.09019L7.19256 6.06952L6.48796 5.32436C5.78785 4.58394 4.9531 3.97922 4.13809 3.69385C3.33676 3.41326 2.60399 3.45235 1.98305 3.90296C0.574195 4.92536 0.0954037 7.08001 1.23162 9.67211C2.32755 12.1723 4.90681 14.9573 9.34003 17.0582C12.0541 14.3022 14.3252 10.7075 15.2417 7.56078C15.7169 5.92936 15.8104 4.47907 15.4749 3.34491C15.15 2.24646 14.4103 1.39442 13.0728 0.927986C11.6695 0.43857 10.5215 0.802924 9.59088 1.61288C8.63241 2.44705 7.91086 3.75811 7.4969 5.09019Z"
        stroke="#298677"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export const LcoationIcon = () => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49909 0.419556C9.89285 0.419556 12.6444 3.17044 12.6444 6.56421C12.6444 9.91392 10.281 14.4479 7.7593 17.2371C7.08642 17.984 5.91367 17.984 5.24015 17.2371C2.71909 14.4479 0.355713 9.91392 0.355713 6.56421C0.355713 3.17108 3.1066 0.419556 6.49973 0.419556H6.49909ZM6.49909 3.31919C8.29045 3.31919 9.7441 4.77284 9.7441 6.56357C9.7441 8.35494 8.29045 9.80859 6.49909 9.80859C4.70836 9.80859 3.25471 8.35494 3.25471 6.56357C3.25471 4.77284 4.70836 3.31919 6.49909 3.31919Z"
        fill="#568079"
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1444_19993" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 1.47913C1.67893 1.47913 0 3.15806 0 5.22913V12.3882C0 14.4593 1.67893 16.1382 3.75 16.1382H11.25C13.3211 16.1382 15 14.4593 15 12.3882V5.22912C15 3.15806 13.3211 1.47913 11.25 1.47913H3.75ZM5 5.2789C3.89543 5.2789 3 6.17433 3 7.2789C3 8.38347 3.89543 9.2789 5 9.2789H10C11.1046 9.2789 12 8.38347 12 7.2789C12 6.17433 11.1046 5.2789 10 5.2789H5Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 1.47913C1.67893 1.47913 0 3.15806 0 5.22913V12.3882C0 14.4593 1.67893 16.1382 3.75 16.1382H11.25C13.3211 16.1382 15 14.4593 15 12.3882V5.22912C15 3.15806 13.3211 1.47913 11.25 1.47913H3.75ZM5 5.2789C3.89543 5.2789 3 6.17433 3 7.2789C3 8.38347 3.89543 9.2789 5 9.2789H10C11.1046 9.2789 12 8.38347 12 7.2789C12 6.17433 11.1046 5.2789 10 5.2789H5Z"
        fill="#557F78"
      />
      <path
        d="M0.340909 5.22913C0.340909 3.34634 1.86721 1.82004 3.75 1.82004V1.13822C1.49065 1.13822 -0.340909 2.96978 -0.340909 5.22913H0.340909ZM0.340909 12.3882V5.22913H-0.340909V12.3882H0.340909ZM3.75 15.7973C1.86721 15.7973 0.340909 14.271 0.340909 12.3882H-0.340909C-0.340909 14.6476 1.49065 16.4791 3.75 16.4791V15.7973ZM11.25 15.7973H3.75V16.4791H11.25V15.7973ZM14.6591 12.3882C14.6591 14.271 13.1328 15.7973 11.25 15.7973V16.4791C13.5093 16.4791 15.3409 14.6476 15.3409 12.3882H14.6591ZM14.6591 5.22912V12.3882H15.3409V5.22912H14.6591ZM11.25 1.82004C13.1328 1.82004 14.6591 3.34634 14.6591 5.22912H15.3409C15.3409 2.96978 13.5093 1.13822 11.25 1.13822V1.82004ZM3.75 1.82004H11.25V1.13822H3.75V1.82004ZM3.34091 7.2789C3.34091 6.36261 4.08371 5.61981 5 5.61981V4.93799C3.70715 4.93799 2.65909 5.98605 2.65909 7.2789H3.34091ZM5 8.93799C4.08371 8.93799 3.34091 8.19519 3.34091 7.2789H2.65909C2.65909 8.57175 3.70715 9.61981 5 9.61981V8.93799ZM10 8.93799H5V9.61981H10V8.93799ZM11.6591 7.2789C11.6591 8.19519 10.9163 8.93799 10 8.93799V9.61981C11.2928 9.61981 12.3409 8.57175 12.3409 7.2789H11.6591ZM10 5.61981C10.9163 5.61981 11.6591 6.36261 11.6591 7.2789H12.3409C12.3409 5.98605 11.2928 4.93799 10 4.93799V5.61981ZM5 5.61981H10V4.93799H5V5.61981Z"
        fill="#557F78"
        mask="url(#path-1-inside-1_1444_19993)"
      />
      <path
        d="M3.06812 0.797241L3.06812 4.20633"
        stroke="#557F78"
        strokeWidth="1.36364"
        stroke-linecap="round"
      />
      <path
        d="M11.9316 0.797241L11.9316 4.20633"
        stroke="#557F78"
        strokeWidth="1.36364"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const EventsEmptyStateIcon = () => {
  return (
    <svg
      width={`100%`}
      height={`100%`}
      // height="244"
      viewBox="0 0 413 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="55.646"
        width="137"
        height="215"
        rx="18"
        transform="rotate(15 55.646 0)"
        fill="url(#paint0_linear_1624_23273)"
      />
      <path
        d="M120.878 129.809L100.743 143.819C96.8166 146.594 91.8585 147.479 87.2133 146.234C82.5681 144.989 78.7169 141.744 76.7024 137.377L66.791 115.821C65.0119 111.974 64.7548 107.596 66.0671 103.567C67.3811 99.5369 70.1689 96.1529 73.8737 94.0938C77.842 92.0136 82.4972 91.6854 86.7158 93.1876C90.962 94.6639 94.3838 97.87 96.1332 102.011L98.2137 106.474L101.635 104.051C105.037 101.525 109.272 100.383 113.483 100.856C117.694 101.331 121.569 103.384 124.324 106.603C126.947 110.141 128.027 114.589 127.32 118.935C126.748 123.288 124.42 127.217 120.878 129.809L120.878 129.809Z"
        fill="white"
      />
      <rect
        x="137.978"
        y="14.0662"
        width="137"
        height="215"
        rx="18"
        fill="url(#paint1_linear_1624_23273)"
      />
      <path
        d="M234.584 122.569L218.762 141.313C215.687 145.009 211.127 147.147 206.318 147.147C201.509 147.147 196.949 145.009 193.873 141.313L178.72 123.056C176.006 119.801 174.625 115.638 174.849 111.407C175.076 107.174 176.893 103.184 179.938 100.236C183.233 97.1999 187.644 95.678 192.108 96.0371C196.592 96.3641 200.727 98.5754 203.488 102.122L206.653 105.895L209.33 102.669C211.963 99.3487 215.758 97.1498 219.948 96.5169C224.138 95.8857 228.413 96.8653 231.907 99.2614C235.356 102 237.551 106.018 237.992 110.399C238.567 114.751 237.335 119.149 234.585 122.569H234.584Z"
        fill="white"
      />
      <rect
        x="224.978"
        y="35.4583"
        width="137"
        height="215"
        rx="18"
        transform="rotate(-15 224.978 35.4583)"
        fill="url(#paint2_linear_1624_23273)"
      />
      <path
        d="M346.374 115.26L335.943 137.46C333.93 141.827 330.078 145.072 325.433 146.317C320.788 147.561 315.83 146.677 311.902 143.902L292.54 130.189C289.076 127.748 286.664 124.085 285.786 119.939C284.909 115.792 285.632 111.468 287.81 107.832C290.207 104.046 294.074 101.434 298.479 100.626C302.894 99.7815 307.461 100.847 311.046 103.558L315.08 106.384L316.831 102.575C318.515 98.6859 321.611 95.5799 325.494 93.884C329.378 92.1898 333.761 92.0297 337.756 93.4397C341.797 95.1922 344.956 98.505 346.517 102.622C348.198 106.678 348.147 111.244 346.375 115.26L346.374 115.26Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1624_23273"
          x1="124.146"
          y1="0"
          x2="233.255"
          y2="50.4683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37645D" />
          <stop offset="1" stopColor="#1F5C53" stop-opacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1624_23273"
          x1="206.478"
          y1="14.0662"
          x2="315.587"
          y2="64.5345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11473F" />
          <stop offset="1" stopColor="#1F5C53" stop-opacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1624_23273"
          x1="293.478"
          y1="35.4583"
          x2="402.587"
          y2="85.9266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37645D" />
          <stop offset="1" stopColor="#1F5C53" stop-opacity="0.38" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const InawoLuxeIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.2372 1.46148C2.01951 0.901556 0.76238 2.15867 1.3223 3.37637L3.03616 7.10363C3.04543 7.12378 3.04543 7.14697 3.03616 7.16712L1.3223 10.8944C0.762384 12.1121 2.0195 13.3692 3.2372 12.8093L6.96446 11.0954C6.98461 11.0861 7.0078 11.0861 7.02795 11.0954L10.7552 12.8093C11.9729 13.3692 13.23 12.1121 12.6701 10.8944L10.9562 7.16712C10.947 7.14697 10.947 7.12378 10.9562 7.10363L12.6701 3.37638C13.23 2.15868 11.9729 0.901552 10.7552 1.46147L7.02795 3.17534C7.0078 3.1846 6.98461 3.1846 6.96446 3.17534L3.2372 1.46148Z"
        stroke="#298576"
        strokeWidth="1.36778"
      />
    </svg>
  );
};

export const ActivateAccessControlIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.88092"
        y="0.514282"
        width="13.7354"
        height="13.7354"
        rx="3.27779"
        fill="white"
        fill-opacity="0.9"
      />
      <rect
        x="3.88092"
        y="3.13065"
        width="8.11908"
        height="8.11908"
        rx="1.93751"
        fill="url(#paint0_linear_1444_19782)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1444_19782"
          x1="7.94046"
          y1="3.13065"
          x2="7.94046"
          y2="11.2497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64837E" />
          <stop offset="1" stopColor="#64837E" stop-opacity="0.6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const AccessControlIcon = () => {
  return (
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9286 1.60599L16.7242 0.857643C10.3152 -0.658247 6.49669 0.589004 4.25164 5.23262C3.67599 6.40311 3.21547 7.82306 2.8317 9.45408L0.951224 17.4941C-0.929247 25.5148 1.54607 29.4677 9.54766 31.3673L12.7713 32.1349C13.8843 32.4035 14.9204 32.5762 15.8799 32.653C21.8667 33.2286 25.052 30.4271 26.6638 23.5001L28.5443 15.4793C30.4247 7.45848 27.9686 3.48646 19.9286 1.60599ZM16.3212 21.0247C16.1485 21.6771 15.5728 22.0993 14.9204 22.0993C14.8053 22.0993 14.6902 22.0801 14.5559 22.0609L8.97201 20.641C8.20447 20.4491 7.74395 19.6624 7.93583 18.8948C8.12772 18.1273 8.91444 17.6668 9.68198 17.8586L15.2658 19.2786C16.0526 19.4705 16.5131 20.2572 16.3212 21.0247ZM21.9434 14.539C21.7707 15.1914 21.1951 15.6136 20.5427 15.6136C20.4275 15.6136 20.3124 15.5944 20.1781 15.5752L10.8717 13.215C10.1041 13.0231 9.64361 12.2364 9.83549 11.4689C10.0274 10.7013 10.8141 10.2408 11.5816 10.4327L20.8881 12.7929C21.6748 12.9656 22.1353 13.7523 21.9434 14.539Z"
        fill="url(#paint0_linear_1444_19337)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1444_19337"
          x1="0.353637"
          y1="24.5789"
          x2="28.8536"
          y2="8.07893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34544F" />
          <stop offset="1" stopColor="#5E978E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const InawoGreenLuxeIcon = () => {
  return (
    <svg
      width="89"
      height="40"
      viewBox="0 0 89 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.219696"
        y="0.432617"
        width="88.0579"
        height="39.0816"
        rx="18.2374"
        fill="white"
      />
      <path
        d="M15.1649 25.8409L14.8694 25.1983L15.1649 25.8409L19.0194 24.0685C19.0402 24.059 19.0642 24.059 19.085 24.0685L22.9395 25.8409C24.1987 26.4199 25.4988 25.1199 24.9197 23.8606L23.1474 20.0062C23.1378 19.9853 23.1378 19.9614 23.1474 19.9405L24.9197 16.0861C25.4988 14.8268 24.1987 13.5268 22.9395 14.1058L19.085 15.8782C19.0642 15.8877 19.0402 15.8877 19.0194 15.8782L15.1649 14.1058C13.9056 13.5268 12.6056 14.8268 13.1847 16.0861L14.957 19.9405C14.9666 19.9614 14.9666 19.9854 14.957 20.0062L13.1847 23.8606C12.6056 25.1199 13.9056 26.4199 15.1649 25.8409Z"
        stroke="#298576"
        strokeWidth="1.41446"
      />
      <path
        d="M32.8659 25.4734V15.2916H34.4021V24.151H39.0157V25.4734H32.8659ZM51.9358 15.2916H53.477V21.9884C53.477 22.701 53.3096 23.3324 52.9748 23.8825C52.6401 24.4294 52.1694 24.8603 51.5629 25.1752C50.9564 25.4867 50.2454 25.6425 49.4301 25.6425C48.6181 25.6425 47.9088 25.4867 47.3023 25.1752C46.6957 24.8603 46.2251 24.4294 45.8903 23.8825C45.5556 23.3324 45.3882 22.701 45.3882 21.9884V15.2916H46.9244V21.8641C46.9244 22.3248 47.0255 22.7341 47.2277 23.0921C47.4332 23.45 47.7232 23.7317 48.0977 23.9372C48.4722 24.1394 48.9164 24.2405 49.4301 24.2405C49.9471 24.2405 50.3929 24.1394 50.7675 23.9372C51.1453 23.7317 51.4337 23.45 51.6325 23.0921C51.8347 22.7341 51.9358 22.3248 51.9358 21.8641V15.2916ZM61.3994 15.2916L63.8354 19.2739H63.915L66.3511 15.2916H68.1309L64.959 20.3825L68.1508 25.4734H66.361L63.915 21.5459H63.8354L61.3894 25.4734H59.5996L62.8461 20.3825L59.6195 15.2916H61.3994ZM74.2747 25.4734V15.2916L80.6582 15.2916V16.6141H75.8109V19.7163L80.3251 19.7163V21.0338L75.8109 21.0338V24.151L80.7179 24.151V25.4734L74.2747 25.4734Z"
        fill="#298576"
      />
    </svg>
  );
};

export const SelfiePlaceholderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="164"
      height="224"
      viewBox="0 0 164 224"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="163"
        height="223"
        rx="55.5"
        fill="#F3F4F6"
        stroke="#9D9D9A"
      />
      <mask
        id="mask0_1022_17324"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="164"
        height="224"
      >
        <rect
          x="0.5"
          y="0.5"
          width="163"
          height="223"
          rx="55.5"
          fill="#D9D9D9"
          stroke="#9D9D9A"
        />
      </mask>
      <g mask="url(#mask0_1022_17324)">
        <path
          d="M81.7771 105.499H81.7776C115.346 105.499 145.171 121.58 163.945 146.456V226.451H-0.444631V146.531C9.11485 133.804 21.5019 123.471 35.7397 116.35C50.0326 109.201 65.7962 105.486 81.7771 105.499ZM108.792 63.5618C108.792 70.7301 105.944 77.6048 100.875 82.6735C95.8067 87.7422 88.9321 90.5898 81.7638 90.5898C74.5955 90.5898 67.7209 87.7422 62.6521 82.6735C57.5834 77.6048 54.7358 70.7301 54.7358 63.5618C54.7358 56.3936 57.5834 49.5189 62.6521 44.4502C67.7209 39.3815 74.5955 36.5339 81.7638 36.5339C88.9321 36.5339 95.8067 39.3815 100.875 44.4502C105.944 49.5189 108.792 56.3936 108.792 63.5618Z"
          fill="#FFFBEB"
          stroke="#9D9D9A"
          strokeWidth="1.11074"
        />
      </g>
    </svg>
  );
};

export const CameraIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 7.46957 1.71071 6.96086 2.08579 6.58579C2.46086 6.21071 2.96957 6 3.5 6H4.43C4.75918 6.00005 5.08329 5.91884 5.37357 5.76359C5.66384 5.60834 5.9113 5.38383 6.094 5.11L6.906 3.89C7.0887 3.61617 7.33616 3.39166 7.62643 3.23641C7.91671 3.08116 8.24082 2.99995 8.57 3H12.43C12.7592 2.99995 13.0833 3.08116 13.3736 3.23641C13.6638 3.39166 13.9113 3.61617 14.094 3.89L14.906 5.11C15.0887 5.38383 15.3362 5.60834 15.6264 5.76359C15.9167 5.91884 16.2408 6.00005 16.57 6H17.5C18.0304 6 18.5391 6.21071 18.9142 6.58579C19.2893 6.96086 19.5 7.46957 19.5 8V15C19.5 15.5304 19.2893 16.0391 18.9142 16.4142C18.5391 16.7893 18.0304 17 17.5 17H3.5C2.96957 17 2.46086 16.7893 2.08579 16.4142C1.71071 16.0391 1.5 15.5304 1.5 15V8ZM15 11C15 11.5909 14.8836 12.1761 14.6575 12.7221C14.4313 13.268 14.0998 13.7641 13.682 14.182C13.2641 14.5998 12.768 14.9313 12.2221 15.1575C11.6761 15.3836 11.0909 15.5 10.5 15.5C9.90905 15.5 9.32389 15.3836 8.77792 15.1575C8.23196 14.9313 7.73588 14.5998 7.31802 14.182C6.90016 13.7641 6.56869 13.268 6.34254 12.7221C6.1164 12.1761 6 11.5909 6 11C6 9.80653 6.47411 8.66193 7.31802 7.81802C8.16193 6.97411 9.30653 6.5 10.5 6.5C11.6935 6.5 12.8381 6.97411 13.682 7.81802C14.5259 8.66193 15 9.80653 15 11ZM10.5 14C10.894 14 11.2841 13.9224 11.6481 13.7716C12.012 13.6209 12.3427 13.3999 12.6213 13.1213C12.8999 12.8427 13.1209 12.512 13.2716 12.1481C13.4224 11.7841 13.5 11.394 13.5 11C13.5 10.606 13.4224 10.2159 13.2716 9.85195C13.1209 9.48797 12.8999 9.15726 12.6213 8.87868C12.3427 8.6001 12.012 8.37913 11.6481 8.22836C11.2841 8.0776 10.894 8 10.5 8C9.70435 8 8.94129 8.31607 8.37868 8.87868C7.81607 9.44129 7.5 10.2044 7.5 11C7.5 11.7956 7.81607 12.5587 8.37868 13.1213C8.94129 13.6839 9.70435 14 10.5 14Z"
        fill="black"
      />
    </svg>
  );
};

export const DownloadPassIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
    >
      <path
        d="M3.56744 0.797336C3.56744 0.682218 3.52171 0.571814 3.44031 0.490413C3.35891 0.409012 3.2485 0.363281 3.13338 0.363281C3.01827 0.363281 2.90786 0.409012 2.82646 0.490413C2.74506 0.571814 2.69933 0.682218 2.69933 0.797336V5.7826L0.989152 3.97173C0.950368 3.92891 0.903426 3.89427 0.851079 3.86983C0.798733 3.84539 0.742034 3.83164 0.684306 3.8294C0.626579 3.82716 0.568984 3.83646 0.514899 3.85676C0.460814 3.87707 0.411326 3.90796 0.369336 3.94764C0.327346 3.98732 0.2937 4.03498 0.270369 4.08783C0.247038 4.14068 0.234492 4.19766 0.233467 4.25542C0.232441 4.31318 0.242957 4.37057 0.264397 4.42421C0.285837 4.47786 0.31777 4.52669 0.358325 4.56783L2.81797 7.17216C2.85854 7.21507 2.90744 7.24925 2.96167 7.27261C3.0159 7.29597 3.07433 7.30802 3.13338 7.30802C3.19244 7.30802 3.25087 7.29597 3.3051 7.27261C3.35933 7.24925 3.40823 7.21507 3.4488 7.17216L5.90844 4.56783C5.98466 4.48368 6.02488 4.37302 6.02047 4.25958C6.01606 4.14613 5.96738 4.03892 5.88486 3.96094C5.80234 3.88297 5.69256 3.84043 5.57904 3.84244C5.46552 3.84446 5.35732 3.89087 5.27762 3.97173L3.56744 5.7826V0.797336Z"
        fill="#141414"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      viewBox="0 0 7 8"
      fill="none"
    >
      <path
        d="M3.56744 0.797336C3.56744 0.682218 3.52171 0.571814 3.44031 0.490413C3.35891 0.409012 3.2485 0.363281 3.13338 0.363281C3.01827 0.363281 2.90786 0.409012 2.82646 0.490413C2.74506 0.571814 2.69933 0.682218 2.69933 0.797336V5.7826L0.989152 3.97173C0.950368 3.92891 0.903426 3.89427 0.851079 3.86983C0.798733 3.84539 0.742034 3.83164 0.684306 3.8294C0.626579 3.82716 0.568984 3.83646 0.514899 3.85676C0.460814 3.87707 0.411326 3.90796 0.369336 3.94764C0.327346 3.98732 0.2937 4.03498 0.270369 4.08783C0.247038 4.14068 0.234492 4.19766 0.233467 4.25542C0.232441 4.31318 0.242957 4.37057 0.264397 4.42421C0.285837 4.47786 0.31777 4.52669 0.358325 4.56783L2.81797 7.17216C2.85854 7.21507 2.90744 7.24925 2.96167 7.27261C3.0159 7.29597 3.07433 7.30802 3.13338 7.30802C3.19244 7.30802 3.25087 7.29597 3.3051 7.27261C3.35933 7.24925 3.40823 7.21507 3.4488 7.17216L5.90844 4.56783C5.98466 4.48368 6.02488 4.37302 6.02047 4.25958C6.01606 4.14613 5.96738 4.03892 5.88486 3.96094C5.80234 3.88297 5.69256 3.84043 5.57904 3.84244C5.46552 3.84446 5.35732 3.89087 5.27762 3.97173L3.56744 5.7826V0.797336Z"
        fill="#141414"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export const GuestCountIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31765 1.66602C6.13431 1.66602 4.35931 3.44102 4.35931 5.62435C4.35931 7.76602 6.03431 9.49935 8.21765 9.57435C8.28431 9.56602 8.35098 9.56602 8.40098 9.57435C8.41765 9.57435 8.42598 9.57435 8.44265 9.57435C8.45098 9.57435 8.45098 9.57435 8.45931 9.57435C10.5926 9.49935 12.2676 7.76602 12.276 5.62435C12.276 3.44102 10.501 1.66602 8.31765 1.66602Z"
        fill="#565656"
      />
      <path
        d="M12.551 11.7914C10.226 10.2414 6.43431 10.2414 4.09264 11.7914C3.03431 12.4997 2.45097 13.4581 2.45097 14.4831C2.45097 15.5081 3.03431 16.4581 4.08431 17.1581C5.25097 17.9414 6.78431 18.3331 8.31764 18.3331C9.85097 18.3331 11.3843 17.9414 12.551 17.1581C13.601 16.4497 14.1843 15.4997 14.1843 14.4664C14.176 13.4414 13.601 12.4914 12.551 11.7914Z"
        fill="#565656"
      />
      <path
        d="M17.476 6.11708C17.6093 7.73374 16.4593 9.15041 14.8676 9.34208C14.8593 9.34208 14.8593 9.34208 14.851 9.34208H14.826C14.776 9.34208 14.726 9.34208 14.6843 9.35874C13.876 9.40041 13.1343 9.14208 12.576 8.66708C13.4343 7.90041 13.926 6.75041 13.826 5.50041C13.7676 4.82541 13.5343 4.20874 13.1843 3.68374C13.501 3.52541 13.8676 3.42541 14.2426 3.39208C15.876 3.25041 17.3343 4.46708 17.476 6.11708Z"
        fill="#565656"
      />
      <path
        d="M19.1426 13.8247C19.076 14.633 18.5593 15.333 17.6926 15.808C16.8593 16.2663 15.8093 16.483 14.7676 16.458C15.3676 15.9163 15.7176 15.2413 15.7843 14.5247C15.8676 13.4913 15.376 12.4997 14.3927 11.708C13.8343 11.2663 13.1843 10.9163 12.476 10.658C14.3176 10.1247 16.6343 10.483 18.0593 11.633C18.826 12.2497 19.2176 13.0247 19.1426 13.8247Z"
        fill="#565656"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.81764" cy="7" r="6" stroke="#565656" stroke-width="2" />
      <path
        d="M13.1712 10.6465L16.8176 14.5"
        stroke="#565656"
        stroke-width="2"
      />
    </svg>
  );
};

export const GuestCheckedInIcon = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.17377"
        y="0.851562"
        width="28.2029"
        height="18.2959"
        rx="6.5"
        fill="#F3FFF6"
      />
      <rect
        x="1.17377"
        y="0.851562"
        width="28.2029"
        height="18.2959"
        rx="6.5"
        stroke="#22A24E"
      />
      <path
        d="M8.67377 11.5488L12.1766 13.9739L19.1822 5.35156"
        stroke="#297D46"
      />
      <path
        d="M13.726 13.7046L14.8711 14.6477L21.8767 6.02539"
        stroke="#297D46"
      />
    </svg>
  );
};

export const InawoPassSuccessIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.50006" cy="8.59528" r="8.37848" fill="#22A24E" />
      <mask
        id="mask0_2014_22374"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="17"
      >
        <circle cx="8.50006" cy="8.59528" r="8.37848" fill="white" />
      </mask>
      <g mask="url(#mask0_2014_22374)">
        <path
          d="M4.47852 6.94867L8.55847 11.6115L17.0098 4.90869"
          stroke="white"
          stroke-width="2.23426"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2199 2.38265L14.5898 0.752563L8.12728 7.21509L1.66475 0.752563L0.034668 2.38265L6.4972 8.84517L0.034668 15.3077L1.66475 16.9378L8.12728 10.4753L14.5898 16.9378L16.2199 15.3077L9.75736 8.84517L16.2199 2.38265Z"
        fill="#A1A1AA"
      />
    </svg>
  );
};
