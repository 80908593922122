import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import styles from "./wishlistAddItem.module.scss";

import { GetWishListItems } from "src/graphql/queries";
import useAppStore from "src/store/utils";

export interface Values {
  productName: string;
  price: string;
  link: string;
}

interface AddWishListItemProps {
  variables: {
    eventId: number | null | undefined;
    name: string;
    amount: number;
    image: string;
    currency: string;
  };
  refetchQueries: {
    query: any;
    variables: {
      eventId: number | null | undefined;
      page: number;
      pageSize: number;
    };
  }[];
}

const AddItemSchema = Yup.object().shape({
  productName: Yup.string().required("Please fill in the name of this item"),
  price: Yup.number().required("Please fill in the price of this item."),
});

interface WishlistAddItemProps {
  setToggleModal: (toggleModal: boolean) => void;
  addWishListItem: (variables: AddWishListItemProps) => void;
  loading: boolean;
  page: number;
  pageSize: number;
}

const WishlistAddItem: React.FC<WishlistAddItemProps> = ({
  loading,
  addWishListItem,
  page,
  pageSize,
}) => {
  const { event } = useAppStore((state) => state);

  return (
    <div className={styles["wishlist__addItem"]}>
      <div className={styles["wishlist__addItem-heading"]}>
        <h4>Add Item</h4>
        <p>Add items to your wishlist</p>
      </div>
      <Formik
        initialValues={{
          productName: "",
          price: "",
          link: "",
        }}
        validationSchema={AddItemSchema}
        onSubmit={(values, actions) => {
          addWishListItem({
            variables: {
              eventId: event.id,
              name: values.productName,
              image: !!values.link ? values.link : "not set",
              currency: "ngn",
              amount: parseFloat(`${values.price}`),
            },
            refetchQueries: [
              {
                query: GetWishListItems,
                variables: {
                  eventId: event.id,
                  page: page,
                  pageSize: pageSize,
                },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["wishlist__addItem-form"]}>
            <div className={styles["wishlist__addItem-form-container"]}>
              <Input
                type="text"
                name="productName"
                label="Name"
                placeholder="e.g Car"
              />
              <Input
                type="number"
                name="price"
                label="Price"
                placeholder="e.g 50000"
              />
              <Input
                type="text"
                name="link"
                label="Link (Optional)"
                placeholder="e.g https://www.amazon.com/abcdef"
              />
            </div>

            <div className={styles["wishlist__addItem-button-container"]}>
              <div className={styles["wishlist__addItem-button"]}>
                <Button
                  type="submit"
                  label="Add Item"
                  disabled={
                    props.values.productName === "" ||
                    props.values.price === "" ||
                    loading
                  }
                  loader={loading}
                >
                  Add Item
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WishlistAddItem;
