import { useState } from "react";
import Tab from "src/components/Tab";
import DashboardLayout from "src/layouts/dashboard";
import { cn } from "src/lib/utils";
import { youtubeLiveTabTypes } from "src/utils/types";
import styles from "../../../invitations/styles.module.scss";
import LiveSchedules from "./live-schedules";
import Setup from "./setup";

const tabItems: youtubeLiveTabTypes[] = ["setup", "upcoming"];

const YoutubeLive = () => {
  const [tabState, setTabState] = useState<youtubeLiveTabTypes>("setup");
  const handleTab = (element: youtubeLiveTabTypes) => {
    setTabState(element);
  };
  const [addLiveEventDefaultOpen, setAddLiveEventDefaultOpen] = useState(false);

  const tab: Record<youtubeLiveTabTypes, JSX.Element> = {
    setup: (
      <Setup
        addLiveEvent={() => {
          setTabState("upcoming");
          setAddLiveEventDefaultOpen(true);
        }}
      />
    ),
    upcoming: (
      <LiveSchedules
        addLiveEventDefaultOpen={addLiveEventDefaultOpen}
        setAddLiveEventDefaultOpen={setAddLiveEventDefaultOpen}
      />
    ),
  };
  return (
    <DashboardLayout pageName="Live Hall">
      <div className={styles["invitations"]}>
        <div className={styles["invitations__tabs"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div
          className={cn(
            styles["invitations__main"],
            `px-[1.2rem] pb-[2rem] sm:px-0`
          )}
        >
          {tab[tabState]}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default YoutubeLive;
