import { useMutation } from "@apollo/client";
import { Dispatch, useContext, useState } from "react";
import toast from "react-hot-toast";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { AppContext, ValueProps } from "src/context";
import {
  InitiateWalletPinReset,
  VerifyPinResetOTP,
} from "src/graphql/mutations/wallets.mutations";
import {
  initiateWalletPinReset,
  initiateWalletPinResetVariables,
  verifyPinResetOTP,
  verifyPinResetOTPVariables,
} from "src/types/api";
import styles from "./resetPinOTP.module.scss";

interface ResetPinOTPprops {
  setOpenOTP: Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  setPasscode: Dispatch<React.SetStateAction<string>>;
  setOpenResetPin: Dispatch<React.SetStateAction<boolean>>;
}

const ResetPinOTP = ({
  setOpenOTP,
  eventId,
  setPasscode,
  setOpenResetPin,
}: ResetPinOTPprops) => {
  const navigate = useNavigate();

  const [ResetPinFn] = useMutation<
    initiateWalletPinReset,
    initiateWalletPinResetVariables
  >(InitiateWalletPinReset, {
    onCompleted() {
      toast.success(<p>{"An OTP has been resent."}</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const [verifyPinOtpfn, { loading: verifyPinOtpLoading }] = useMutation<
    verifyPinResetOTP,
    verifyPinResetOTPVariables
  >(VerifyPinResetOTP, {
    onCompleted(res) {
      setPasscode(res.verifyPinResetOTP);
      toast.success(<p>Success</p>);
      setOpenResetPin(true);
      setOpenOTP(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const [otp, setOtp] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (otp.length === 5) {
      verifyPinOtpfn({
        variables: {
          eventId: eventId,
          otp: otp,
        },
      });
    } else {
      toast.error(<p className="toast">OTP is too short!</p>);
    }
  };

  const contextValue = useContext<ValueProps | null>(AppContext);
  const phone = contextValue?.phone;

  return (
    <div className={styles["resetPinOTP__main"]}>
      <div className={styles["resetPinOTP__main-container"]}>
        <div className={styles["resetPinOTP__title"]}>
          <h1>An OTP has been sent</h1>
          <p>
            A One-Time PIN has been sent to{" "}
            {!!phone ? `${phone}` : "your WhatsApp number"}. Input the PIN below
            to proceed.
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className={styles["resetPinOTP__field-form"]}
        >
          <PinInput
            length={5}
            focus
            initialValue=""
            // secret
            onChange={(value, index) => {
              setOtp(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "12px",
              display: " flex",
              justifyContent: " center",
              alignItems: " center",
              gap: "0.5rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            inputStyle={{
              borderColor: "#808080",
              width: "17.5%",
              height: "78px",
              fontSize: "2.25rem",
              borderRadius: "2px",
            }}
            inputFocusStyle={{
              borderColor: "#1fad3e",
              caretColor: "#1fad3e",
            }}
            onComplete={(value, index) => {
              if (value.length === 5) {
                verifyPinOtpfn({
                  variables: {
                    otp: value,
                    eventId: eventId,
                  },
                });
              }
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <div className={styles["resetPinOTP__button"]}>
            <Button loader={verifyPinOtpLoading} type="submit" label="Proceed">
              Proceed
            </Button>
            <div>
              <p className={styles["resetPinOTP__code"]}>
                Didn't receive a Code?{" "}
                <span
                  className={styles["resetPinOTP__resend"]}
                  onClick={() => {
                    ResetPinFn({
                      variables: {
                        eventId: eventId,
                      },
                    });
                  }}
                >
                  Resend
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPinOTP;
