import { useState } from "react";
import Tab from "src/components/Tab";
import { GetMedia as getMedia } from "src/graphql/queries";
import useCustomQuery from "src/hooks/useCustomQuery";
import DashboardLayout from "src/layouts/dashboard";
import { cn } from "src/lib/utils";
import useAppStore from "src/store/utils";
import { GetMedia } from "src/types/api";
import { photoBookTabTypes, SelectedAlbumType } from "src/utils/types";
import styles from "./photobook.module.scss";
import YourAlbum from "./view/Albums";
import GuestsUploads from "./view/GuestUploads";
import YourPhotos from "./view/Photos";

const tabItems: photoBookTabTypes[] = ["photos", "guestsUploads", "albums"];
const Photobook: React.FC = () => {
  const [tabState, setTabState] = useState<photoBookTabTypes>("photos");
  const { event } = useAppStore((state) => state);
  const [selectedAlbum, setSelectedAlbum] = useState<SelectedAlbumType>();
  const page = 1;
  const pageSize = 200;

  const handleTab = (element: photoBookTabTypes) => {
    setTabState(element);
  };

  const tab: Record<photoBookTabTypes, JSX.Element | null> = {
    photos: (
      <YourPhotos
        setTabState={setTabState}
        setSelectedAlbum={setSelectedAlbum}
      />
    ),
    guestsUploads: <GuestsUploads />,
    albums: null,
  };

  const { data: images, isLoading: getMediaLoading } = useCustomQuery<GetMedia>(
    getMedia,
    {
      eventId: event.uuid,
      filter: { album_id: selectedAlbum?.id, page, pageSize },
    },
    !event.uuid
  );

  return (
    <DashboardLayout pageName="Photobook">
      <div className={styles["photobook"]}>
        {tabState === "albums" ? (
          <YourAlbum
            setTabState={setTabState}
            images={images?.getMedia ?? []}
            loading={getMediaLoading}
            selectedAlbum={selectedAlbum}
          />
        ) : (
          <>
            <div className={styles["photobook__tabs"]}>
              <Tab
                tabItems={tabItems.filter((item) => item !== "albums")}
                tabState={tabState}
                handleTab={handleTab}
              />
            </div>
            <div className={cn(styles["photobook__main"], `pb-[2rem]`)}>
              {tab[tabState]}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Photobook;
