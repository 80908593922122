import { useQuery } from "@apollo/client";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GetLuxeDetails } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { DescribeLuxe } from "src/types/api.d";
import PaymentOptions from "./PaymentOptions";
import PaymentPrompt from "./PaymentPrompt";

const Index = ({ setOpen }: { setOpen: (value: boolean) => void }) => {
  const navigate = useNavigate();
  const [step, setStep] = React.useState<number>(1);
  const { event } = useAppStore((state) => state);

  const { data, loading } = useQuery<DescribeLuxe>(GetLuxeDetails, {
    fetchPolicy: "network-only",
    variables: { eventId: event?.uuid },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div>
      {step === 1 && (
        <PaymentPrompt
          cost={data?.describeLuxe?.discounted_amount}
          step={step}
          setStep={setStep}
          setOpen={setOpen}
          currency={data?.describeLuxe?.currency}
        />
      )}
      {step === 2 && (
        <PaymentOptions
          setOpen={setOpen}
          cost={data?.describeLuxe?.discounted_amount}
        />
      )}
    </div>
  );
};

export default Index;
