import { useMutation } from "@apollo/client";
import axios from "axios";
import { Field, Form, Formik, FormikProps } from "formik";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import Textarea from "src/components/Textarea";
import { AddBackStories } from "src/graphql/mutations";
import { GetBackStory } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import {
  BackStoryRole,
  addBackStory,
  addBackStoryVariables,
} from "src/types/api";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import styles from "./addBackstory.module.scss";

export interface Values {
  story: string;
  role: BackStoryRole | "";
}

const AddBackstorySchema = Yup.object().shape({
  role: Yup.string().required("Kindly select a role"),
  story: Yup.string()
    .min(150, "Your backstory should be a minimum of 150 characters.")
    .required("Write a story"),
});

interface AddBackstoryProps {
  setToggleModal: (toggleModal: boolean) => void;
}

const AddBackstory: React.FC<AddBackstoryProps> = ({ setToggleModal }) => {
  const [url, setUrl] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [cloudinaryLoading, setCloudinaryLoading] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const reader = new FileReader();

  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const uploadPicture = useCallback(() => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_FOLDER!);
    axios
      .post(
        "https://api.Cloudinary.com/v1_1/inawo-medias/image/upload",
        formData
      )
      .then((response) => {
        setUrl(
          `${response?.data?.secure_url}?inawoHeight=${response?.data?.height}&inawoWidth=${response?.data?.width}`
        );
        setCloudinaryLoading(false);
        setImage(response.data.secure_url);
        // toast.success(
        //   <p className="toast">You have successfully uploaded the image.</p>
        // );
      })
      .catch((error) => {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
        setCloudinaryLoading(false);
      });
    setCloudinaryLoading(true);
  }, [image]);

  useEffect(() => {
    if (url === "" && image !== "") {
      uploadPicture();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const [addBackStory, { loading }] = useMutation<
    addBackStory,
    addBackStoryVariables
  >(AddBackStories, {
    onCompleted() {
      setToggleModal(false);
      toast.success(
        <p className="toast">You have successfully added a backstory.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["addBackstory"]}>
      <div className={styles["addBackstory-heading"]}>
        <h4>Add Backstory</h4>
      </div>
      <Formik
        initialValues={{
          role: "",
          story: "",
        }}
        validationSchema={AddBackstorySchema}
        onSubmit={(values, actions) => {
          values.role !== "" &&
            addBackStory({
              variables: {
                eventId: event.id,
                input: {
                  title: "",
                  role: values.role,
                  story: values.story,
                  image: url,
                },
              },
              refetchQueries: [
                { query: GetBackStory, variables: { eventId: event.id } },
              ],
            });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addBackstory-form"]}>
            <div className={styles["addBackstory-form-container"]}>
              <div className={styles["addBackstory-form-select"]}>
                <label htmlFor="role">Role</label>
                <Field
                  onChange={(e: any) =>
                    props.setFieldValue("role", e.target.value)
                  }
                  as="select"
                  name="role"
                >
                  <option disabled hidden value="">
                    Select role
                  </option>
                  <option value="Groom">Groom</option>
                  <option value="Bride">Bride</option>
                </Field>
              </div>
              <Textarea
                rows={3}
                name="story"
                placeholder="Enter story details"
                label="Story (min 150 characters)"
              />
              <div className={styles["addBackstory-image-box-container"]}>
                <p>Image</p>
                <div className={styles["addBackstory-image-box"]}>
                  {isSelected === false && url === "" ? (
                    <>
                      <input
                        id="add-file"
                        type="file"
                        className={styles["file"]}
                        name="file"
                        accept="image/*"
                        onChange={(event: any) => {
                          if (event.target.files[0] && event.target.files) {
                            reader.onload = function (e: any) {
                              setImage(e.target.result);
                            };
                            reader.readAsDataURL(event.target.files[0]);
                            setIsSelected(true);
                          }
                        }}
                        onBlur={props.handleBlur}
                      ></input>
                      <label htmlFor="add-file">
                        <span>
                          <Icon iconName="link" />
                          Click here to upload file
                        </span>
                        <br />
                        <span>.png or .jpg only</span>
                      </label>
                    </>
                  ) : isSelected === true && url === "" ? (
                    <InnerLoading />
                  ) : (
                    <img src={image} alt="addPictures" />
                  )}
                </div>
              </div>
            </div>
            <div className={styles["addBackstory-button-container"]}>
              <div className={styles["addBackstory-button"]}>
                <Button
                  type="submit"
                  label="Add BackStory"
                  loader={loading}
                  disabled={
                    props.values.role === "" ||
                    props.values.story === "" ||
                    url === "" ||
                    cloudinaryLoading
                  }
                >
                  Add
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddBackstory;
