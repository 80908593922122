import * as RadioGroup from "@radix-ui/react-radio-group";

interface PaymentptionProps {
  type: string;
  description?: string;
  active: boolean;
  onClick: () => void;
}

const PaymentOption = ({
  type,
  onClick,
  active,
  description,
}: PaymentptionProps) => {
  return (
    <div
      onClick={onClick}
      className={`${
        active ? "bg-[#EFF5F4]" : ""
      } flex cursor-pointer items-center gap-4 rounded-lg border border-[#e4e4e7] px-3 py-4 hover:bg-[#EFF5F4]`}
    >
      <RadioGroup.Root
        className="flex flex-col space-y-2.5"
        aria-label="View density"
      >
        <RadioGroup.Item
          className={`${
            active ? "h-2 w-2 bg-primary-6 ring-4" : "h-4 w-4"
          } rounded-full border border-solid border-[#CBD5E1] bg-white shadow-md ring-primary-6`}
          value="default"
          id="r1"
        >
          <RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center"></RadioGroup.Indicator>
        </RadioGroup.Item>
      </RadioGroup.Root>

      <div>
        <h2 className="font-medium">{type}</h2>
        {description && <p className="text-sm text-[#71717A]">{description}</p>}
      </div>
    </div>
  );
};

export default PaymentOption;
