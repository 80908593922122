import { FC, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FallbackComponent from "src/components/Fallback";
import { AppContext, ValueProps } from "src/context";

const AcceptTeamInvite: FC = () => {
  let [searchParams] = useSearchParams();
  const contextValue = useContext<ValueProps | null>(AppContext);
  const setAcceptTeamToken = contextValue?.setAcceptTeamToken;
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("token") && setAcceptTeamToken) {
      setAcceptTeamToken(searchParams.get("token"));
      // console.log("object :>> ", searchParams.get("token"));
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return <FallbackComponent />;
};

export default AcceptTeamInvite;
