import { motion } from "framer-motion";
import CloseV2 from "src/assets/icons/closeV2";
import RetryIcon from "src/assets/icons/retryIcon";
import Dancer from "../../../assets/images/01.png";

export type UploadValue = { file: File; preview: string }[];

const CollapsedUpload = ({
  collapsed,
  fileUploaded,
  percent,
  totalItems,
  totalUploaded,
  timeLeft,
  isCompleted,
  setRetryUploads,
  onClose,
}: {
  fileUploaded: UploadValue;
  collapsed: boolean;
  percent: number;
  totalItems: number;
  totalUploaded: number;
  timeLeft: number;
  isCompleted: boolean;
  setRetryUploads: () => void;
  onClose: () => void;
}) => {
  return (
    <motion.div
      initial={{ height: 700, opacity: 0 }}
      animate={{
        height: !collapsed ? 0 : "auto",
        opacity: !collapsed ? 0 : 1,
      }}
      transition={{ duration: 0.3 }}
      className="w-full rounded-[14px] bg-white px-[30px] py-[14px] md:w-[524px]"
    >
      <p className="mb-[15px] text-[15px] font-[500] text-[#71717A]">
        Uploading {totalUploaded} of {totalItems} items
      </p>
      <div className="flex items-center justify-between overflow-hidden">
        <div className="flex w-full gap-[5px]">
          {fileUploaded
            ?.slice(0, 3)
            .map(({ file, preview }: any, idx: number) => (
              <div
                key={idx}
                className="relative flex h-[45px] w-[45px] items-center justify-center overflow-hidden rounded-[9px]"
              >
                {file?.type?.includes("video/") ? (
                  <video preload="none" className="h-full w-full object-cover">
                    <source src={preview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={preview || Dancer}
                    alt={`pov ${idx}`}
                    className="h-full w-full object-cover"
                  />
                )}
                {fileUploaded?.length > 3 && idx === 2 && (
                  <div className="dark:text-black absolute left-0 top-0 z-[1] grid h-full w-full place-items-center bg-[rgba(59,95,89,0.5)] text-lg font-semibold text-white">
                    <span>+{fileUploaded?.length - 3}</span>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className="flex items-center gap-[5px]">
          {/* <div
            className="w-[29px] flex items-center justify-center h-[29px] rounded-[10px] bg-[#F2F4F3] text-[#71717A]"
            role="button"
          >
            <PauseIcon />
          </div> */}
          {percent === 100 && !isCompleted && (
            <div
              className="flex h-[29px] w-[29px] items-center justify-center rounded-[10px] bg-[#F2F4F3] text-[#71717A]"
              role="button"
              onClick={() => {
                setRetryUploads();
              }}
            >
              <RetryIcon className="text-[#FFF1F1]" />
            </div>
          )}
          <div
            className="flex h-[29px] w-[29px] items-center justify-center rounded-[10px] bg-[#FFF1F1] text-[#DC2626]"
            role="button"
            onClick={onClose}
          >
            <CloseV2 className="stroke-[#DC2626]" />
          </div>
        </div>
      </div>
      <div className="relative mt-3 h-[3px] w-full rounded-[6px] bg-[#F2F2F2]">
        <span
          className={`absolute h-full rounded-[6px] bg-[#4E7E76]`}
          style={{
            width: `${percent}%`,
          }}
        />
      </div>
      <p className="mt-3 text-xs font-[500] text-[rgba(113,113,122,0.51)] dark:text-[rgba(113,113,122,1)]">
        <span className="text-[#4E7E76]">{timeLeft}sec</span> left
      </p>
    </motion.div>
  );
};

export default CollapsedUpload;
