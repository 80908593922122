import { ChangeEvent, EventHandler, FocusEvent } from "react";
import Icon from "src/components/Icon";
import styles from "./addPictures.module.scss";

const UploadBox = ({
  onUpload,
  handleBlur,
}: {
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: EventHandler<FocusEvent<HTMLInputElement>>;
}) => {
  return (
    <>
      <input
        id="add-file"
        multiple
        type="file"
        className={styles["file"]}
        name="image"
        accept="image/png,image/jpeg,video/mp4, video/avi, video/webm"
        onChange={onUpload}
        onBlur={handleBlur}
      ></input>
      <label htmlFor="add-file">
        <p className="mb-6 grid place-content-center">
          <Icon iconName="uploadplaceholder" />
        </p>
        <span>Click here to upload your images/videos</span>
        <br />
        <span>.png or .jpg or .mp4 or .avi or .webm only</span>
        <p className="mt-6 grid place-content-center">
          <Icon iconName="imageplus" />
        </p>
      </label>
    </>
  );
};

export default UploadBox;
