import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { DeleteEventVendor } from "src/graphql/mutations";
import { GetPlanner } from "src/graphql/queries";

import {
  eventVendor_eventVendor,
  removeEventVendor,
  removeEventVendorVariables,
} from "src/types/api";

import styles from "./styles.module.scss";

interface RemoveEventVendorProps {
  page: number;
  pageSize: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  vendor: eventVendor_eventVendor;
}

const RemoveVendor = ({
  page,
  pageSize,
  vendor,
  setOpen,
}: RemoveEventVendorProps) => {
  const navigate = useNavigate();
  const [removeEventVendor, { loading }] = useMutation<
    removeEventVendor,
    removeEventVendorVariables
  >(DeleteEventVendor, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully removed a vendor.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div
      className={`${styles["removePlannerModal--content"]} ${styles["show"]}`}
    >
      <h1>Remove Vendor</h1>
      <p>
        You are about to remove this team from your event. Do you wish to
        proceed?
      </p>
      <span className={styles["removePlannerModal--content-btnContainer"]}>
        <div>
          <Button
            type="submit"
            onClick={() =>
              removeEventVendor({
                variables: {
                  eventId: vendor.event_id,
                  vendorId: vendor.id,
                },
                refetchQueries: [
                  {
                    query: GetPlanner,
                    variables: { eventId: vendor.event_id, page, pageSize },
                  },
                ],
              })
            }
            label="Yes, Proceed"
            loader={loading}
          >
            Yes, Proceed
          </Button>
        </div>
      </span>
    </div>
  );
};

export default RemoveVendor;
