import { AddEmptyIcon } from "src/assets/svg/add";
import AddPictures from "../AddPictures";

const EmptyState = () => {
  return (
    <div className="mt-[4rem] flex w-full items-center justify-center">
      <div className="flex max-w-[400px] flex-col items-center justify-center">
        <AddEmptyIcon />
        <p className="text-center leading-5 text-[#707070]">
          Add all your beautiful photos/videos and albums for your wedding and
          see those from your guests
        </p>

        <AddPictures
          type="photo"
          trigger={
            <div
              className={
                "mt-5 w-fit rounded-[11px] bg-[#34544F] px-7 py-3 text-white"
              }
            >
              <h4>Add</h4>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default EmptyState;
