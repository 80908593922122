const AccessControlIcon = () => {
  return (
    <svg
      width="251"
      height="196"
      viewBox="0 0 251 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4088 64.3568C39.4088 62.188 38.9817 60.0405 38.1517 58.0368C37.3218 56.0331 36.1053 54.2126 34.5717 52.679C33.0382 51.1455 31.2176 49.929 29.2139 49.099C27.2103 48.2691 25.0627 47.8419 22.894 47.8419C20.7252 47.8419 18.5777 48.2691 16.574 49.099C14.5703 49.929 12.7497 51.1455 11.2162 52.679C9.68264 54.2126 8.46616 56.0331 7.63621 58.0368C6.80626 60.0405 6.37909 62.188 6.37909 64.3568L22.894 64.3568H39.4088Z"
        fill="url(#paint0_linear_1021_16543)"
      />
      <path
        d="M39.4088 64.3568C39.4088 62.188 38.9817 60.0405 38.1517 58.0368C37.3218 56.0331 36.1053 54.2126 34.5717 52.679C33.0382 51.1455 31.2176 49.929 29.2139 49.099C27.2103 48.2691 25.0627 47.8419 22.894 47.8419C20.7252 47.8419 18.5777 48.2691 16.574 49.099C14.5703 49.929 12.7497 51.1455 11.2162 52.679C9.68264 54.2126 8.46616 56.0331 7.63621 58.0368C6.80626 60.0405 6.37909 62.188 6.37909 64.3568L22.894 64.3568H39.4088Z"
        fill="black"
        fill-opacity="0.1"
      />
      <circle cx="22.894" cy="42.3374" r="11.0099" fill="#619D94" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8922 49.9066C28.8856 52.0256 26.0455 53.3474 22.8968 53.3474C19.748 53.3474 16.9079 52.0256 14.9012 49.9065C15.443 49.6067 16.0023 49.337 16.5766 49.0991C18.5803 48.2692 20.7278 47.842 22.8966 47.842C25.0653 47.842 27.2129 48.2692 29.2165 49.0991C29.791 49.3371 30.3504 49.6068 30.8922 49.9066Z"
        fill="url(#paint1_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8922 49.9066C28.8856 52.0256 26.0455 53.3474 22.8968 53.3474C19.748 53.3474 16.9079 52.0256 14.9012 49.9065C15.443 49.6067 16.0023 49.337 16.5766 49.0991C18.5803 48.2692 20.7278 47.842 22.8966 47.842C25.0653 47.842 27.2129 48.2692 29.2165 49.0991C29.791 49.3371 30.3504 49.6068 30.8922 49.9066Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M79.0671 101.236C79.0671 99.0675 78.6399 96.92 77.81 94.9163C76.98 92.9126 75.7636 91.092 74.23 89.5585C72.6965 88.0249 70.8759 86.8085 68.8722 85.9785C66.8685 85.1486 64.721 84.7214 62.5522 84.7214C60.3835 84.7214 58.2359 85.1486 56.2323 85.9785C54.2286 86.8085 52.408 88.0249 50.8744 89.5585C49.3409 91.092 48.1244 92.9126 47.2945 94.9163C46.4645 96.92 46.0374 99.0675 46.0374 101.236L62.5522 101.236H79.0671Z"
        fill="url(#paint2_linear_1021_16543)"
      />
      <path
        d="M79.0671 101.236C79.0671 99.0675 78.6399 96.92 77.81 94.9163C76.98 92.9126 75.7636 91.092 74.23 89.5585C72.6965 88.0249 70.8759 86.8085 68.8722 85.9785C66.8685 85.1486 64.721 84.7214 62.5522 84.7214C60.3835 84.7214 58.2359 85.1486 56.2323 85.9785C54.2286 86.8085 52.408 88.0249 50.8744 89.5585C49.3409 91.092 48.1244 92.9126 47.2945 94.9163C46.4645 96.92 46.0374 99.0675 46.0374 101.236L62.5522 101.236H79.0671Z"
        fill="black"
        fill-opacity="0.1"
      />
      <circle cx="62.5523" cy="79.2175" r="11.0099" fill="#619D94" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.551 86.7862C68.5443 88.9055 65.7041 90.2274 62.5552 90.2274C59.4061 90.2274 56.5659 88.9054 54.5591 86.7861C55.1011 86.4862 55.6605 86.2165 56.235 85.9785C58.2386 85.1486 60.3862 84.7214 62.5549 84.7214C64.7237 84.7214 66.8712 85.1486 68.8749 85.9785C69.4495 86.2165 70.009 86.4863 70.551 86.7862Z"
        fill="url(#paint3_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.551 86.7862C68.5443 88.9055 65.7041 90.2274 62.5552 90.2274C59.4061 90.2274 56.5659 88.9054 54.5591 86.7861C55.1011 86.4862 55.6605 86.2165 56.235 85.9785C58.2386 85.1486 60.3862 84.7214 62.5549 84.7214C64.7237 84.7214 66.8712 85.1486 68.8749 85.9785C69.4495 86.2165 70.009 86.4863 70.551 86.7862Z"
        fill="black"
        fill-opacity="0.1"
      />
      <g clip-path="url(#clip0_1021_16543)">
        <path
          d="M110.022 159.456C110.022 156.322 109.587 153.219 108.742 150.323C107.897 147.428 106.659 144.798 105.098 142.582C103.537 140.366 101.683 138.608 99.6434 137.409C97.6035 136.209 95.4173 135.592 93.2094 135.592C91.0015 135.592 88.8152 136.209 86.7754 137.409C84.7356 138.608 82.8822 140.366 81.321 142.582C79.7597 144.798 78.5213 147.428 77.6764 150.323C76.8315 153.219 76.3966 156.322 76.3966 159.456L93.2094 159.456H110.022Z"
          fill="url(#paint4_linear_1021_16543)"
        />
        <path
          d="M110.022 159.456C110.022 156.322 109.587 153.219 108.742 150.323C107.897 147.428 106.659 144.798 105.098 142.582C103.537 140.366 101.683 138.608 99.6434 137.409C97.6035 136.209 95.4173 135.592 93.2094 135.592C91.0015 135.592 88.8152 136.209 86.7754 137.409C84.7356 138.608 82.8822 140.366 81.321 142.582C79.7597 144.798 78.5213 147.428 77.6764 150.323C76.8315 153.219 76.3966 156.322 76.3966 159.456L93.2094 159.456H110.022Z"
          fill="black"
          fill-opacity="0.1"
        />
        <circle cx="93.3368" cy="129.881" r="11.0099" fill="#619D94" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.335 137.45C99.3288 139.569 96.4886 140.891 93.3396 140.891C90.1906 140.891 87.3504 139.569 85.3436 137.45C85.8855 137.15 86.445 136.88 87.0194 136.642C89.0231 135.813 91.1706 135.385 93.3394 135.385C95.5082 135.385 97.6557 135.813 99.6594 136.642C100.234 136.88 100.793 137.15 101.335 137.45Z"
          fill="url(#paint5_linear_1021_16543)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.335 137.45C99.3288 139.569 96.4886 140.891 93.3396 140.891C90.1906 140.891 87.3504 139.569 85.3436 137.45C85.8855 137.15 86.445 136.88 87.0194 136.642C89.0231 135.813 91.1706 135.385 93.3394 135.385C95.5082 135.385 97.6557 135.813 99.6594 136.642C100.234 136.88 100.793 137.15 101.335 137.45Z"
          fill="black"
          fill-opacity="0.1"
        />
      </g>
      <rect
        x="140.563"
        y="97.1372"
        width="57.8988"
        height="56.9496"
        rx="14.712"
        transform="rotate(6.3364 140.563 97.1372)"
        fill="#A0BAB6"
      />
      <rect
        x="158.494"
        y="74.4248"
        width="67.7922"
        height="117.224"
        rx="22.5974"
        fill="url(#paint6_linear_1021_16543)"
      />
      <rect
        x="158.494"
        y="74.4248"
        width="67.7922"
        height="117.224"
        rx="22.5974"
        fill="black"
        fill-opacity="0.1"
      />
      <g filter="url(#filter0_bi_1021_16543)">
        <rect
          x="164"
          y="80"
          width="65"
          height="116"
          rx="17.1921"
          fill="#FDFDFD"
          fill-opacity="0.6"
        />
        <rect
          x="164.4"
          y="80.4"
          width="64.2"
          height="115.2"
          rx="16.7921"
          stroke="url(#paint7_linear_1021_16543)"
          strokeWidth="0.8"
        />
      </g>
      <circle
        cx="199.39"
        cy="183.289"
        r="5.64935"
        fill="url(#paint8_linear_1021_16543)"
      />
      <circle
        cx="199.39"
        cy="183.289"
        r="5.64935"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.989 115.247C162.044 114.72 161.662 114.248 161.135 114.192L152.945 113.326C152.725 113.303 152.529 113.462 152.505 113.681L152.463 114.079C152.463 114.079 152.463 114.079 152.463 114.079C152.463 114.079 152.463 114.079 152.463 114.079L151.555 122.667C151.499 123.194 151.881 123.666 152.408 123.722L154.317 123.924C154.844 123.98 155.316 123.597 155.372 123.07L155.859 118.458C155.915 117.931 156.388 117.549 156.915 117.605L160.732 118.009C161.259 118.065 161.731 117.682 161.787 117.155L161.989 115.247Z"
        fill="url(#paint9_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.989 115.247C162.044 114.72 161.662 114.248 161.135 114.192L152.945 113.326C152.725 113.303 152.529 113.462 152.505 113.681L152.463 114.079C152.463 114.079 152.463 114.079 152.463 114.079C152.463 114.079 152.463 114.079 152.463 114.079L151.555 122.667C151.499 123.194 151.881 123.666 152.408 123.722L154.317 123.924C154.844 123.98 155.316 123.597 155.372 123.07L155.859 118.458C155.915 117.931 156.388 117.549 156.915 117.605L160.732 118.009C161.259 118.065 161.731 117.682 161.787 117.155L161.989 115.247Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.247 141.17C159.191 141.697 158.719 142.079 158.192 142.023L150.002 141.157C149.782 141.134 149.623 140.937 149.646 140.717L149.688 140.319C149.688 140.319 149.688 140.319 149.688 140.319C149.688 140.319 149.688 140.319 149.688 140.319L150.596 131.731C150.652 131.204 151.124 130.822 151.651 130.878L153.56 131.08C154.087 131.136 154.469 131.608 154.413 132.135L153.925 136.747C153.87 137.274 154.252 137.747 154.779 137.803L158.596 138.206C159.123 138.262 159.505 138.734 159.449 139.261L159.247 141.17Z"
        fill="url(#paint10_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.247 141.17C159.191 141.697 158.719 142.079 158.192 142.023L150.002 141.157C149.782 141.134 149.623 140.937 149.646 140.717L149.688 140.319C149.688 140.319 149.688 140.319 149.688 140.319C149.688 140.319 149.688 140.319 149.688 140.319L150.596 131.731C150.652 131.204 151.124 130.822 151.651 130.878L153.56 131.08C154.087 131.136 154.469 131.608 154.413 132.135L153.925 136.747C153.87 137.274 154.252 137.747 154.779 137.803L158.596 138.206C159.123 138.262 159.505 138.734 159.449 139.261L159.247 141.17Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.894 116.189C170.95 115.662 171.423 115.28 171.95 115.335L179.583 116.143C180.11 116.198 180.492 116.671 180.437 117.198L180.134 120.06C180.134 120.06 180.134 120.06 180.134 120.06C180.134 120.06 180.134 120.06 180.134 120.06L179.545 125.627C179.489 126.154 179.017 126.536 178.49 126.48L176.582 126.278C176.055 126.222 175.673 125.75 175.728 125.223L176.216 120.611C176.272 120.084 175.89 119.612 175.363 119.556L171.546 119.152C171.019 119.096 170.637 118.624 170.693 118.097L170.894 116.189Z"
        fill="url(#paint11_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.894 116.189C170.95 115.662 171.423 115.28 171.95 115.335L179.583 116.143C180.11 116.198 180.492 116.671 180.437 117.198L180.134 120.06C180.134 120.06 180.134 120.06 180.134 120.06C180.134 120.06 180.134 120.06 180.134 120.06L179.545 125.627C179.489 126.154 179.017 126.536 178.49 126.48L176.582 126.278C176.055 126.222 175.673 125.75 175.728 125.223L176.216 120.611C176.272 120.084 175.89 119.612 175.363 119.556L171.546 119.152C171.019 119.096 170.637 118.624 170.693 118.097L170.894 116.189Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.153 142.112C168.097 142.639 168.479 143.111 169.006 143.167L176.64 143.974C177.167 144.03 177.639 143.648 177.695 143.121L177.998 140.258C177.998 140.258 177.998 140.258 177.998 140.258C177.998 140.258 177.998 140.258 177.998 140.258L178.586 134.692C178.642 134.165 178.26 133.692 177.733 133.637L175.825 133.435C175.298 133.379 174.825 133.761 174.77 134.288L174.282 138.9C174.226 139.427 173.754 139.809 173.227 139.753L169.41 139.35C168.883 139.294 168.411 139.676 168.355 140.203L168.153 142.112Z"
        fill="url(#paint12_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.153 142.112C168.097 142.639 168.479 143.111 169.006 143.167L176.64 143.974C177.167 144.03 177.639 143.648 177.695 143.121L177.998 140.258C177.998 140.258 177.998 140.258 177.998 140.258C177.998 140.258 177.998 140.258 177.998 140.258L178.586 134.692C178.642 134.165 178.26 133.692 177.733 133.637L175.825 133.435C175.298 133.379 174.825 133.761 174.77 134.288L174.282 138.9C174.226 139.427 173.754 139.809 173.227 139.753L169.41 139.35C168.883 139.294 168.411 139.676 168.355 140.203L168.153 142.112Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M214.843 147.035C214.843 145.036 214.449 143.056 213.684 141.209C212.919 139.361 211.797 137.683 210.383 136.269C208.969 134.855 207.291 133.734 205.444 132.969C203.597 132.204 201.617 131.81 199.617 131.81C197.618 131.81 195.638 132.204 193.791 132.969C191.944 133.734 190.265 134.855 188.851 136.269C187.438 137.683 186.316 139.361 185.551 141.209C184.786 143.056 184.392 145.036 184.392 147.035L199.617 147.035H214.843Z"
        fill="url(#paint13_linear_1021_16543)"
      />
      <path
        d="M214.843 147.035C214.843 145.036 214.449 143.056 213.684 141.209C212.919 139.361 211.797 137.683 210.383 136.269C208.969 134.855 207.291 133.734 205.444 132.969C203.597 132.204 201.617 131.81 199.617 131.81C197.618 131.81 195.638 132.204 193.791 132.969C191.944 133.734 190.265 134.855 188.851 136.269C187.438 137.683 186.316 139.361 185.551 141.209C184.786 143.056 184.392 145.036 184.392 147.035L199.617 147.035H214.843Z"
        fill="black"
        fill-opacity="0.1"
      />
      <circle cx="199.617" cy="126.735" r="10.1502" fill="#619D94" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.991 133.713C205.141 135.667 202.523 136.885 199.62 136.885C196.717 136.885 194.098 135.667 192.248 133.713C192.748 133.437 193.264 133.188 193.793 132.969C195.64 132.204 197.62 131.81 199.62 131.81C201.619 131.81 203.599 132.204 205.446 132.969C205.976 133.188 206.491 133.437 206.991 133.713Z"
        fill="url(#paint14_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.991 133.713C205.141 135.667 202.523 136.885 199.62 136.885C196.717 136.885 194.098 135.667 192.248 133.713C192.748 133.437 193.264 133.188 193.793 132.969C195.64 132.204 197.62 131.81 199.62 131.81C201.619 131.81 203.599 132.204 205.446 132.969C205.976 133.188 206.491 133.437 206.991 133.713Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.005 0H238.995C245.625 0 251 5.3746 251 12.0045L251 182.998C251 186.313 248.313 189 244.998 189C241.683 189 238.995 186.313 238.995 182.998V68.3637C238.995 39.6449 215.714 16.3636 186.995 16.3636H176.005C147.286 16.3636 124.005 39.6449 124.005 68.3637V182.998C124.005 186.313 121.317 189 118.002 189C114.687 189 112 186.313 112 182.998V16.3636V12.0045C112 5.37462 117.375 0 124.005 0Z"
        fill="url(#paint15_linear_1021_16543)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.005 0H238.995C245.625 0 251 5.3746 251 12.0045L251 182.998C251 186.313 248.313 189 244.998 189C241.683 189 238.995 186.313 238.995 182.998V68.3637C238.995 39.6449 215.714 16.3636 186.995 16.3636H176.005C147.286 16.3636 124.005 39.6449 124.005 68.3637V182.998C124.005 186.313 121.317 189 118.002 189C114.687 189 112 186.313 112 182.998V16.3636V12.0045C112 5.37462 117.375 0 124.005 0Z"
        fill="black"
        fill-opacity="0.1"
      />
      <defs>
        <filter
          id="filter0_bi_1021_16543"
          x="151"
          y="67"
          width="91"
          height="142"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1021_16543"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1021_16543"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="10"
            operator="erode"
            in="SourceAlpha"
            result="effect2_innerShadow_1021_16543"
          />
          <feOffset dx="-6" dy="6" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1021_16543"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1021_16543"
          x1="13.1653"
          y1="47.8419"
          x2="24.4611"
          y2="73.1525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1021_16543"
          x1="18.1866"
          y1="47.842"
          x2="21.0276"
          y2="57.0871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1021_16543"
          x1="52.8235"
          y1="84.7214"
          x2="64.1194"
          y2="110.032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1021_16543"
          x1="57.8448"
          y1="84.7214"
          x2="60.6862"
          y2="93.9674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1021_16543"
          x1="83.3052"
          y1="135.592"
          x2="103.131"
          y2="166.891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1021_16543"
          x1="88.6293"
          y1="135.385"
          x2="91.4707"
          y2="144.631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1021_16543"
          x1="172.422"
          y1="74.4248"
          x2="270.737"
          y2="138.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1021_16543"
          x1="164"
          y1="89"
          x2="225.5"
          y2="182.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stop-opacity="0.5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1021_16543"
          x1="196.062"
          y1="177.639"
          x2="206.378"
          y2="189.197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1021_16543"
          x1="154.508"
          y1="113.491"
          x2="162.963"
          y2="124.314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1021_16543"
          x1="151.564"
          y1="141.322"
          x2="162.097"
          y2="132.508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1021_16543"
          x1="178.577"
          y1="116.036"
          x2="168.045"
          y2="124.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1021_16543"
          x1="175.634"
          y1="143.867"
          x2="167.178"
          y2="133.045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1021_16543"
          x1="190.648"
          y1="131.81"
          x2="201.062"
          y2="155.144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1021_16543"
          x1="195.277"
          y1="131.81"
          x2="197.897"
          y2="140.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1021_16543"
          x1="140.558"
          y1="5.2891e-07"
          x2="311.029"
          y2="140.461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <clipPath id="clip0_1021_16543">
          <rect
            width="44.0397"
            height="44.0397"
            fill="white"
            transform="translate(71.3171 115.201)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccessControlIcon;

export const ActivateAccessControlIcon = () => {
  return (
    <svg
      width="160"
      height="138"
      viewBox="0 0 160 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.61169"
        y="21.6302"
        width="70.4788"
        height="69.3234"
        rx="17.9086"
        transform="rotate(6.3364 8.61169 21.6302)"
        fill="#A0BAB6"
      />
      <g filter="url(#filter0_f_1021_16495)">
        <path
          d="M124.61 47.3679C124.13 45.8022 122.291 40.0335 117.828 36.7225C115.611 35.1131 113.155 34.3376 110.682 34.4664C102.954 34.6266 97.6845 42.8474 96.4607 44.8577C95.2388 42.8474 89.9679 34.6404 82.2399 34.4664C79.7665 34.3376 77.3103 35.1131 75.0936 36.7225C70.6309 40.0363 68.7914 45.8022 68.3118 47.3679C67.37 50.4973 66.9103 53.8911 66.9652 57.3091C66.9652 58.5131 67.0351 63.4257 69.1503 69.8572C71.3127 76.4349 74.3703 80.4059 76.4571 83.3883C81.5589 90.6341 87.2577 96.9413 93.3808 102.223C95.2822 103.863 97.6393 103.863 99.5407 102.223C105.664 96.9413 111.363 90.6341 116.464 83.3883C118.542 80.4114 121.609 76.4405 123.773 69.8572C125.886 63.4257 125.954 58.5131 125.956 57.3091C126.012 53.891 125.552 50.4968 124.61 47.3679Z"
          fill="url(#paint0_linear_1021_16495)"
        />
      </g>
      <g filter="url(#filter1_bi_1021_16495)">
        <rect
          x="39.035"
          width="65.3298"
          height="128.94"
          rx="17.1921"
          fill="#FDFDFD"
          fill-opacity="0.59"
        />
        <rect
          x="39.435"
          y="0.4"
          width="64.5298"
          height="128.14"
          rx="16.7921"
          stroke="white"
          strokeWidth="0.8"
        />
      </g>
      <g opacity="0.8">
        <circle
          cx="71.6999"
          cy="114.328"
          r="6.87683"
          fill="url(#paint1_linear_1021_16495)"
        />
        <circle
          cx="71.6999"
          cy="114.328"
          r="6.87683"
          fill="black"
          fill-opacity="0.1"
        />
      </g>
      <g filter="url(#filter2_bi_1021_16495)">
        <rect
          x="58.9608"
          y="24.4527"
          width="72.481"
          height="73.8572"
          rx="14.59"
          fill="white"
          fill-opacity="0.5"
        />
        <rect
          x="59.3739"
          y="24.8658"
          width="71.6548"
          height="73.031"
          rx="14.1769"
          stroke="url(#paint2_linear_1021_16495)"
          stroke-opacity="0.5"
          strokeWidth="0.826168"
        />
      </g>
      <path
        d="M113.031 75.3699C113.031 72.9361 112.551 70.5261 111.62 68.2775C110.688 66.0289 109.323 63.9858 107.602 62.2648C105.881 60.5438 103.838 59.1787 101.59 58.2473C99.3411 57.3159 96.9311 56.8365 94.4972 56.8365C92.0634 56.8365 89.6533 57.3159 87.4048 58.2473C85.1562 59.1787 83.1131 60.5438 81.3921 62.2648C79.6711 63.9858 78.3059 66.0289 77.3745 68.2775C76.4431 70.5261 75.9637 72.9361 75.9637 75.3699L94.4972 75.3699H113.031Z"
        fill="url(#paint3_linear_1021_16495)"
      />
      <path
        d="M113.031 75.3699C113.031 72.9361 112.551 70.5261 111.62 68.2775C110.688 66.0289 109.323 63.9858 107.602 62.2648C105.881 60.5438 103.838 59.1787 101.59 58.2473C99.3411 57.3159 96.9311 56.8365 94.4972 56.8365C92.0634 56.8365 89.6533 57.3159 87.4048 58.2473C85.1562 59.1787 83.1131 60.5438 81.3921 62.2648C79.6711 63.9858 78.3059 66.0289 77.3745 68.2775C76.4431 70.5261 75.9637 72.9361 75.9637 75.3699L94.4972 75.3699H113.031Z"
        fill="black"
        fill-opacity="0.1"
      />
      <circle cx="94.4971" cy="50.6594" r="12.3556" fill="#619D94" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.473 59.1536C101.221 61.5318 98.0339 63.0152 94.5002 63.0152C90.9663 63.0152 87.779 61.5316 85.527 59.1534C86.135 58.8169 86.7627 58.5143 87.4073 58.2473C89.6559 57.3159 92.0659 56.8365 94.4998 56.8365C96.9336 56.8365 99.3436 57.3159 101.592 58.2473C102.237 58.5143 102.865 58.8171 103.473 59.1536Z"
        fill="url(#paint4_linear_1021_16495)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.473 59.1536C101.221 61.5318 98.0339 63.0152 94.5002 63.0152C90.9663 63.0152 87.779 61.5316 85.527 59.1534C86.135 58.8169 86.7627 58.5143 87.4073 58.2473C89.6559 57.3159 92.0659 56.8365 94.4998 56.8365C96.9336 56.8365 99.3436 57.3159 101.592 58.2473C102.237 58.5143 102.865 58.8171 103.473 59.1536Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6926 43.6749C34.7604 43.0334 34.2953 42.4584 33.6538 42.3906L23.684 41.3363C23.4167 41.308 23.177 41.5018 23.1488 41.7691L23.0976 42.2531C23.0976 42.2531 23.0976 42.2532 23.0975 42.2532C23.0975 42.2532 23.0975 42.2532 23.0975 42.2532L21.992 52.707C21.9242 53.3485 22.3892 53.9236 23.0307 53.9914L25.3538 54.2371C25.9953 54.3049 26.5703 53.8399 26.6382 53.1984L27.2319 47.5841C27.2997 46.9426 27.8747 46.4776 28.5162 46.5454L33.1625 47.0367C33.804 47.1046 34.3791 46.6395 34.4469 45.998L34.6926 43.6749Z"
        fill="url(#paint5_linear_1021_16495)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.356 75.23C31.2881 75.8715 30.7131 76.3365 30.0716 76.2687L20.102 75.2144C19.8345 75.1862 19.6407 74.9464 19.6689 74.679L19.7201 74.1949C19.7201 74.1948 19.7201 74.1947 19.7199 74.1947C19.7198 74.1947 19.7197 74.1946 19.7198 74.1945L20.8252 63.7408C20.893 63.0993 21.4681 62.6342 22.1096 62.7021L24.4327 62.9477C25.0742 63.0156 25.5392 63.5906 25.4714 64.2321L24.8776 69.8468C24.8098 70.4883 25.2748 71.0633 25.9163 71.1312L30.5629 71.6225C31.2044 71.6904 31.6695 72.2654 31.6016 72.9069L31.356 75.23Z"
        fill="url(#paint6_linear_1021_16495)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5338 44.8214C45.6017 44.1799 46.1767 43.7149 46.8182 43.7827L56.1106 44.7654C56.7521 44.8332 57.2171 45.4082 57.1493 46.0497L56.7808 49.5343C56.7808 49.5343 56.7807 49.5343 56.7807 49.5343C56.7806 49.5343 56.7806 49.5344 56.7806 49.5344L56.0641 56.3101C55.9962 56.9516 55.4212 57.4167 54.7797 57.3488L52.4566 57.1032C51.8151 57.0353 51.3501 56.4603 51.4179 55.8188L52.0116 50.2046C52.0794 49.5631 51.6144 48.988 50.9729 48.9202L46.3269 48.4289C45.6854 48.3611 45.2203 47.786 45.2882 47.1445L45.5338 44.8214Z"
        fill="url(#paint7_linear_1021_16495)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1966 76.3764C42.1288 77.0179 42.5938 77.593 43.2353 77.6608L52.5277 78.6435C53.1692 78.7113 53.7442 78.2462 53.812 77.6047L54.1805 74.1201C54.1805 74.1201 54.1805 74.1201 54.1805 74.1201C54.1805 74.1201 54.1805 74.1201 54.1805 74.1201L54.8969 67.3445C54.9648 66.703 54.4997 66.128 53.8582 66.0601L51.5352 65.8145C50.8936 65.7466 50.3186 66.2117 50.2508 66.8532L49.6571 72.4672C49.5893 73.1087 49.0142 73.5738 48.3727 73.506L43.7266 73.0146C43.0851 72.9468 42.5101 73.4119 42.4423 74.0534L42.1966 76.3764Z"
        fill="url(#paint8_linear_1021_16495)"
      />
      <defs>
        <filter
          id="filter0_f_1021_16495"
          x="32.9608"
          y="0.452637"
          width="127"
          height="137"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_1021_16495"
          />
        </filter>
        <filter
          id="filter1_bi_1021_16495"
          x="-4.96497"
          y="-44"
          width="153.33"
          height="216.94"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="22" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1021_16495"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1021_16495"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="13.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1021_16495"
          />
        </filter>
        <filter
          id="filter2_bi_1021_16495"
          x="40.6112"
          y="6.10308"
          width="109.18"
          height="110.556"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.17481" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1021_16495"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1021_16495"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4.5874" dy="4.5874" />
          <feGaussianBlur stdDeviation="4.5874" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1021_16495"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1021_16495"
          x1="120.771"
          y1="39.8683"
          x2="71.1993"
          y2="73.9753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1B5B1" />
          <stop offset="1" stopColor="#3E5854" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1021_16495"
          x1="67.6489"
          y1="107.451"
          x2="80.2063"
          y2="121.519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1021_16495"
          x1="58.9608"
          y1="24.4527"
          x2="115.615"
          y2="81.1071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stop-opacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1021_16495"
          x1="83.5794"
          y1="56.8365"
          x2="96.2559"
          y2="85.2407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1021_16495"
          x1="89.2141"
          y1="56.8365"
          x2="92.4025"
          y2="67.2119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1021_16495"
          x1="25.5864"
          y1="41.5375"
          x2="35.8791"
          y2="54.7114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1021_16495"
          x1="22.0039"
          y1="75.4156"
          x2="34.8249"
          y2="64.6858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1021_16495"
          x1="54.8856"
          y1="44.6358"
          x2="42.0652"
          y2="55.3652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1021_16495"
          x1="51.3027"
          y1="78.5139"
          x2="41.01"
          y2="65.3404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ChevronLeftIcon = () => {
  return (
    <svg
      width="9"
      height="17"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 1.14746L1 9L8.5 16.1475" stroke="#929292" />
    </svg>
  );
};

export const VideoIcon = () => {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83685 0.0216145C3.02417 0.105219 2.27129 0.486958 1.72357 1.09313C1.17586 1.69929 0.872149 2.48689 0.871094 3.30386V7.69614C0.871078 7.91959 0.893317 8.1425 0.93748 8.36154C1.09143 9.1074 1.4981 9.77726 2.0888 10.258C2.67949 10.7387 3.418 11.0008 4.17959 11H10.2624C10.7681 11.0004 11.2671 10.8845 11.7209 10.6612C12.1746 10.438 12.571 10.1133 12.8792 9.71242C12.89 9.70007 12.8993 9.68617 12.9101 9.67228C13.1878 9.3017 13.3841 8.87659 13.486 8.42484L13.4999 8.36154C13.544 8.1425 13.5663 7.91959 13.5662 7.69614V3.30386C13.5664 3.05135 13.5379 2.79963 13.4813 2.55354L13.4628 2.47789C13.3496 2.03867 13.1467 1.62762 12.8669 1.2706C12.8494 1.24795 12.8309 1.2248 12.8113 1.20112C12.5051 0.829175 12.1211 0.528868 11.6863 0.321347C11.2515 0.113827 10.7766 0.00413604 10.2948 0H4.14253C4.04032 0.00172027 3.93828 0.00893586 3.83685 0.0216145Z"
        fill="url(#paint0_linear_1101_1848)"
      />
      <path
        d="M3.83685 0.0216145C3.02417 0.105219 2.27129 0.486958 1.72357 1.09313C1.17586 1.69929 0.872149 2.48689 0.871094 3.30386V7.69614C0.871078 7.91959 0.893317 8.1425 0.93748 8.36154C1.09143 9.1074 1.4981 9.77726 2.0888 10.258C2.67949 10.7387 3.418 11.0008 4.17959 11H10.2624C10.7681 11.0004 11.2671 10.8845 11.7209 10.6612C12.1746 10.438 12.571 10.1133 12.8792 9.71242C12.89 9.70007 12.8993 9.68617 12.9101 9.67228C13.1878 9.3017 13.3841 8.87659 13.486 8.42484L13.4999 8.36154C13.544 8.1425 13.5663 7.91959 13.5662 7.69614V3.30386C13.5664 3.05135 13.5379 2.79963 13.4813 2.55354L13.4628 2.47789C13.3496 2.03867 13.1467 1.62762 12.8669 1.2706C12.8494 1.24795 12.8309 1.2248 12.8113 1.20112C12.5051 0.829175 12.1211 0.528868 11.6863 0.321347C11.2515 0.113827 10.7766 0.00413604 10.2948 0H4.14253C4.04032 0.00172027 3.93828 0.00893586 3.83685 0.0216145Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M17.8817 9.89871C17.8819 9.99144 17.8531 10.0819 17.7991 10.1574C17.7452 10.2328 17.669 10.2895 17.5812 10.3193C17.4934 10.3491 17.3984 10.3507 17.3097 10.3237C17.221 10.2967 17.1429 10.2426 17.0866 10.1689L14.5995 6.93604C14.5394 6.85879 14.5068 6.76372 14.5068 6.66586V4.30839C14.5068 4.21101 14.5394 4.11642 14.5995 4.03976L17.0866 0.785302C17.1429 0.711629 17.221 0.657486 17.3097 0.630501C17.3984 0.603516 17.4934 0.60505 17.5812 0.634888C17.669 0.664725 17.7452 0.721361 17.7991 0.796816C17.8531 0.87227 17.8819 0.96274 17.8817 1.05548V9.89871Z"
        fill="url(#paint1_linear_1101_1848)"
      />
      <path
        d="M17.8817 9.89871C17.8819 9.99144 17.8531 10.0819 17.7991 10.1574C17.7452 10.2328 17.669 10.2895 17.5812 10.3193C17.4934 10.3491 17.3984 10.3507 17.3097 10.3237C17.221 10.2967 17.1429 10.2426 17.0866 10.1689L14.5995 6.93604C14.5394 6.85879 14.5068 6.76372 14.5068 6.66586V4.30839C14.5068 4.21101 14.5394 4.11642 14.5995 4.03976L17.0866 0.785302C17.1429 0.711629 17.221 0.657486 17.3097 0.630501C17.3984 0.603516 17.4934 0.60505 17.5812 0.634888C17.669 0.664725 17.7452 0.721361 17.7991 0.796816C17.8531 0.87227 17.8819 0.96274 17.8817 1.05548V9.89871Z"
        fill="black"
        fill-opacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1101_1848"
          x1="3.47939"
          y1="3.07831e-08"
          x2="13.2628"
          y2="12.6499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1101_1848"
          x1="15.2002"
          y1="0.611328"
          x2="21.2378"
          y2="2.95713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CarIcon = () => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5565 3.25277C6.72078 3.83533 5.80382 4.51116 4.80488 4.71095L3.53171 4.96558C3.53053 4.96582 3.53081 4.96576 3.52963 4.966C3.4916 4.9736 0.00085773 5.67605 0 7.26801C-0.000317246 7.85847 0.125295 8.42101 0.283973 8.89588C0.582968 9.79067 1.4944 10.2551 2.4377 10.2402L5.28244 10.1952C5.45659 10.1924 5.58681 10.036 5.58681 9.8618C5.58681 8.68115 6.54392 7.72404 7.72457 7.72404C8.90523 7.72404 9.86234 8.68115 9.86234 9.8618C9.86234 10.0002 9.96991 10.121 10.1083 10.1188L14.0474 10.0565C14.7956 10.0446 15.378 9.38682 15.8489 8.80535C16.1757 8.40195 16.4824 7.95291 16.4824 7.43377V3.55109C16.4824 2.11309 15.4451 0.935301 14.0089 1.00595C13.6825 1.02201 13.3143 1.04354 12.8946 1.07048C11.4445 1.16356 9.0058 2.24252 7.5565 3.25277Z"
        fill="url(#paint0_linear_1101_2537)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5565 3.25277C6.72078 3.83533 5.80382 4.51116 4.80488 4.71095L3.53171 4.96558C3.53053 4.96582 3.53081 4.96576 3.52963 4.966C3.4916 4.9736 0.00085773 5.67605 0 7.26801C-0.000317246 7.85847 0.125295 8.42101 0.283973 8.89588C0.582968 9.79067 1.4944 10.2551 2.4377 10.2402L5.28244 10.1952C5.45659 10.1924 5.58681 10.036 5.58681 9.8618C5.58681 8.68115 6.54392 7.72404 7.72457 7.72404C8.90523 7.72404 9.86234 8.68115 9.86234 9.8618C9.86234 10.0002 9.96991 10.121 10.1083 10.1188L14.0474 10.0565C14.7956 10.0446 15.378 9.38682 15.8489 8.80535C16.1757 8.40195 16.4824 7.95291 16.4824 7.43377V3.55109C16.4824 2.11309 15.4451 0.935301 14.0089 1.00595C13.6825 1.02201 13.3143 1.04354 12.8946 1.07048C11.4445 1.16356 9.0058 2.24252 7.5565 3.25277Z"
        fill="black"
        fill-opacity="0.1"
      />
      <circle
        cx="7.72468"
        cy="9.86237"
        r="2.55229"
        stroke="url(#paint1_linear_1101_2537)"
        strokeWidth="0.829058"
      />
      <circle
        cx="7.72468"
        cy="9.86237"
        r="2.55229"
        stroke="black"
        stroke-opacity="0.1"
        strokeWidth="0.829058"
      />
      <path
        d="M14.128 0.645463C13.6799 0.60944 12.6457 0.546325 11.5301 0.618728C10.4357 0.689758 9.17542 0.895233 8.33723 1.45405C7.10904 2.27288 6.08021 2.9782 5.23805 3.5692C4.57365 4.03545 4.33109 4.74777 4.55772 5.37384C4.78281 5.9957 5.42195 6.39674 6.22767 6.35425C7.86943 6.26766 9.81033 6.15396 11.4963 6.02437C13.1658 5.89604 14.6287 5.74938 15.2849 5.5904C15.874 5.44766 16.282 5.11748 16.4857 4.65307C16.6808 4.2084 16.6624 3.70026 16.5384 3.22936C16.2925 2.2955 15.5813 1.31734 14.7378 0.827381C14.5384 0.711556 14.3262 0.661398 14.128 0.645463Z"
        fill="#F3FFF6"
      />
      <path
        d="M14.128 0.645463C13.6799 0.60944 12.6457 0.546325 11.5301 0.618728C10.4357 0.689758 9.17542 0.895233 8.33723 1.45405C7.10904 2.27288 6.08021 2.9782 5.23805 3.5692C4.57365 4.03545 4.33109 4.74777 4.55772 5.37384C4.78281 5.9957 5.42195 6.39674 6.22767 6.35425C7.86943 6.26766 9.81033 6.15396 11.4963 6.02437C13.1658 5.89604 14.6287 5.74938 15.2849 5.5904C15.874 5.44766 16.282 5.11748 16.4857 4.65307C16.6808 4.2084 16.6624 3.70026 16.5384 3.22936C16.2925 2.2955 15.5813 1.31734 14.7378 0.827381C14.5384 0.711556 14.3262 0.661398 14.128 0.645463Z"
        stroke="url(#paint2_linear_1101_2537)"
        strokeWidth="0.829058"
      />
      <path
        d="M14.128 0.645463C13.6799 0.60944 12.6457 0.546325 11.5301 0.618728C10.4357 0.689758 9.17542 0.895233 8.33723 1.45405C7.10904 2.27288 6.08021 2.9782 5.23805 3.5692C4.57365 4.03545 4.33109 4.74777 4.55772 5.37384C4.78281 5.9957 5.42195 6.39674 6.22767 6.35425C7.86943 6.26766 9.81033 6.15396 11.4963 6.02437C13.1658 5.89604 14.6287 5.74938 15.2849 5.5904C15.874 5.44766 16.282 5.11748 16.4857 4.65307C16.6808 4.2084 16.6624 3.70026 16.5384 3.22936C16.2925 2.2955 15.5813 1.31734 14.7378 0.827381C14.5384 0.711556 14.3262 0.661398 14.128 0.645463Z"
        stroke="black"
        stroke-opacity="0.1"
        strokeWidth="0.829058"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1101_2537"
          x1="3.38641"
          y1="1.00293"
          x2="10.1794"
          y2="14.5821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1101_2537"
          x1="6.46535"
          y1="7.72461"
          x2="10.369"
          y2="12.0981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1101_2537"
          x1="5.3364"
          y1="1"
          x2="8.15818"
          y2="9.46205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stop-opacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CameraIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 7.46957 1.71071 6.96086 2.08579 6.58579C2.46086 6.21071 2.96957 6 3.5 6H4.43C4.75918 6.00005 5.08329 5.91884 5.37357 5.76359C5.66384 5.60834 5.9113 5.38383 6.094 5.11L6.906 3.89C7.0887 3.61617 7.33616 3.39166 7.62643 3.23641C7.91671 3.08116 8.24082 2.99995 8.57 3H12.43C12.7592 2.99995 13.0833 3.08116 13.3736 3.23641C13.6638 3.39166 13.9113 3.61617 14.094 3.89L14.906 5.11C15.0887 5.38383 15.3362 5.60834 15.6264 5.76359C15.9167 5.91884 16.2408 6.00005 16.57 6H17.5C18.0304 6 18.5391 6.21071 18.9142 6.58579C19.2893 6.96086 19.5 7.46957 19.5 8V15C19.5 15.5304 19.2893 16.0391 18.9142 16.4142C18.5391 16.7893 18.0304 17 17.5 17H3.5C2.96957 17 2.46086 16.7893 2.08579 16.4142C1.71071 16.0391 1.5 15.5304 1.5 15V8ZM15 11C15 11.5909 14.8836 12.1761 14.6575 12.7221C14.4313 13.268 14.0998 13.7641 13.682 14.182C13.2641 14.5998 12.768 14.9313 12.2221 15.1575C11.6761 15.3836 11.0909 15.5 10.5 15.5C9.90905 15.5 9.32389 15.3836 8.77792 15.1575C8.23196 14.9313 7.73588 14.5998 7.31802 14.182C6.90016 13.7641 6.56869 13.268 6.34254 12.7221C6.1164 12.1761 6 11.5909 6 11C6 9.80653 6.47411 8.66193 7.31802 7.81802C8.16193 6.97411 9.30653 6.5 10.5 6.5C11.6935 6.5 12.8381 6.97411 13.682 7.81802C14.5259 8.66193 15 9.80653 15 11ZM10.5 14C10.894 14 11.2841 13.9224 11.6481 13.7716C12.012 13.6209 12.3427 13.3999 12.6213 13.1213C12.8999 12.8427 13.1209 12.512 13.2716 12.1481C13.4224 11.7841 13.5 11.394 13.5 11C13.5 10.606 13.4224 10.2159 13.2716 9.85195C13.1209 9.48797 12.8999 9.15726 12.6213 8.87868C12.3427 8.6001 12.012 8.37913 11.6481 8.22836C11.2841 8.0776 10.894 8 10.5 8C9.70435 8 8.94129 8.31607 8.37868 8.87868C7.81607 9.44129 7.5 10.2044 7.5 11C7.5 11.7956 7.81607 12.5587 8.37868 13.1213C8.94129 13.6839 9.70435 14 10.5 14Z"
        fill="black"
      />
    </svg>
  );
};
