import { useQuery } from "@apollo/client";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import inviteDefaultImage from "src/assets/images/generalDefaultImage.png";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import { InvitationCardTemplate } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import ShareInviteCard from "../invitation-card/ShareInviteCard";
import GiftShareInviteCard from "../invitation-card/ShareInviteCard/GiftShareInviteCard";
import AddGuestsSpreadsheet from "./AddGuestsSpreadsheet";

function AddGuest() {
  const [showSpreadsheet, setShowSpreadsheet] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState(false);
  const [openShareWebsite, setOpenShareWebsite] = useState(false);
  const [weddingWebsiteLinkInfo, setWeddingWebsiteLinkInfo] = useState(false);
  const [isPhotobookShareOpen, setIsPhotobookShareOpen] = useState(false);
  const [isGiftsShareOpen, setIsGiftsShareOpen] = useState(false);

  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: invitationCard } = useQuery(InvitationCardTemplate, {
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <>
      <div>
        {showSpreadsheet ? (
          <AddGuestsSpreadsheet setOpen={setShowSpreadsheet} />
        ) : (
          <div className="relative flex h-full w-full flex-col gap-[3.8rem] px-[1.2rem] py-[2.9rem] md:px-0">
            <div className="flex flex-col gap-[1.5rem]">
              <h4 className="text-[16.37px] font-[600] leading-[24px] -tracking-[0.02em] text-primary-1">
                Invite your guests
              </h4>
              <div className="grid w-full grid-cols-1 gap-[2.5rem] lg:grid-cols-2 xl:w-max">
                <div
                  onClick={() => setShowSpreadsheet(true)}
                  className="flex h-max w-full cursor-pointer rounded-[8px] border border-secondary-2 transition-colors duration-300 ease-in-out hover:bg-primary-2/20 sm:h-[7.7rem] lg:max-w-[411px]"
                >
                  <div className="grid min-w-[7.7rem] place-items-center border-r border-r-secondary-2">
                    <Icon iconName="inviteAddManually" />
                  </div>
                  <div className="flex flex-grow flex-col justify-center px-4 py-4">
                    <h5 className="font-[600] -tracking-[0.02em] text-dark-1">
                      Add Guests Yourself
                    </h5>
                    <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                      Quickly add your guests by entering their details in a few
                      simple steps.
                    </p>
                  </div>
                </div>

                <div className="flex h-max w-full cursor-pointer rounded-[8px] border border-secondary-2 transition-colors duration-300 ease-in-out hover:bg-primary-2/20 sm:h-[7.7rem] lg:max-w-[411px]">
                  <DialogModal
                    trigger={
                      <div className="flex h-full w-full">
                        <div className="grid min-w-[7.7rem] place-items-center border-r border-r-secondary-2">
                          <Icon iconName="inviteGeneralInvite" />
                        </div>
                        <div className="flex flex-grow flex-col justify-center px-4 py-4 text-left">
                          <h5 className="font-[600] -tracking-[0.02em] text-dark-1">
                            General Invite Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            Share a general link to your guest so they would be
                            able to add themselves to your list
                          </p>
                        </div>
                      </div>
                    }
                    open={openShare}
                    onOpenChange={setOpenShare}
                  >
                    <ShareInviteCard
                      setToggleModal={setOpenShare}
                      image={
                        invitationCard?.invitationCard?.template?.length
                          ? invitationCard?.invitationCard?.template
                          : inviteDefaultImage
                      }
                    />
                  </DialogModal>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[1.5rem]">
              <h4 className="text-[16.37px] font-[600] leading-[24px] -tracking-[0.02em] text-primary-1">
                Invite others
              </h4>
              <div className="grid w-full grid-cols-1 gap-[2.5rem] lg:grid-cols-2 xl:w-max">
                <div className="relative flex h-max w-full cursor-pointer rounded-[8px] border border-secondary-2 transition-colors duration-300 ease-in-out hover:bg-primary-2/20 sm:h-[7.7rem] lg:max-w-[411px]">
                  <DialogModal
                    trigger={
                      <div className="flex h-full w-full">
                        <Tooltip.Provider delayDuration={200}>
                          <Tooltip.Root open={weddingWebsiteLinkInfo}>
                            <Tooltip.Trigger
                              onMouseEnter={() => {
                                setWeddingWebsiteLinkInfo(true);
                              }}
                              onMouseLeave={() => {
                                setWeddingWebsiteLinkInfo(false);
                              }}
                              onFocus={() => {
                                setWeddingWebsiteLinkInfo(true);
                              }}
                              onBlur={() => {
                                setWeddingWebsiteLinkInfo(false);
                              }}
                              asChild
                            >
                              <div
                                onClick={(e) => e?.stopPropagation()}
                                className="absolute right-4 top-2 grid h-[17px] w-[17px] place-items-center rounded-[50px] bg-primary-3 text-[10.57px] font-[800] leading-[8px]"
                              >
                                i
                              </div>
                            </Tooltip.Trigger>
                            <Tooltip.Content
                              side="right"
                              className="flex flex-col gap-[1.625rem] rounded-[6px] border border-dashed border-primary-5 bg-primary-4 px-[1.375rem] py-[1.125rem]"
                            >
                              <div className="flex flex-col gap-1">
                                <h6 className="text-[13px] font-[600] leading-[19px] -tracking-[0.02em] text-dark-1">
                                  Restrictions
                                </h6>
                                {[
                                  "Cannot leave messages on your website",
                                  "Cannot see venue",
                                  "Cannot rsvp",
                                ]?.map((i, idx) => (
                                  <p
                                    key={idx}
                                    className="text-[14px] leading-[21px] -tracking-[0.02em] text-secondary-3"
                                  >
                                    {i}
                                  </p>
                                ))}
                              </div>
                              <div className="flex flex-col gap-1">
                                <h6 className="text-[13px] font-[600] leading-[19px] -tracking-[0.02em] text-dark-1">
                                  Everything else on the website is accessible
                                </h6>
                                {[
                                  "Wishlist",
                                  "Cash gift",
                                  "Photobook",
                                  "Vendors list",
                                  "Others",
                                ]?.map((i, idx) => (
                                  <p
                                    key={idx}
                                    className="text-[14px] leading-[21px] -tracking-[0.02em] text-secondary-3"
                                  >
                                    {i}
                                  </p>
                                ))}
                              </div>
                              <Tooltip.Arrow className={`fill-primary-5`} />
                            </Tooltip.Content>
                          </Tooltip.Root>
                        </Tooltip.Provider>
                        <div className="grid min-w-[7.7rem] place-items-center border-r border-r-secondary-2">
                          <Icon iconName="inviteWebsiteLink" />
                        </div>
                        <div className="flex flex-grow flex-col justify-center px-4 py-4 text-left">
                          <h5 className="font-[600] -tracking-[0.02em] text-dark-1">
                            Wedding Website Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            A link for non-guests to engage with your website.
                            It comes with some restrictions
                          </p>
                        </div>
                      </div>
                    }
                    open={openShareWebsite}
                    onOpenChange={setOpenShareWebsite}
                  >
                    <ShareInviteCard
                      customLink={`https://${event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}`}
                      setToggleModal={setOpenShareWebsite}
                      customTitle={`Wedding Website`}
                      preview={true}
                      customDescription={`Share your wedding website with everyone`}
                    />
                  </DialogModal>
                </div>
                <div className="flex h-max w-full cursor-not-allowed rounded-[8px] border border-secondary-2 transition-colors duration-300 ease-in-out hover:bg-primary-2/20 sm:h-[7.7rem] lg:max-w-[411px]">
                  <DialogModal
                    trigger={
                      <div className="flex h-full w-full">
                        <div className="grid min-w-[7.7rem] place-items-center border-r border-r-secondary-2">
                          <Icon iconName="inviteGiftsLink" />
                        </div>
                        <div className="flex flex-grow flex-col justify-center px-4 py-4 text-left">
                          <h5 className="font-[600] -tracking-[0.02em] text-dark-1">
                            Gifts Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            Share a link to your wedding wishlist and cash gifts
                          </p>
                        </div>
                      </div>
                    }
                    open={isGiftsShareOpen}
                    onOpenChange={setIsGiftsShareOpen}
                  >
                    <GiftShareInviteCard
                      customLink={`https://${event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}/gifts`}
                      setToggleModal={setIsGiftsShareOpen}
                      customTitle={`Gifts Link`}
                      customDescription={` Share a link to your wedding wishlist and cash gifts`}
                      preview={true}
                    />
                  </DialogModal>
                </div>
                <div className="flex h-max w-full cursor-not-allowed rounded-[8px] border border-secondary-2 transition-colors duration-300 ease-in-out hover:bg-primary-2/20 sm:h-[7.7rem] lg:max-w-[411px]">
                  <DialogModal
                    trigger={
                      <div className="flex h-full w-full">
                        <div className="grid min-w-[7.7rem] place-items-center border-r border-r-secondary-2">
                          <Icon iconName="photobookLink" />
                        </div>
                        <div className="flex flex-grow flex-col justify-center px-4 py-4 text-left">
                          <h5 className="font-[600] -tracking-[0.02em] text-dark-1">
                            Photobook Link
                          </h5>
                          <p className="text-[14px] -tracking-[0.02em] text-secondary-3">
                            Share link to your beautiful photobook
                          </p>
                        </div>
                      </div>
                    }
                    open={isPhotobookShareOpen}
                    onOpenChange={setIsPhotobookShareOpen}
                  >
                    <ShareInviteCard
                      customLink={`https://${event?.slug}.${process.env.REACT_APP_INAWO_ALBUM_SITE_END}`}
                      setToggleModal={setIsPhotobookShareOpen}
                      customTitle={`Photobook`}
                      customDescription={`Share a link to your wedding photos and memories`}
                      preview={true}
                    />
                  </DialogModal>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddGuest;
