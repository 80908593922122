import * as Dialog from "@radix-ui/react-dialog";
import { Dispatch, ReactNode } from "react";
import Icon from "../Icon";
import styles from "./dialog.module.scss";

interface DiaglogProps {
  trigger?: ReactNode;
  title?: string | ReactNode;
  description?: string | ReactNode;
  children: ReactNode;
  open?: boolean;
  onOpenChange?: Dispatch<React.SetStateAction<boolean>>;
  icon?: ReactNode;
  onCollapse?: () => void;
  disabled?: boolean;
  className?: string;
}

const DialogModal = ({
  trigger,
  title,
  description,
  children,
  icon,
  onCollapse,
  onOpenChange,
  className,
  disabled,
  ...props
}: DiaglogProps) => (
  <Dialog.Root {...props}>
    <Dialog.Trigger
      className={`cursor-pointer ${styles["dialog__trigger"]} ${className}`}
      onClick={() => onOpenChange?.(true)}
      disabled={disabled}
    >
      {trigger}
    </Dialog.Trigger>

    <Dialog.Portal>
      <Dialog.Overlay className={styles["dialog__overlay"]}>
        <Dialog.Content
          className={styles["dialog__content"]}
          // className={"max-h-[100vh] bg-[white] overflow-y-auto my-4 mx-4 p-4"}
        >
          {onCollapse && icon ? (
            <Dialog.Close
              className={styles["dialog__close"]}
              onClick={(e) => {
                e.preventDefault();
                onCollapse?.();
              }}
            >
              {icon}
            </Dialog.Close>
          ) : (
            <Dialog.Close
              className={styles["dialog__close"]}
              onClick={() => onOpenChange?.(false)}
            >
              <Icon iconName={"close2"} />
            </Dialog.Close>
          )}

          {title && <Dialog.Title>{title}</Dialog.Title>}
          {description && <Dialog.Description></Dialog.Description>}
          {children}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  </Dialog.Root>
);

export default DialogModal;
