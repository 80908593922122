import { useMutation, useQuery } from "@apollo/client";
import { Form, Formik, FormikProps } from "formik";
import { Dispatch, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import { EditEvent } from "src/graphql/mutations";
import { GetEvents } from "src/graphql/queries";
import { CheckEventSlug } from "src/graphql/queries/event.queries";
import useAppStore from "src/store/utils";
import {
  GetEventInfo,
  checkEventSlug,
  checkEventSlugVariables,
  editEvent,
} from "src/types/api";
import * as Yup from "yup";
import editDetails from "./editDetails.module.scss";

interface Values {
  eventname: string | any;
  hashtag: string | any;
  message: string | any;
  slug: string | any;
}

const GeneralFormSchema = Yup.object().shape({
  eventname: Yup.string().required("Event Name is required"),
  hashtag: Yup.string()
    .matches(/#\S+/, "Must include a hashtag e.g #MikeLucy")
    .required("A hashtag is required"),
  message: Yup.string().required("Please enter a welcome message").max(64),
  slug: Yup.string()
    .min(2, "Too Short!")
    .matches(/^(?!www).*$/, "Can't use 'www'")
    .matches(/^[^&\v]*$/, "Your slug cannot include '&'")
    .required("A slug is required for your custom website")
    .lowercase(),
});

interface EditDetailsProps {
  details: GetEventInfo | undefined;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

const EditDetails = ({ details, setOpen }: EditDetailsProps) => {
  const [slugDisplay, setSlugDisplay] = useState(details?.event?.slug ?? "");
  const navigate = useNavigate();

  const [UpdateEventSlugFn, { loading }] = useMutation<editEvent>(EditEvent, {
    onCompleted() {
      setOpen(false);
      toast.success(<p>Event details successfully updated.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const {
    data: checkEventSlugData,
    loading: checkEventSlugLoading,
    refetch: checkEventSlugRefetch,
  } = useQuery<checkEventSlug, checkEventSlugVariables>(CheckEventSlug, {
    skip: slugDisplay === details?.event?.slug,
    variables: {
      slug: slugDisplay,
    },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p className="toast">{error.message ?? "No Tags Set"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const { event } = useAppStore((state) => state);

  const handleOnChange = (event: any) => {
    if (event.target.name === "slug") {
      setSlugDisplay(event.target.value);

      if (event.target.value.length > 2) {
        checkEventSlugRefetch({
          slug: event.target.value.replace(/\s/g, "").toLowerCase(),
        });
      }
    }
  };

  return (
    <div className={`${editDetails["editDetailsModal--content"]}`}>
      <h1>Edit Information</h1>

      <Formik
        enableReinitialize
        initialValues={{
          eventname: details?.event?.name || "",
          hashtag: details?.event?.hash_tag || "",
          message: details?.event?.welcome_msg || "",
          slug: details?.event?.slug || "",
        }}
        validationSchema={GeneralFormSchema}
        onSubmit={(values, actions) => {
          UpdateEventSlugFn({
            variables: {
              eventId: event.id,
              name: values.eventname,
              hashTag: values.hashtag,
              welcomeMsg: values.message,
              updateEventEventId2: event.id,
              slug: values.slug.replace(/\s/g, "").toLowerCase(),
            },
            refetchQueries: [
              {
                query: GetEvents,
              },
            ],
          });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form
            onChange={handleOnChange}
            className={editDetails["editDetailsModal--content-form"]}
          >
            <div
              className={
                editDetails["editDetailsModal--content-form_container"]
              }
            >
              <Input
                type="text"
                name="eventname"
                label="Wedding Name"
                placeholder="David and Kemi’s Wedding"
              />
              <Textarea
                rows={3}
                name="message"
                label="Welcome Message (max 64 characters)"
                placeholder="Enter Wedding Message"
              />
              <Input
                type="text"
                name="hashtag"
                label="Hashtag"
                placeholder="#DavidKemi2022"
              />
              <Input
                type="text"
                name="slug"
                label="Slug"
                placeholder="mikewedslucy"
              />
            </div>

            {checkEventSlugLoading ? (
              <p
                className={
                  editDetails["editDetailsModal--content-form_container__desc"]
                }
              >
                Checking...
              </p>
            ) : checkEventSlugData?.checkEventSlug &&
              details?.event?.slug !== props.values.slug ? (
              <p
                className={
                  editDetails["editDetailsModal--content-form_container__desc"]
                }
              >
                This slug is already in use. Please enter a different slug.
              </p>
            ) : slugDisplay.includes("&") ? (
              <></>
            ) : (
              <p
                className={
                  editDetails["editDetailsModal--content-form_container__desc"]
                }
              >
                Your wedding website will appear as "https://
                <span>{slugDisplay}</span>
                .inawo.wedding"
              </p>
            )}

            <div
              className={editDetails["editDetailsModal--content-btnContainer"]}
            >
              <div>
                <Button
                  type="submit"
                  label="Save Changes"
                  loader={loading}
                  disabled={
                    props.values.eventname === "" ||
                    props.values.hashtag === "" ||
                    props.values.message === "" ||
                    props.values.slug === "" ||
                    checkEventSlugLoading ||
                    (checkEventSlugData?.checkEventSlug &&
                      details?.event?.slug !== props.values.slug)
                  }
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditDetails;
