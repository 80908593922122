import PlayIcon from "src/assets/icons/play";
import Media from "src/components/Media";
import { GetAlbums } from "src/types/api";
import { SelectedAlbumType } from "src/utils/types";

const Album = ({
  element,
  setSelectedAlbum,
}: {
  element: GetAlbums["getAlbums"]["data"][0];
  setSelectedAlbum: (album: SelectedAlbumType) => void;
}) => {
  return (
    <div className="relative !h-[260px] w-full sm:h-[260px] sm:w-[260px]">
      {element?.Media?.data?.[0]?.type?.includes("video/") ? (
        <div className="relative h-full w-full sm:h-[260px] sm:w-[260px]">
          <video
            className="h-full w-full rounded-[5.48px] object-cover"
            controls={false}
          >
            <source
              src={element?.Media?.data?.[0]?.url ?? ""}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="bg-black/30 absolute inset-0 flex items-center justify-center rounded-[5.48px]">
            <PlayIcon />
          </div>
        </div>
      ) : (
        <img
          src={element?.Media?.data?.[0]?.url ?? ""}
          alt={`mage`}
          className="h-[184px] rounded-[5.48px] object-cover sm:w-[260px]"
        />
      )}
      <div className="absolute top-0 z-[50] flex w-fit items-center justify-between pl-[12.58px] pr-[15.14px] pt-[14.6px]">
        <p className="hidden text-sm font-semibold leading-[17.2px] text-white [text-shadow:_0_1px_0_rgb(0_0_0)] sm:block">
          {element?.name}
        </p>
      </div>
      <div className="absolute inset-0 top-0 flex flex-col items-center justify-center rounded-[5.48px] bg-[#000]/50 opacity-0 transition-opacity duration-300 hover:opacity-100">
        <div
          className="w-fit cursor-pointer rounded-[9.52px] border border-white px-4 py-2 text-[14.58px] !font-medium leading-[17.65px] text-white sm:px-[23.49px] sm:py-[12.92px]"
          onClick={() => setSelectedAlbum(element)}
        >
          Open Album
        </div>
      </div>
      <div className="mt-[4px] grid h-full grid-cols-3 gap-[4px] sm:w-[260px]">
        {element?.Media?.data
          ?.slice(1, 3)
          ?.map((item) => (
            <Media
              media={{ type: item?.type ?? "", url: item?.url ?? "" }}
              className="!h-[72px] w-full rounded-[5.48px] sm:h-full"
              imageClassName="object-cover h-full"
            />
          ))}

        {element?.Media?.data?.length > 2 && (
          <div className="relative h-full w-full sm:h-[72px]">
            <Media
              media={{
                type: element?.Media?.data?.[3]?.type ?? "",
                url: element?.Media?.data?.[3]?.url ?? "",
              }}
              className="!h-[72px] w-full rounded-[5.48px] sm:h-full"
              imageClassName="object-cover h-full"
            />

            {element?.Media?.data?.length > 4 && (
              <p className="absolute top-0 flex h-full w-full items-center justify-center rounded-[5.48px] bg-[#000]/60 text-white">
                {element?.Media?.data?.length - 4}+
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Album;
