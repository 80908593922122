export const AddEmptyIcon = () => (
  <svg
    width={357}
    height={136}
    viewBox="0 0 357 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-[136px] w-[300px] sm:w-[356px]"
  >
    <path
      d="M132.859 16.9058L125.586 18.8641C107.338 23.7839 102.025 33.0591 106.944 51.2629L111.862 69.4666C116.082 85.189 123.584 91.3529 137.235 89.5757C139.424 89.3264 141.781 88.8517 144.306 88.1516L151.622 86.1479C169.78 81.1871 175.139 71.9543 170.22 53.7505L165.3 35.5029C164.297 31.8013 163.134 28.5884 161.728 25.955C156.235 15.5049 147.404 12.9453 132.859 16.9058Z"
      fill="url(#paint0_linear_629_2111)"
    />
    <path
      d="M132.859 16.9058L125.586 18.8641C107.338 23.7839 102.025 33.0591 106.944 51.2629L111.862 69.4666C116.082 85.189 123.584 91.3529 137.235 89.5757C139.424 89.3264 141.781 88.8517 144.306 88.1516L151.622 86.1479C169.78 81.1871 175.139 71.9543 170.22 53.7505L165.3 35.5029C164.297 31.8013 163.134 28.5884 161.728 25.955C156.235 15.5049 147.404 12.9453 132.859 16.9058Z"
      fill="black"
      fillOpacity={0.1}
    />
    <path
      d="M233.013 16.9058L240.286 18.8641C258.533 23.7839 263.846 33.0591 258.928 51.2629L254.01 69.4666C249.79 85.189 242.288 91.3529 228.637 89.5757C226.448 89.3264 224.091 88.8517 221.565 88.1516L214.25 86.1479C196.092 81.1871 190.733 71.9543 195.651 53.7505L200.571 35.5029C201.575 31.8013 202.737 28.5884 204.144 25.955C209.637 15.5049 218.468 12.9453 233.013 16.9058Z"
      fill="url(#paint1_linear_629_2111)"
    />
    <path
      d="M233.013 16.9058L240.286 18.8641C258.533 23.7839 263.846 33.0591 258.928 51.2629L254.01 69.4666C249.79 85.189 242.288 91.3529 228.637 89.5757C226.448 89.3264 224.091 88.8517 221.565 88.1516L214.25 86.1479C196.092 81.1871 190.733 71.9543 195.651 53.7505L200.571 35.5029C201.575 31.8013 202.737 28.5884 204.144 25.955C209.637 15.5049 218.468 12.9453 233.013 16.9058Z"
      fill="black"
      fillOpacity={0.1}
    />
    <path
      d="M310.64 47.9565L317.913 49.9148C336.161 54.8347 341.474 64.1099 336.555 82.3137L331.637 100.517C327.417 116.24 319.915 122.404 306.264 120.627C304.075 120.377 301.718 119.902 299.193 119.202L291.877 117.199C273.719 112.238 268.36 103.005 273.279 84.8013L278.199 66.5537C279.202 62.8521 280.365 59.6392 281.771 57.0058C287.264 46.5557 296.095 43.9961 310.64 47.9565Z"
      fill="url(#paint2_linear_629_2111)"
    />
    <path
      d="M310.64 47.9565L317.913 49.9148C336.161 54.8347 341.474 64.1099 336.555 82.3137L331.637 100.517C327.417 116.24 319.915 122.404 306.264 120.627C304.075 120.377 301.718 119.902 299.193 119.202L291.877 117.199C273.719 112.238 268.36 103.005 273.279 84.8013L278.199 66.5537C279.202 62.8521 280.365 59.6392 281.771 57.0058C287.264 46.5557 296.095 43.9961 310.64 47.9565Z"
      fill="black"
      fillOpacity={0.1}
    />
    <path
      d="M46.3601 32.4312L39.087 34.3894C20.8394 39.3093 15.5261 48.5845 20.4446 66.7883L25.363 84.992C29.5827 100.714 37.0848 106.878 50.7359 105.101C52.9248 104.852 55.2819 104.377 57.8072 103.677L65.1226 101.673C83.281 96.7125 88.6396 87.4797 83.7212 69.2759L78.8013 51.0283C77.798 47.3267 76.6354 44.1138 75.2285 41.4804C69.7357 31.0303 60.9046 28.4707 46.3601 32.4312Z"
      fill="url(#paint3_linear_629_2111)"
    />
    <path
      d="M46.3601 32.4312L39.087 34.3894C20.8394 39.3093 15.5261 48.5845 20.4446 66.7883L25.363 84.992C29.5827 100.714 37.0848 106.878 50.7359 105.101C52.9248 104.852 55.2819 104.377 57.8072 103.677L65.1226 101.673C83.281 96.7125 88.6396 87.4797 83.7212 69.2759L78.8013 51.0283C77.798 47.3267 76.6354 44.1138 75.2285 41.4804C69.7357 31.0303 60.9046 28.4707 46.3601 32.4312Z"
      fill="black"
      fillOpacity={0.1}
    />
    <defs>
      <linearGradient
        id="paint0_linear_629_2111"
        x1={128.887}
        y1={9.96065}
        x2={125.322}
        y2={111.476}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_629_2111"
        x1={236.985}
        y1={9.96065}
        x2={240.55}
        y2={111.476}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_629_2111"
        x1={314.612}
        y1={41.0114}
        x2={318.177}
        y2={142.526}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_629_2111"
        x1={42.3878}
        y1={25.486}
        x2={38.8226}
        y2={127.001}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
    </defs>
  </svg>
);

const AddFileIcon = () => (
  <svg
    viewBox="0 0 161 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-[38px] w-[99px] md:h-[73px] md:w-[161px]"
  >
    <path
      d="M59.9072 7.89967L56.628 8.78261C48.4006 11.0008 46.005 15.1828 48.2226 23.3903L50.4401 31.5979C52.3427 38.6867 55.7252 41.4658 61.8801 40.6645C62.867 40.5521 63.9297 40.3381 65.0683 40.0224L68.3667 39.119C76.5538 36.8823 78.9698 32.7195 76.7522 24.512L74.534 16.2846C74.0816 14.6156 73.5574 13.1671 72.9231 11.9797C70.4466 7.26808 66.4649 6.11401 59.9072 7.89967Z"
      fill="url(#paint0_linear_611_2970)"
    />
    <path
      d="M59.9072 7.89967L56.628 8.78261C48.4006 11.0008 46.005 15.1828 48.2226 23.3903L50.4401 31.5979C52.3427 38.6867 55.7252 41.4658 61.8801 40.6645C62.867 40.5521 63.9297 40.3381 65.0683 40.0224L68.3667 39.119C76.5538 36.8823 78.9698 32.7195 76.7522 24.512L74.534 16.2846C74.0816 14.6156 73.5574 13.1671 72.9231 11.9797C70.4466 7.26808 66.4649 6.11401 59.9072 7.89967Z"
      fill="black"
      fillOpacity={0.1}
    />
    <path
      d="M105.064 7.89967L108.343 8.78261C116.57 11.0008 118.966 15.1828 116.748 23.3903L114.531 31.5979C112.628 38.6867 109.246 41.4658 103.091 40.6645C102.104 40.5521 101.041 40.3381 99.9024 40.0224L96.604 39.119C88.4169 36.8823 86.0009 32.7195 88.2185 24.512L90.4367 16.2846C90.8891 14.6156 91.4133 13.1671 92.0476 11.9797C94.5241 7.26808 98.5058 6.11401 105.064 7.89967Z"
      fill="url(#paint1_linear_611_2970)"
    />
    <path
      d="M105.064 7.89967L108.343 8.78261C116.57 11.0008 118.966 15.1828 116.748 23.3903L114.531 31.5979C112.628 38.6867 109.246 41.4658 103.091 40.6645C102.104 40.5521 101.041 40.3381 99.9024 40.0224L96.604 39.119C88.4169 36.8823 86.0009 32.7195 88.2185 24.512L90.4367 16.2846C90.8891 14.6156 91.4133 13.1671 92.0476 11.9797C94.5241 7.26808 98.5058 6.11401 105.064 7.89967Z"
      fill="black"
      fillOpacity={0.1}
    />
    <path
      d="M140.064 21.8997L143.343 22.7826C151.57 25.0008 153.966 29.1828 151.748 37.3903L149.531 45.5979C147.628 52.6867 144.246 55.4658 138.091 54.6645C137.104 54.5521 136.041 54.3381 134.902 54.0224L131.604 53.119C123.417 50.8823 121.001 46.7195 123.218 38.512L125.437 30.2846C125.889 28.6156 126.413 27.1671 127.048 25.9797C129.524 21.2681 133.506 20.114 140.064 21.8997Z"
      fill="url(#paint2_linear_611_2970)"
    />
    <path
      d="M140.064 21.8997L143.343 22.7826C151.57 25.0008 153.966 29.1828 151.748 37.3903L149.531 45.5979C147.628 52.6867 144.246 55.4658 138.091 54.6645C137.104 54.5521 136.041 54.3381 134.902 54.0224L131.604 53.119C123.417 50.8823 121.001 46.7195 123.218 38.512L125.437 30.2846C125.889 28.6156 126.413 27.1671 127.048 25.9797C129.524 21.2681 133.506 20.114 140.064 21.8997Z"
      fill="black"
      fillOpacity={0.1}
    />
    <path
      d="M20.9072 14.8997L17.628 15.7826C9.4006 18.0008 7.00501 22.1828 9.22258 30.3903L11.4401 38.5979C13.3427 45.6867 16.7252 48.4658 22.8801 47.6645C23.867 47.5521 24.9297 47.3381 26.0683 47.0224L29.3667 46.119C37.5538 43.8823 39.9698 39.7195 37.7522 31.512L35.534 23.2846C35.0816 21.6156 34.5574 20.1671 33.9231 18.9797C31.4466 14.2681 27.4649 13.114 20.9072 14.8997Z"
      fill="url(#paint3_linear_611_2970)"
    />
    <path
      d="M20.9072 14.8997L17.628 15.7826C9.4006 18.0008 7.00501 22.1828 9.22258 30.3903L11.4401 38.5979C13.3427 45.6867 16.7252 48.4658 22.8801 47.6645C23.867 47.5521 24.9297 47.3381 26.0683 47.0224L29.3667 46.119C37.5538 43.8823 39.9698 39.7195 37.7522 31.512L35.534 23.2846C35.0816 21.6156 34.5574 20.1671 33.9231 18.9797C31.4466 14.2681 27.4649 13.114 20.9072 14.8997Z"
      fill="black"
      fillOpacity={0.1}
    />
    <rect
      x={69.2256}
      y={48.6187}
      width={23.756}
      height={23.756}
      rx={5.95045}
      fill="url(#paint4_linear_611_2970)"
    />
    <rect
      x={69.2256}
      y={48.6187}
      width={23.756}
      height={23.756}
      rx={5.95045}
      fill="black"
      fillOpacity={0.1}
    />
    <path d="M80.168 54.5688H82.0398V66.4239H80.168V54.5688Z" fill="white" />
    <path d="M75.1763 59.5605H87.0313V61.4324H75.1763V59.5605Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_611_2970"
        x1={58.1162}
        y1={4.76831}
        x2={56.5087}
        y2={50.5385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_611_2970"
        x1={106.855}
        y1={4.76831}
        x2={108.462}
        y2={50.5385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_611_2970"
        x1={141.855}
        y1={18.7683}
        x2={143.462}
        y2={64.5385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_611_2970"
        x1={19.1162}
        y1={11.7683}
        x2={17.5087}
        y2={57.5385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_611_2970"
        x1={74.1064}
        y1={48.6187}
        x2={95.7961}
        y2={72.9187}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#026051" />
        <stop offset={1} stopColor="#3B5F59" stopOpacity={0} />
        <stop offset={1} stopColor="#47A092" />
      </linearGradient>
    </defs>
  </svg>
);
export default AddFileIcon;
