import { useState } from "react";
import styles from "../Input/input.module.scss";

import Icon from "../Icon";

import { useField } from "formik";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
}

const Textarea: React.FC<TextareaProps> = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  const isError = Boolean(meta.error && meta.touched);

  const [active, setActive] = useState(false);

  const handleClearIcon = () => {
    helpers.setValue("");
  };

  const textIcon = active && (
    <button
      type="button"
      className={styles["input__icon"]}
      onClick={handleClearIcon}
    >
      <Icon iconName="close" />
    </button>
  );

  return (
    <div className={styles["input"]}>
      <label htmlFor={props.name}>{props.label}</label>
      <div className={styles["input__field"]} data-focused={active}>
        <textarea
          {...field}
          {...props}
          onFocus={() => setActive(true)}
        ></textarea>

        <div className={styles["input__label-icon"]}>{textIcon}</div>
      </div>
      {isError && (
        <p className={styles["input__errors"]} data-error={isError}>
          <span className={styles["input__errors-message"]}>{meta.error}</span>
        </p>
      )}
    </div>
  );
};

export default Textarea;
