import PlayIcon from "src/assets/icons/play";
import { cn } from "src/lib/utils";

const Video = ({
  url,
  preload,
  autoPlay = false,
  controls = false,
  className,
}: {
  url: string;
  preload?: string;
  autoPlay?: boolean;
  controls?: boolean;
  className?: string;
}) => {
  return (
    <>
      <video
        className={cn("h-full w-full object-cover", className)}
        autoPlay={autoPlay}
        controls={controls}
        preload={preload}
      >
        <source src={url as string} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!controls && (
        <div className="absolute inset-0 flex items-center justify-center">
          <PlayIcon className="text-inawo-black-500 h-8 w-8 fill-current" />
        </div>
      )}
    </>
  );
};

export default Video;
