import { FC } from "react";
import Logo from "../../assets/icons/inawo-logo-2.svg";
import styles from "./fallback.module.scss";

const FallbackComponent: FC = () => {
  return (
    <div className={styles["index__loader"]}>
      <div className={styles["index__loader__hidden"]}>
        <img src={Logo} alt="Inawo" className={styles["index__loader__logo"]} />
      </div>
      <div className={styles["index__loader-dots"]}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default FallbackComponent;
