const NoMessage = () => {
  return (
    <svg
      width="141"
      height="117"
      viewBox="0 0 141 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="38.1907" cy="66.6175" r="25.1311" fill="#619D94" />
      <path
        d="M75.8875 116.879C75.8875 111.929 74.9125 107.027 73.0181 102.453C71.1236 97.8798 68.3469 93.7242 64.8464 90.2237C61.346 86.7233 57.1903 83.9465 52.6167 82.0521C48.0432 80.1577 43.1412 79.1826 38.1908 79.1826C33.2404 79.1826 28.3385 80.1577 23.7649 82.0521C19.1914 83.9465 15.0357 86.7233 11.5352 90.2237C8.03478 93.7242 5.25807 97.8798 3.36363 102.453C1.46919 107.027 0.49414 111.929 0.494141 116.879L38.1908 116.879H75.8875Z"
        fill="url(#paint0_linear_790_12661)"
      />
      <path
        d="M75.8875 116.879C75.8875 111.929 74.9125 107.027 73.0181 102.453C71.1236 97.8798 68.3469 93.7242 64.8464 90.2237C61.346 86.7233 57.1903 83.9465 52.6167 82.0521C48.0432 80.1577 43.1412 79.1826 38.1908 79.1826C33.2404 79.1826 28.3385 80.1577 23.7649 82.0521C19.1914 83.9465 15.0357 86.7233 11.5352 90.2237C8.03478 93.7242 5.25807 97.8798 3.36363 102.453C1.46919 107.027 0.49414 111.929 0.494141 116.879L38.1908 116.879H75.8875Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g filter="url(#filter0_bi_790_12661)">
        <circle cx="105.899" cy="35.4863" r="35" fill="#51837C" />
        <circle
          cx="105.899"
          cy="35.4863"
          r="34.5977"
          stroke="url(#paint1_linear_790_12661)"
          strokeOpacity="0.5"
          strokeWidth="0.804598"
        />
      </g>
      <path
        d="M111.293 32.9689C111.207 32.7863 110.881 32.1137 110.09 31.7276C109.697 31.5399 109.262 31.4495 108.823 31.4645C107.453 31.4832 106.519 32.4418 106.302 32.6762C106.086 32.4418 105.151 31.4848 103.781 31.4645C103.343 31.4495 102.907 31.5399 102.514 31.7276C101.723 32.114 101.397 32.7863 101.312 32.9689C101.145 33.3338 101.063 33.7296 101.073 34.1281C101.073 34.2685 101.085 34.8414 101.46 35.5914C101.844 36.3584 102.386 36.8214 102.756 37.1692C103.66 38.0141 104.671 38.7496 105.756 39.3654C106.093 39.5567 106.511 39.5567 106.848 39.3654C107.934 38.7496 108.944 38.0141 109.848 37.1692C110.217 36.8221 110.76 36.359 111.144 35.5914C111.519 34.8414 111.531 34.2685 111.531 34.1281C111.541 33.7296 111.46 33.3338 111.293 32.9689Z"
        fill="white"
      />
      <path
        d="M128.189 32.9689C128.104 32.7863 127.778 32.1137 126.987 31.7276C126.594 31.5399 126.158 31.4495 125.72 31.4645C124.35 31.4832 123.416 32.4418 123.199 32.6762C122.982 32.4418 122.048 31.4848 120.677 31.4645C120.239 31.4495 119.804 31.5399 119.411 31.7276C118.619 32.114 118.293 32.7863 118.208 32.9689C118.041 33.3338 117.96 33.7296 117.97 34.1281C117.97 34.2685 117.982 34.8414 118.357 35.5914C118.74 36.3584 119.282 36.8214 119.652 37.1692C120.557 38.0141 121.567 38.7496 122.653 39.3654C122.99 39.5567 123.408 39.5567 123.745 39.3654C124.83 38.7496 125.84 38.0141 126.745 37.1692C127.113 36.8221 127.657 36.359 128.041 35.5914C128.415 34.8414 128.427 34.2685 128.428 34.1281C128.438 33.7296 128.356 33.3338 128.189 32.9689Z"
        fill="white"
      />
      <path
        d="M94.4043 32.9689C94.3225 32.7863 94.009 32.1137 93.2482 31.7276C92.8704 31.5399 92.4517 31.4495 92.03 31.4645C90.7127 31.4832 89.8145 32.4418 89.6059 32.6762C89.3976 32.4418 88.4991 31.4848 87.1817 31.4645C86.7601 31.4495 86.3414 31.5399 85.9635 31.7276C85.2028 32.114 84.8892 32.7863 84.8074 32.9689C84.6469 33.3338 84.5685 33.7296 84.5779 34.1281C84.5779 34.2685 84.5898 34.8414 84.9504 35.5914C85.319 36.3584 85.8402 36.8214 86.1959 37.1692C87.0656 38.0141 88.0371 38.7496 89.0808 39.3654C89.405 39.5567 89.8068 39.5567 90.1309 39.3654C91.1747 38.7496 92.1461 38.0141 93.0158 37.1692C93.3699 36.8221 93.8928 36.359 94.2617 35.5914C94.6219 34.8414 94.6335 34.2685 94.6338 34.1281C94.6434 33.7296 94.565 33.3338 94.4043 32.9689Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_bi_790_12661"
          x="54.8075"
          y="-15.6056"
          width="102.184"
          height="102.184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.04598" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_790_12661"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_790_12661"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4.02299" dy="4.02299" />
          <feGaussianBlur stdDeviation="4.02299" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_790_12661"
          />
        </filter>
        <linearGradient
          id="paint0_linear_790_12661"
          x1="15.9842"
          y1="79.1826"
          x2="41.768"
          y2="136.956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#026051" />
          <stop offset="1" stopColor="#3B5F59" stopOpacity="0" />
          <stop offset="1" stopColor="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_790_12661"
          x1="80.1523"
          y1="9.7392"
          x2="131.244"
          y2="60.8312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoMessage;
