import Close3 from "src/assets/icons/close3";
import { UploadValue } from "src/utils/types";

const ImageGallery = ({
  uploadResult,
  active,
  onRemoveImage,
}: {
  uploadResult: UploadValue;
  active: string;
  onRemoveImage: (preview: string) => void;
}) => {
  const mediaUpload =
    uploadResult.filter((item) => item?.preview === active).length > 0
      ? uploadResult.find((item) => item?.preview === active)
      : uploadResult[0];

  return (
    <div className="mx-auto w-full p-4">
      <div className="relative">
        <div className="relative mx-auto h-[194px] w-[154px]">
          {mediaUpload?.file?.type?.includes("video/") ? (
            <video
              preload="none"
              className="h-full w-full rounded-lg object-cover shadow-lg"
              controls
              key={mediaUpload?.preview}
            >
              <source src={mediaUpload?.preview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={mediaUpload?.preview}
              alt="Selected"
              className="h-full w-full rounded-lg object-cover shadow-lg"
            />
          )}
          <button
            type="button"
            className="absolute -right-2 -top-2 rounded-full bg-gray-800 p-1 text-white"
            onClick={() =>
              onRemoveImage(
                uploadResult.find((item) => item?.preview === active)
                  ?.preview ?? ""
              )
            }
          >
            <Close3 />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
