import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
import InnerLoading from "src/components/InnerLoading";
import Modal from "src/components/Modal";
import {
  AddWishlistItem,
  DeleteWishlistItem,
  UpdateWishlistItem,
} from "src/graphql/mutations";
import { GetWishListItems } from "src/graphql/queries";
import { ModalType, WishlistType } from "src/utils/types";
import WishlistAddItem from "./WishlistAddItem";
import WishlistCard from "./WishlistCard";
import WishlistDeleteItem from "./WishlistDeleteItem";
import WishlistEditItem from "./WishlistEditItem";
import styles from "./wishlist.module.scss";
// import Pagination from "src/components/Pagination";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import NoGift from "src/assets/icons/NoGift";
import Search from "src/components/Search";
import { BlockIcon } from "src/layouts/dashboard/Icons/BlockIcon";
import { RowIcon } from "src/layouts/dashboard/Icons/RowIcon";
import useAppStore from "src/store/utils";
import {
  getWishListItems,
  getWishListItemsVariables,
  getWishListItems_getWishListItems,
} from "src/types/api";
import { v4 as uuidv4 } from "uuid";

export const PryColor: string = "#34544f";

export const SecColor: string = "#c4c4c4";

const Wishlist = () => {
  const [activeState, setActiveState] = useState<WishlistType>("wishlistCard");
  const [toggleModal, setToggleModal] = useState(false);
  const [modalActiveStates, setModalActiveStates] =
    useState<ModalType>("wishlistAddItem");
  const [searchValue, setSearchValue] = useState("");
  const [editItemId, setEditItemId] = useState<number | undefined>();
  const [deleteItemId, setDeleteItemId] = useState<number | undefined>();
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const [currentEditElement, setCurrentEditElement] =
    useState<getWishListItems_getWishListItems>({
      __typename: "WishListItem",
      id: 0,
      wish_list_id: 0,
      name: "",
      image: "",
      amount: 0,
      currency: "",
      paid: false,
      created_at: "",
      updated_at: "",
    });

  // const [totalPages, setTotalPages] = useState(1);
  // const [startPage, setStartPage] = useState(1);
  const [pageSize] = useState(50);
  const [page] = useState(1);

  const [addWishListItem, { loading: addWishListLoading }] = useMutation(
    AddWishlistItem,
    {
      onCompleted() {
        toast.success(<p className="toast">Your item has been added.</p>);
        setToggleModal(false);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [updateWishListItem, { loading: updateWishListLoading }] = useMutation(
    UpdateWishlistItem,
    {
      onCompleted() {
        toast.success(<p className="toast">Your item has been updated.</p>);
        setToggleModal(false);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [deleteWishListItem, { loading: deleteWishListItemLoading }] =
    useMutation(DeleteWishlistItem, {
      onCompleted() {
        setToggleModal(false);
        toast.success(<p className="toast">Your item has been deleted.</p>);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const { data: wishLists, loading: getWishlistItemLoading } = useQuery<
    getWishListItems,
    getWishListItemsVariables
  >(GetWishListItems, {
    skip: !event.id,
    variables: {
      eventId: event.id,
      page,
      pageSize,
    },
    // onCompleted(response) {
    //   if (!!response?.getWishListItems && response?.getWishListItems.length) {
    //     setTotalPages(
    //       Math.floor(response?.getWishListItems.length / pageSize)
    //     );
    //   }
    // },
    // fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
      }
      toast.error(
        <p className="toast">{error?.message ?? "An error occured"}</p>
      );
    },
  });

  const handleRemoveWishlist = () => {
    deleteWishListItem({
      variables: {
        eventId: event.id,
        itemId: deleteItemId,
      },
      refetchQueries: [
        {
          query: GetWishListItems,
          variables: { eventId: event.id, page, pageSize },
        },
      ],
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event?.target.value);
  };

  const handleToggleModal = () => {
    setToggleModal((toggleModal) => !toggleModal);
  };

  const handleAddItem = () => {
    setModalActiveStates("wishlistAddItem");
    setToggleModal(true);
  };

  const handleEditItem = (
    element: getWishListItems_getWishListItems | null
  ) => {
    if (!!element) {
      setModalActiveStates("wishlistEditItem");
      setToggleModal(true);
      setEditItemId(element.id);
      setCurrentEditElement(element);
    }
  };

  const handleDeleteItem = (id: number) => {
    setModalActiveStates("wishlistDeleteItem");
    setToggleModal(true);
    setDeleteItemId(id);
  };

  const EmptyState = () => {
    return (
      <div className={styles["emptyState"]}>
        <Info>
          <Icon iconName="bulb" />

          <p className={styles["EmptyState--title"]}>
            Gift gives you the power to add gifts you would want to get for your
            wedding
          </p>
        </Info>

        <div className={styles["emptyState--main"]}>
          <NoGift />

          <p> Who wouldn't love some gifts? Let's begin adding them </p>

          <Button type="button" onClick={handleAddItem} label="Add Item">
            Add Item
          </Button>
        </div>
      </div>
    );
  };

  const modalStates: Record<ModalType, JSX.Element> = {
    wishlistAddItem: (
      <WishlistAddItem
        page={page}
        pageSize={pageSize}
        setToggleModal={setToggleModal}
        addWishListItem={addWishListItem}
        loading={addWishListLoading}
      />
    ),
    wishlistEditItem: (
      <WishlistEditItem
        itemId={editItemId}
        page={page}
        pageSize={pageSize}
        setToggleModal={setToggleModal}
        updateWishListItem={updateWishListItem}
        currentElement={currentEditElement}
        loading={updateWishListLoading}
      />
    ),
    wishlistDeleteItem: (
      <WishlistDeleteItem
        loading={deleteWishListItemLoading}
        handleRemoveWishList={handleRemoveWishlist}
      />
    ),
  };

  return (
    <div className={styles["wishlist"]}>
      {getWishlistItemLoading ? (
        <div className={styles["wishlist__loading"]}>
          <InnerLoading />
        </div>
      ) : !!wishLists?.getWishListItems &&
        wishLists?.getWishListItems?.length !== 0 ? (
        <>
          <div className={styles["wishlist__header"]}>
            <div className={styles["wishlist__header-search"]}>
              <Search
                searchValue={searchValue}
                placeholder={"Search Wishlist"}
                handleChange={handleSearchChange}
              />
            </div>
            <div className={styles["wishlist__header-buttons"]}>
              <button
                className={`${styles["wishlist__header-shape"]} ${
                  activeState === "wishlistCard" &&
                  styles["wishlist__header-shape__active"]
                }`}
                onClick={() => setActiveState("wishlistCard")}
              >
                <BlockIcon
                  fill={activeState === "wishlistCard" ? PryColor : SecColor}
                />
              </button>
              <button
                className={`${styles["wishlist__header-shape"]} ${
                  activeState === "wishlistTable" &&
                  styles["wishlist__header-shape__active"]
                }`}
                onClick={() => setActiveState("wishlistTable")}
              >
                <RowIcon
                  fill={activeState === "wishlistTable" ? PryColor : SecColor}
                />
              </button>
              <div className={styles["wishlist__header-addItem"]}>
                <Button type="button" onClick={handleAddItem} label="Add Item">
                  Add Item
                </Button>
              </div>
            </div>
          </div>

          <div className={styles["wishlist__main"]}>
            {activeState === "wishlistTable" && (
              <div className={styles["wishlist__main__top"]}>
                <h4>NAME</h4>
                <h4>PRICE</h4>
                <h4>&nbsp;</h4>
              </div>
            )}
            <div
              className={`${
                activeState === "wishlistCard"
                  ? styles["wishlist__main-content-card"]
                  : activeState === "wishlistTable"
                    ? styles["wishlist__main-content-table"]
                    : ""
              }`}
            >
              {wishLists?.getWishListItems?.map((element, index) => {
                return (
                  <WishlistCard
                    key={uuidv4()}
                    list={element}
                    activeState={activeState}
                    handleEdit={() => {
                      handleEditItem(element);
                    }}
                    handleDelete={() => {
                      if (!!element?.id) {
                        handleDeleteItem(element.id);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <EmptyState />
      )}

      <Modal toggleModal={toggleModal} handleToggleModal={handleToggleModal}>
        <div className={styles["wishlist__modal"]}>
          {modalStates[`${modalActiveStates}`]}
        </div>
      </Modal>
      {/* <Pagination
        pageSize={pageSize}
        startPage={startPage}
        setPageSize={setPageSize}
        setStartPage={setStartPage}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
      /> */}
    </div>
  );
};

export default Wishlist;
