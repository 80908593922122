import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { DeleteGuestCategory } from "src/graphql/mutations";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import {
  deleteGuestCategory,
  noGuestPerCategory_noGuestPerCategory,
} from "src/types/api";
import removeCategoryModal from "./removeCategoryModal.module.scss";

interface RemoveCategoryProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  category: noGuestPerCategory_noGuestPerCategory;
}

const RemoveCategory = ({ category, setOpen }: RemoveCategoryProps) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [deleteGuestCategoryFn, { loading }] = useMutation<deleteGuestCategory>(
    DeleteGuestCategory,
    {
      onCompleted() {
        setOpen(false);
        toast.success(
          <p className="toast">You have successfully deleted the category.</p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  return (
    <div
      className={`${removeCategoryModal["removeCategoryModal--content"]} ${removeCategoryModal["show"]}`}
    >
      <h1>Delete Category</h1>
      <p>
        You are about to delete this category from your invitations. Do you wish
        to proceed?
      </p>
      <span
        className={
          removeCategoryModal["removeCategoryModal--content-btnContainer"]
        }
      >
        <div>
          <Button
            type="submit"
            onClick={() =>
              deleteGuestCategoryFn({
                variables: {
                  event_id: event.id,
                  categoryId: category.category_id,
                },
                refetchQueries: [
                  {
                    query: NumberOfGuestsByCategory,
                    variables: { eventId: event.id },
                  },
                ],
              })
            }
            label="Yes, Proceed"
            loader={loading}
          >
            Yes, Delete
          </Button>
        </div>
      </span>
    </div>
  );
};

export default RemoveCategory;
