import { useQuery } from "@apollo/client";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GetLuxeDetails } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { DescribeLuxe } from "src/types/api.d";
import Features from "./Features";
import InfoScreen from "./InfoScreen";

const Index = () => {
  const [step, setStep] = React.useState(1);
  const navigate = useNavigate();
  const { event } = useAppStore((state) => state);

  const { data, loading } = useQuery<DescribeLuxe>(GetLuxeDetails, {
    variables: { eventId: event?.uuid },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className="h-full w-full pt-[20px]">
      {step === 1 && (
        <InfoScreen
          step={step}
          setStep={setStep}
          currency={data?.describeLuxe?.currency}
          discounted_cost={data?.describeLuxe?.discounted_amount}
        />
      )}
      {step === 2 && (
        <Features
          step={step}
          setStep={setStep}
          currency={data?.describeLuxe?.currency}
          discounted_cost={data?.describeLuxe?.discounted_amount}
          undiscounted_cost={data?.describeLuxe?.undiscounted_amount}
        />
      )}
    </div>
  );
};

export default Index;
