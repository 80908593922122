import * as Tooltip from "@radix-ui/react-tooltip";
import React from "react";
import { IconType } from "src/utils/icon";
import Icon from "../Icon";

interface MessageStatusProps {
  status: "sent" | "delivered" | "pending" | "read" | "failed";
  type: IconType;
  errorMessage?: string;
}

const MessageStatus: React.FC<MessageStatusProps> = ({
  status,
  type,
  errorMessage,
}) => {
  let statusClasses = "";
  let statusText = "";
  let tickIcon: any = "";

  switch (status) {
    case "pending":
      statusClasses = "bg-[#ffffeb]  border-[#bfbf00] text-[#bfbf00]";
      statusText = "Pending";
      break;
    case "sent":
      statusClasses = " text-[#71717A] border-[#71717A]";
      statusText = "Sent";
      tickIcon = "singleGrayTick";
      break;
    case "read":
      statusClasses = "bg-[#f2fff6] text-[#16A34A]  border-[#16A34A]";
      statusText = "Read";
      tickIcon = "greenDoubleTick";
      break;
    case "delivered":
      statusClasses = "bg-[#fff0df] text-[#D97706]  border-[#D97706]";
      statusText = "Delivered";
      tickIcon = "orangeDoubleTick";
      break;
    default:
      statusClasses = "bg-[#ffe7e7]  border-[#ff0000] text-[#ff0000]";
      statusText = "Failed";
  }

  return (
    <>
      {errorMessage ? (
        <Tooltip.Provider delayDuration={200}>
          <Tooltip.Root>
            <Tooltip.Trigger>
              <div
                className={`flex items-center gap-2 rounded-md border px-2 py-[2px] ${statusClasses} text-xs`}
              >
                {tickIcon && (
                  <div
                    className={`${
                      tickIcon === "singleGrayTick" ? "-mr-1" : ""
                    } w-4`}
                  >
                    <Icon iconName={tickIcon} />
                  </div>
                )}
                <p className="font-medium">{statusText}</p>
                <div className="w-[15px]">
                  <Icon iconName={type} />
                </div>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content
              side="right"
              className="rounded-sm bg-white px-4 py-1 shadow-lg"
              sideOffset={4}
            >
              <div className="text-[0.875rem] text-dark-1">{errorMessage}</div>
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      ) : (
        <div
          className={`flex items-center gap-2 rounded-md border px-2 py-[2px] ${statusClasses} text-xs`}
        >
          {tickIcon && (
            <div
              className={`${tickIcon === "singleGrayTick" ? "-mr-1" : ""} w-4`}
            >
              <Icon iconName={tickIcon} />
            </div>
          )}
          <p className="font-medium">{statusText}</p>
          <div className="w-[15px]">
            <Icon iconName={type} />
          </div>
        </div>
      )}
    </>
  );
};

export default MessageStatus;
