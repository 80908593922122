import NoMessage from "src/assets/icons/NoMessage";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
import styles from "./styles.module.scss";

function EmptyState() {
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        <Icon iconName="bulb" />

        <p className={styles["EmptyState--title"]}>
          Receive and respond to well wishes from your guests
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        <NoMessage />
        <p>No messages yet. Check back later.</p>
      </div>
    </div>
  );
}

export default EmptyState;
