const Addmore = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" rx="19" fill="#2D2D2D" />
      <path
        d="M19 13.6877V23.6877M24 18.6877H14"
        stroke="#DADADA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Addmore;
