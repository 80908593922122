import { Dispatch, SetStateAction, useState } from "react";
import DialogModal from "src/components/Dialog";
import { GetMedia as getMediaType } from "src/types/api";
import EmptyState from "../../component/EmptyState";
import styles from "../../photobook.module.scss";

import Button from "src/components/Button";
import DropDownMenu from "src/components/Dropdown";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import { cn } from "src/lib/utils";
import useAppStore from "src/store/utils";
import { photoBookTabTypes, SelectedAlbumType } from "src/utils/types";
import AddPictures from "../../component/AddPictures";
import DeleteSelected from "../../component/DeleteSelected";
import ImageList from "../../component/ImageList";

type Props = {
  selectedAlbum?: SelectedAlbumType;
  setTabState: Dispatch<SetStateAction<photoBookTabTypes>>;
  images: getMediaType["getMedia"] | null;
  loading: boolean;
};

const YourAlbum = ({ selectedAlbum, setTabState, images, loading }: Props) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const { event } = useAppStore((state) => state);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const handleChange = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  return (
    <div className="mt-4">
      <div
        className="mb-[19px] flex cursor-pointer items-center gap-[7.32px] text-[#787777]"
        onClick={() => setTabState("photos")}
      >
        <Icon iconName="arrowLeftBlack" />
        <span className="text-[17.15px] font-medium leading-[20.59px]">
          Back
        </span>
      </div>

      {loading ? (
        <div className={styles["photobook__loading"]}>
          <InnerLoading />
        </div>
      ) : images && images.length > 0 ? (
        <>
          <div
            className={
              "flex flex-col items-start justify-between gap-5 md:flex-row"
            }
          >
            <div className="flex flex-col gap-[9.38px]">
              <p className="text-black text-2xl font-medium leading-[34.05px] lg:text-[28.14px]">
                {selectedAlbum!.name} photos
              </p>
              {selected?.length ? (
                <p className={styles["photobook__trigger__number"]}>
                  Number of images: <span>{images.length}</span>
                </p>
              ) : (
                !showSelected && (
                  <div className="hidden w-fit items-center justify-center gap-[3px] rounded-md border border-secondary-6 px-2.5 text-[#787777] sm:flex">
                    <Icon iconName="thumbnail" />
                    <p>{images.length}</p>
                  </div>
                )
              )}
            </div>

            <div className={styles["photobook__trigger__right"]}>
              {showSelected ? (
                <>
                  <div>
                    <DialogModal
                      open={openDelete}
                      onOpenChange={setOpenDelete}
                      disabled={!selected.length}
                      trigger={
                        <Button
                          label="delete"
                          type="button"
                          onClick={() => {
                            setOpenDelete(true);
                          }}
                          design="secondary"
                          style={{
                            borderRadius: "9.52px",
                            height: "37px",
                            borderColor: "#DC2626",
                          }}
                          className="text-[14.58px] !text-[#DC2626]"
                        >
                          Delete{" "}
                          <span className="hidden !text-[#DC2626] sm:inline-block">
                            Selected
                          </span>
                        </Button>
                      }
                    >
                      <DeleteSelected
                        setShowDelete={setShowSelected}
                        setOpen={setOpenDelete}
                        deleteList={selected}
                        selectedAlbum={selectedAlbum}
                      />
                    </DialogModal>
                  </div>
                  <div>
                    <Button
                      label="cancel"
                      type="button"
                      onClick={() => {
                        setShowSelected(false);
                        setSelected([]);
                      }}
                      design="secondary"
                      style={{
                        borderRadius: "9.52px",
                        height: "37px",
                      }}
                      className="text-[14.58px]"
                    >
                      Cancel{" "}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <DropDownMenu
                    trigger={
                      <div className={styles["photobook__trigger"]}>
                        <span>Add </span>
                      </div>
                    }
                  >
                    <div className="flex flex-col items-start gap-2 px-4 py-2">
                      <AddPictures type="photo" album={selectedAlbum} />
                    </div>
                  </DropDownMenu>

                  {!expanded && (
                    <div
                      className="sm:hidden"
                      onClick={() => setExpanded(!expanded)}
                    >
                      <Icon iconName="dotHorizontal" />
                    </div>
                  )}
                  <div
                    className={cn(
                      "hidden items-center gap-[5.63px] sm:flex",
                      expanded && "flex"
                    )}
                  >
                    {/* <div>
                      <Button
                        label="select"
                        type="button"
                        disabled={false}
                        design="secondary"
                        style={{
                          borderRadius: "9.52px",
                          height: "37px",
                        }}
                        className="flex items-center gap-[5px] text-[14.58px]"
                      >
                        <Icon iconName="capture" />
                        <span className="hidden lg:block">Upload code</span>
                      </Button>
                    </div> */}
                    <div className="">
                      <Button
                        label="select"
                        type="button"
                        onClick={() => {
                          setShowSelected(!showSelected);
                          setSelected([]);
                        }}
                        disabled={false}
                        design="secondary"
                        style={{
                          borderRadius: "9.52px",
                          height: "37px",
                        }}
                        className="flex items-center gap-[5px] text-[14.58px]"
                      >
                        <span> Select</span>
                      </Button>
                    </div>
                    <a
                      href={`https://${event.slug}.${process.env.REACT_APP_INAWO_ALBUM_SITE_END}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        label="delete"
                        type="button"
                        disabled={false}
                        design="secondary"
                        className="flex items-center gap-[5px] text-[14.58px]"
                        style={{
                          borderRadius: "9.52px",
                          height: "37px",
                        }}
                      >
                        <Icon iconName="eyeOpenOutline" />
                        <span className="hidden lg:block"> See photobook</span>
                      </Button>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
          <ImageList
            groupedMedia={images}
            showSelected={showSelected}
            selected={selected}
            selectedAlbum={selectedAlbum}
            handleChange={handleChange}
            setTabState={setTabState}
          />
        </>
      ) : (
        <div className={styles["photobook__add-pictures-container"]}>
          <div className={styles["photobook__add-pictures"]}>
            <EmptyState />
          </div>
        </div>
      )}
      {/* <Pagination
        pageSize={pageSize}
        startPage={startPage}
        setPageSize={setPageSize}
        setStartPage={setStartPage}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
    /> */}
    </div>
  );
};

export default YourAlbum;
