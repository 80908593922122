import { useMutation } from "@apollo/client";
import { Dispatch, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import {
  DeleteCustomMyPlanTask,
  UpdateDoneStatus,
} from "src/graphql/mutations/event.mutation";
import { GetCustomMyPlanTask } from "src/graphql/queries/event.queries";
import useAppStore from "src/store/utils";
import {
  deleteCustomMyPlanTask,
  deleteCustomMyPlanTaskVariables,
  updateDoneStatus,
  updateDoneStatusVariables,
} from "src/types/api";
import EditTask from "../edit-task";
import viewTask from "./viewTask.module.scss";

interface EditTaskProps {
  id: string;
  text: string;
  done: boolean;
  date: string;
  description: string;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

const ViewTask = ({
  date,
  id,
  text,
  description,
  done,
  setOpen,
}: EditTaskProps) => {
  const navigate = useNavigate();

  const [deleteFn, { loading: deleteLoading }] = useMutation<
    deleteCustomMyPlanTask,
    deleteCustomMyPlanTaskVariables
  >(DeleteCustomMyPlanTask, {
    onCompleted() {
      setOpen(false);
      toast.success(<p>Successfully delete task.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const { event } = useAppStore((state) => state);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [updateDoneFn, { loading: updateLoading }] = useMutation<
    updateDoneStatus,
    updateDoneStatusVariables
  >(UpdateDoneStatus, {
    onCompleted() {
      setOpen(false);
      toast.success(<p>Successfully updated task.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });
  const handleMark = () => {
    updateDoneFn({
      variables: { eventId: event.id, isDone: !done, taskId: id },
      refetchQueries: [
        {
          query: GetCustomMyPlanTask,
          variables: {
            eventId: event.id,
          },
          fetchPolicy: "network-only",
        },
      ],
    });
  };
  return (
    <div className={viewTask["viewTask--content"]}>
      <div className={viewTask["viewTask--row"]}>
        <p className={viewTask["viewTask--title"]}>{text}</p>
        <div className={viewTask["viewTask--group"]}>
          <div className={viewTask["viewTask--edit"]}>
            <DialogModal
              trigger={<Icon iconName="write" />}
              open={openEdit}
              onOpenChange={setOpenEdit}
            >
              <EditTask
                done={done}
                text={text}
                description={description}
                setOpen={setOpenEdit}
                setOpenView={setOpen}
                id={`${id}`}
              />
            </DialogModal>
          </div>
          <button
            onClick={() =>
              deleteFn({
                variables: { eventId: event.id, taskId: id },
                refetchQueries: [
                  {
                    query: GetCustomMyPlanTask,
                    variables: {
                      eventId: event.id,
                    },
                  },
                ],
              })
            }
            className={viewTask["viewTask--button"]}
          >
            <Icon iconName="delete2" />
          </button>
        </div>
      </div>

      <div className={viewTask["viewTask--content--description"]}>
        {description}
      </div>
      <div className={viewTask["viewTask--content-btnContainer"]}>
        <p className={viewTask["viewTask--date"]}>
          Date Added: {new Date(date).toDateString()}
        </p>
        <div>
          <Button
            onClick={() => handleMark()}
            type="button"
            label="Mark as Complete"
            loader={updateLoading}
          >
            Mark as {done ? "Incomplete" : "Complete"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewTask;
