import React from "react";
import DialogModal from "src/components/Dialog";
import {
  AccessControlIcon,
  ActivateAccessControlIcon,
  InawoGreenLuxeIcon,
} from "src/components/Icons";
import ToggleAccessControlModal from "../Modals/ToggleAccessControlModal";

const Index = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const activationSteps = [
    "Activate access control",
    "Guest receive a digital pass (through invites and email)",
    "Bouncers scan the pass to verify guests at the venue",
  ];
  return (
    <div className="mx-auto mt-[30px] flex max-w-[400px] flex-col items-center justify-center px-[17px] py-[29px]">
      <div className="mb-[22px] flex flex-col items-center justify-center text-center">
        <div className="mb-[9px]">
          <AccessControlIcon />
        </div>
        <h3 className="mb-[9px] text-[19px] font-medium text-[#298576]">
          Access control
        </h3>
        <p className="leading-[22px] text-[#707070]">
          Allows you to have a very private and exclusive event by giving each
          of your guests a pass (Inawo pass)
        </p>
      </div>
      <h3 className="mb-[22px] text-[15px] font-medium uppercase tracking-[2px] text-[#A9A9A9] lg:tracking-[4.5px]">
        how it works
      </h3>
      <div className="mb-[32px] flex flex-col items-start justify-center gap-[15px]">
        {activationSteps?.map((step: string, index) => (
          <div className="flex items-center gap-[10px]">
            <div className="flex h-[66px] w-[62px] items-center justify-center rounded-[16px] border border-[#ABABAB80]">
              <img
                src="/activation-steps-icon.svg"
                alt="activation steps icon"
              />
            </div>
            <p className="max-w-[250px] text-[16px] text-[#3A5752B2] lg:max-w-[300px] lg:text-[17px]">
              {step}
            </p>
          </div>
        ))}
      </div>
      <div className="mb-[30px]">
        <div className="flex items-center gap-0 tracking-[2px] lg:gap-[7px] lg:tracking-[4.5px]">
          <span className="text-[15px] font-medium uppercase text-[#A9A9A9]">
            YOU’RE CURRENTLY ON
          </span>
          <InawoGreenLuxeIcon />
        </div>
      </div>

      <div>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <button
              className="mx-auto flex w-[167px] items-center justify-center gap-[10px] rounded-[10px] bg-[#34544F] px-[38px] py-[15px] font-medium"
              type="button"
            >
              <ActivateAccessControlIcon />{" "}
              <span style={{ color: "white" }}>Activate</span>
            </button>
          }
        >
          <ToggleAccessControlModal setOpen={setOpen} />
        </DialogModal>
      </div>
    </div>
  );
};

export default Index;
