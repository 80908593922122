import { useState } from "react";
import Tab from "src/components/Tab";
import DashboardLayout from "src/layouts/dashboard";
import { RsvpTabType, SectionRsvpCountType } from "src/utils/types";
import Coming from "./Coming";
import NotComing from "./NotComing";
import Pending from "./Requests";
import styles from "./rsvp.module.scss";

const tabItems = ["coming", "notComing", "pending"];

function Rsvp() {
  const [count, setCount] = useState<SectionRsvpCountType>(
    {} as SectionRsvpCountType
  );

  const [tabState, setTabState] = useState<RsvpTabType>("coming");

  const handleTab = (element: RsvpTabType) => {
    setTabState(element);
  };

  const tab: Record<RsvpTabType, JSX.Element> = {
    coming: <Coming count={count} setCount={setCount} />,
    notComing: <NotComing count={count} setCount={setCount} />,
    pending: <Pending count={count} setCount={setCount} />,
  };

  return (
    <DashboardLayout pageName="RSVP">
      <div className={styles["rsvp"]}>
        <div className={styles["rsvp__tabs"]}>
          <Tab
            count={count}
            tabItems={tabItems}
            tabState={tabState}
            handleTab={handleTab}
          />
        </div>
        <div className={styles["rsvp__main"]}>{tab[tabState]}</div>
      </div>
    </DashboardLayout>
  );
}

export default Rsvp;
