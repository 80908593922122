import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import DialogModal from "src/components/Dialog";
import { CloseIcon } from "src/components/Icons";
import InnerLoading from "src/components/InnerLoading";
import { Spinner } from "src/components/Spinner";
import { AutoSendInawoPass } from "src/graphql/mutations";
import { GetEvent, GetInawoPassProgress } from "src/graphql/queries";
import PassDesign from "src/layouts/dashboard/Icons/PassDesign";
import PlusIcon from "src/layouts/dashboard/Icons/PlusIcon";
import useAppStore from "src/store/utils";
import {
  autoSendInawoPassVariables,
  event,
  getInawoPassProgress,
  inawoPassProgressVariables,
} from "src/types/api.d";
import ComingSoonModal from "../Modals/ComingSoonModal";
import Header from "./components/Header";
import PassCard from "./components/PassCard";

const Index = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const { event } = useAppStore((state) => state);
  const [allPassSent, setAllPassSent] = React.useState(false);
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [showStatusHeader, setShowStatusheader] = useState<boolean>(true);
  const checkCountRef = useRef(0);

  const { data, loading } = useQuery<event>(GetEvent, {
    variables: {
      eventId: event?.id,
    },
  });

  const {
    data: passProgressData,
    refetch,
    loading: passProgressLoading,
  } = useQuery<getInawoPassProgress, inawoPassProgressVariables>(
    GetInawoPassProgress,
    {
      variables: {
        eventId: event?.uuid,
      },
    }
  );

  const [autoSendInawoPass, { loading: inawoPassLoading }] =
    useMutation<autoSendInawoPassVariables>(AutoSendInawoPass, {
      variables: {
        eventId: event?.uuid,
      },
      onCompleted() {
        toast.success("Inawo Pass is being sent to your guests.");
        setIsSending(true);
        setTimeout(() => {
          refetch();
        }, 7000);
        refetch();
      },
      onError(error) {
        toast.error(
          <p className="toast">{error?.message ?? "An error occurred"}</p>
        );
      },
    });

  useEffect(() => {
    setAllPassSent(
      passProgressData?.getInawoPassProgress?.total_sent ===
        passProgressData?.getInawoPassProgress?.total_guest
    );
  }, [passProgressData]);

  useEffect(() => {
    if (allPassSent) return;
    const isInProgress =
      passProgressData?.getInawoPassProgress?.in_progress ?? false;
    setIsSending(isInProgress);
    localStorage.setItem(`headerRequired_${event?.id}`, "true");

    let intervalId: NodeJS.Timeout;

    if (isInProgress) {
      intervalId = setInterval(() => refetch(), 13500);
    } else if (checkCountRef.current < 2) {
      intervalId = setInterval(() => {
        checkCountRef.current += 1;
        refetch();
        if (checkCountRef.current >= 2) clearInterval(intervalId);
      }, 13500);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [allPassSent, event?.id, refetch, passProgressData]);

  // Updated effect to handle header visibility logic
  useEffect(() => {
    // Check if all passes have been sent
    if (allPassSent) {
      // If all passes sent, check local storage
      const headerRequired = localStorage.getItem(
        `headerRequired_${event?.id}`
      );
      // Only show header if local storage exists and is 'true'
      setShowStatusheader(headerRequired === "true");
    } else {
      // If not all passes sent, always show the header
      setShowStatusheader(true);
    }
  }, [event?.id, allPassSent]);

  return (
    <div className="flex w-full flex-col gap-[35px] py-[10px]">
      {/* send pass to guest info header */}
      {passProgressLoading ? (
        <InnerLoading />
      ) : (
        <>
          {showStatusHeader &&
            data?.event?.event_preference?.access_control && (
              <div className="mx-auto flex w-[95%] items-center justify-between rounded-[10px] bg-gradient-to-r from-[#B9CCC9] to-[#EBF1F0] px-[20px] py-[16px] lg:mx-0 lg:w-[60%]">
                <div>
                  {(passProgressData?.getInawoPassProgress?.total_sent || 0) <=
                  (passProgressData?.getInawoPassProgress?.total_guest || 0) ? (
                    <>
                      {" "}
                      <h3 className="mb-[5px] text-[16px] font-medium">
                        {passProgressData?.getInawoPassProgress?.total_sent}/
                        {passProgressData?.getInawoPassProgress?.total_guest}{" "}
                        Guests
                      </h3>
                      <p className="text-[14px] font-medium text-[#00000066]">
                        Have recieved their pass
                      </p>
                    </>
                  ) : (
                    <>
                      {" "}
                      <h3 className="mb-[5px] text-[16px] font-medium">
                        {passProgressData?.getInawoPassProgress?.total_guest}{" "}
                        Guests
                      </h3>
                      <p className="text-[14px] font-medium text-[#00000066]">
                        Have not recieved their pass
                      </p>
                    </>
                  )}
                </div>
                {isSending ||
                passProgressData?.getInawoPassProgress?.in_progress ? (
                  <p className="text-[14px] font-medium text-[#00000080]">
                    Sending...
                  </p>
                ) : allPassSent ? (
                  <button
                    style={{
                      border: "1px solid #66666666",
                      background: "transparent",
                    }}
                    className="flex h-[36px] w-[36px] items-center justify-center rounded-full"
                    onClick={() => {
                      setShowStatusheader(false);
                      localStorage.removeItem(`headerRequired_${event?.id}`);
                    }}
                  >
                    <CloseIcon />
                  </button>
                ) : (
                  <button
                    style={{
                      border: "1px solid #66666666",
                      background: "transparent",
                    }}
                    className="rounded-[10px] px-[23px] py-[13px] text-[14.5px]"
                    onClick={() => {
                      if (data?.event?.pass_image) {
                        checkCountRef.current = 0; // Reset check counter when manually sending
                        autoSendInawoPass();
                      } else {
                        toast.error(
                          "Please set a pass image before sending Inawo pass."
                        );
                      }
                    }}
                  >
                    {inawoPassLoading ? <Spinner /> : "Send Inawo Pass"}
                  </button>
                )}
              </div>
            )}
        </>
      )}
      {/* Your pass header */}
      <Header allPassSent={allPassSent} />
      <div className="flex flex-wrap items-center justify-center gap-[37px] lg:justify-start">
        {loading ? (
          <InnerLoading />
        ) : (
          <PassCard
            passImage={data?.event?.pass_image ?? ""}
            eventSlug={data?.event?.slug ?? ""}
          />
        )}
        <DialogModal
          open={openModal}
          onOpenChange={setOpenModal}
          trigger={
            <div className="flex h-[437px] w-[307px] flex-col items-center justify-center gap-[20px] rounded-[18px] border border-dashed border-[#9F9F9F] px-[50px]">
              <div className="flex flex-col items-center">
                <div className="mb-[12px]">
                  <PassDesign />
                </div>
                <div className="mb-[26px]">
                  <PlusIcon />
                </div>
                <h3 className="mb-[15px] text-[12px] font-semibold uppercase tracking-[1px] text-[#919191]">
                  See More pass designs
                </h3>
                <p className="text-center text-[13px] text-[#898989]">
                  See more pass designs that you can use for your wedding to
                  make it unique
                </p>
              </div>
            </div>
          }
        >
          <ComingSoonModal />
        </DialogModal>
      </div>
    </div>
  );
};

export default Index;
