import Button from "src/components/Button";
import AccessControlIcon from "src/layouts/dashboard/Icons/AccessControlIcon";

const index = () => {
  return (
    <div className="flex h-[100%] w-full items-center justify-center">
      <div className="flex h-auto w-[270px] flex-col items-center gap-[30px] py-[50px]">
        <AccessControlIcon />
        <Button
          type="button"
          label="coming soon"
          style={{
            borderRadius: "25px",
            width: "153px",
            margin: "auto",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          COMING SOON
        </Button>
        <p className="text-center text-[#707070]">
          We’ll be showing all your guests activity here, coming very soon
        </p>
      </div>
    </div>
  );
};

export default index;
