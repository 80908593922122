import { useQuery } from "@apollo/client";
import { QRCodeCanvas } from "qrcode.react";
import { memo, useContext, useMemo, useRef } from "react";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Backdrop from "src/assets/images/bgImage.svg";
import Icon from "src/components/Icon";
import { DownloadIcon } from "src/components/Icons";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import { GetGeneralInviteToken } from "src/graphql/queries/invitations.queries";
import useAppStore from "src/store/utils";
import {
  getGeneralInviteToken,
  getGeneralInviteTokenVariables,
} from "src/types/api";
import { copyToClipboard } from "src/utils";
import styles from "./shareInviteCard.module.scss";

interface ShareInviteCardInterface {
  image?: string;
  setToggleModal: (modalActiveStates: boolean) => void;
  customLink?: string;
  customTitle?: string;
  customDescription?: string;
  preview?: boolean;
}

const GiftShareInviteCard = ({
  image,
  setToggleModal,
  customLink,
  customTitle,
  preview,
  customDescription,
}: ShareInviteCardInterface) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventName = contextValue?.eventName;
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const { data: inviteToken, loading: inviteTokenLoading } = useQuery<
    getGeneralInviteToken,
    getGeneralInviteTokenVariables
  >(GetGeneralInviteToken, {
    skip: !event.id,
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const shareDescription = useMemo(() => {
    const text = `Hello there, ${eventName} invite you to share in their happiness as they walk down the aisle. The couple would love you to be a part of their big day and we’re here to make that happen. Click the link attached to respond to their invitation, have fun, and engage on their wedding website.`;

    return text;
  }, [eventName]);

  const downloadQrCode = async () => {
    const canvas = qrCodeRef?.current?.querySelector("canvas");
    if (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
      toast.success(<p className="toast">QR code has been downloaded</p>);
    }
  };

  return (
    <div className={styles["shareInviteCard"]}>
      <div className={styles["shareInviteCard__heading"]}>
        <h4>Share {customTitle ?? `Invitation Link`}</h4>
      </div>
      <div className={styles["shareInviteCard__subHeading"]}>
        <span>
          {customDescription ??
            `Share a link for your guests to add their details`}
        </span>
      </div>
      {inviteTokenLoading ? (
        <div className={styles["shareInviteCard__invitationCon"]}>
          <InnerLoading />
        </div>
      ) : (
        <>
          <div
            className={
              preview
                ? styles["shareInviteCard__invitationCon--isPreview"]
                : styles["shareInviteCard__invitationCon"]
            }
          >
            {preview ? (
              <div ref={qrCodeRef}>
                <QRCodeCanvas
                  value={
                    customLink ??
                    `https://${event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}/gifts`
                  }
                  size={300}
                />
              </div>
            ) : (
              <LazyLoadImage
                src={image}
                placeholderSrc={Backdrop}
                alt="invitationCard"
                effect="blur"
                className={styles["shareInviteCard__invitationCon__img"]}
              />
            )}
          </div>
          <div className={`mt-4 ${styles["shareInviteCard__linksCon"]}`}>
            <div
              className={styles["shareInviteCard__linksCon__buttons"]}
              onClick={() =>
                copyToClipboard(
                  `${
                    customLink ??
                    `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
                  }`,
                  <p className="toast">
                    {customTitle ?? `Invite`} link has been copied
                  </p>
                )
              }
            >
              <Icon iconName="chainLink" />
              <span>Copy Link</span>
            </div>
            <FacebookShareButton
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
              quote={shareDescription}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="facebook" />
                <span>Facebook</span>
              </div>
            </FacebookShareButton>
            <TwitterShareButton
              title={shareDescription}
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="twitter" />
                <span>Twitter</span>
              </div>
            </TwitterShareButton>

            <WhatsappShareButton
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
              title={shareDescription}
              separator={` `}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="whatsapp" />
                <span>Whatsapp</span>
              </div>
            </WhatsappShareButton>
            <EmailShareButton
              title={eventName}
              body={shareDescription}
              separator={` `}
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="email" />
                <span>Email</span>
              </div>
            </EmailShareButton>
            <div
              onClick={downloadQrCode}
              className={styles["shareInviteCard__linksCon__buttons"]}
            >
              <DownloadIcon />
              <span>Download</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(GiftShareInviteCard);
