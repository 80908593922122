import { useQuery } from "@apollo/client";
import * as Tooltip from "@radix-ui/react-tooltip";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import Backdrop from "src/assets/images/bgImage.svg";
import inviteDefaultImage from "src/assets/images/generalDefaultImage.png";
import Button from "src/components/Button";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import { Spinner } from "src/components/Spinner";
import { InvitationCardTemplate } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import UploadCard from "./UploadCard";

const InvitationCard: FC = () => {
  const [image, setImage] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { loading: invitationCardLoading } = useQuery(InvitationCardTemplate, {
    variables: {
      eventId: event.id,
    },
    onCompleted(response) {
      if (!!response?.invitationCard?.template) {
        setImage(response?.invitationCard?.template);
      }
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div
      className={`relative flex h-full w-full flex-col-reverse overflow-x-scroll sm:flex-col`}
    >
      <div className="fixed bottom-0 z-[1] w-full items-center justify-between bg-secondary-1 pb-[1rem] pt-4 sm:relative sm:flex sm:bg-transparent sm:pb-[2.5rem] sm:pt-[3.5rem]">
        <div className="hidden sm:flex">
          {/* <Button
            label="Edit Card Content"
            type="button"
            design="secondary"
            className="whitespace-nowrap !text-[14px]"
          >
            Edit Card Content
          </Button> */}
        </div>
        <div className="relative flex gap-[0.75rem] px-[1.2rem] sm:px-0">
          <Tooltip.Provider delayDuration={200}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild className="hidden sm:flex">
                <div className="h-max w-max">
                  <Button
                    label="Get More Templates"
                    type="button"
                    design="secondary"
                    className="cursor-not-allowed whitespace-nowrap !text-[12px] sm:!text-[14px]"
                  >
                    Get More Templates
                  </Button>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Content
                side="top"
                className="flex flex-col gap-[1.625rem] rounded-[6px] border border-dashed border-primary-5 bg-primary-4 px-[1.375rem] py-[.5rem]"
              >
                <div className="flex flex-col gap-1">
                  <h6 className="text-[13px] font-[600] leading-[19px] -tracking-[0.02em] text-dark-1">
                    Coming soon!
                  </h6>
                  <p className="text-[14px] leading-[21px] -tracking-[0.02em] text-secondary-3">
                    Get more invitation templates <br />
                    from our marketplace.
                  </p>
                </div>

                <Tooltip.Arrow className={`fill-primary-5`} />
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>

          <DialogModal
            trigger={
              <Button
                label="Upload IV"
                type="button"
                design="primary"
                className="flex items-center gap-[0.7rem] !text-white"
              >
                <Icon iconName="uploadFile" />
                <span className="whitespace-nowrap !text-[12px] !text-white sm:!text-[14px]">
                  Upload IV
                </span>
              </Button>
            }
            open={openUpload}
            onOpenChange={setOpenUpload}
          >
            <UploadCard setToggleModal={setOpenUpload} />
          </DialogModal>
        </div>
      </div>
      <div className="grid flex-grow grid-cols-1 gap-8 px-[1.2rem] sm:grid-cols-2 sm:px-0 xl:grid-cols-3">
        <DialogModal
          trigger={
            <div className="group flex max-h-[30rem] w-full cursor-pointer flex-col gap-8 rounded-[8px] border border-secondary-4 bg-1 px-8 py-[calc(32px+8px)]">
              {invitationCardLoading ? (
                <div className="grid h-[20vh] w-full place-items-center">
                  <Spinner />
                </div>
              ) : (
                <div className="no-scrollbar h-full w-full overflow-auto transition-transform duration-300 ease-in-out group-hover:-translate-y-2">
                  <LazyLoadImage
                    src={image?.length ? image : inviteDefaultImage}
                    placeholderSrc={Backdrop}
                    alt="invitationCard"
                    effect="blur"
                    className="h-full w-full bg-contain bg-center"
                  />
                </div>
              )}
              <div className="flex justify-center">
                <Button
                  label="Preview"
                  type="button"
                  design="secondary"
                  className="cursor-not-allowed whitespace-nowrap !text-[14px] !text-white"
                  wrapperBtnClassName="!bg-transparent !border-white group-hover:!bg-white/20"
                >
                  Preview
                </Button>
              </div>
            </div>
          }
          open={openPreview}
          onOpenChange={setOpenPreview}
        >
          <div className="grid max-h-[90vh] max-w-[90vw] place-items-center overflow-auto bg-white md:max-w-[60vw]">
            <LazyLoadImage
              src={image?.length ? image : inviteDefaultImage}
              placeholderSrc={Backdrop}
              alt="invitationCard"
              effect="blur"
              className="h-full bg-center"
            />
          </div>
        </DialogModal>
      </div>
    </div>
  );
};

export default InvitationCard;
