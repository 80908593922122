import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type EventType = {
  id: number;
  uuid: string;
  slug: string;
};

interface UtilState {
  event: EventType;
  setEvent: (event: Partial<EventType>) => void;
}

export const useAppStore = create<UtilState>()(
  persist(
    (set) => ({
      event: {
        id: 0,
        uuid: "",
        slug: "",
      },
      setEvent: (event) =>
        set((state) => ({
          ...state,
          event: {
            ...state.event,
            ...event,
          },
        })),
    }),
    {
      name: "event-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAppStore;
