import { useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import InnerLoading from "src/components/InnerLoading";
import Tab from "src/components/Tab";
import { AppContext } from "src/context";
import { GetEventPreferenceById } from "src/graphql/queries";
import DashboardLayout from "src/layouts/dashboard";
import useAppStore from "src/store/utils";
import { getEventPreferences } from "src/types/api.d";
import { AccessControlTabType } from "src/utils/types";
import ActivationScreen from "./ActivateAccessControl";
import ActivationSteps from "./ActivationSteps";
import CheckInActivity from "./CheckInActivity";
import YourPass from "./YourPass";
import styles from "./styles.module.scss";
const tabItems: AccessControlTabType[] = ["yourPass", "checkInActivity"];

const Index = () => {
  const [tabState, setTabState] = useState<AccessControlTabType>("yourPass");
  const { event } = useAppStore((state) => state);
  const isLuxeEvent = useContext(AppContext)?.isLuxeEvent;
  const handleTab = (element: AccessControlTabType) => {
    setTabState(element);
  };
  const tab: Record<AccessControlTabType, JSX.Element> = {
    yourPass: <YourPass />,
    checkInActivity: <CheckInActivity />,
  };

  const { data, loading } = useQuery<getEventPreferences>(
    GetEventPreferenceById,
    {
      variables: {
        eventId: event?.uuid,
      },
    }
  );

  return (
    <DashboardLayout pageName="Access control">
      {!isLuxeEvent ? (
        <ActivationSteps />
      ) : (
        <div className="h-full w-full">
          {loading ? (
            <InnerLoading />
          ) : (
            <div className="h-full w-full">
              {" "}
              {!data?.event_preferences?.access_control ? (
                <ActivationScreen />
              ) : (
                <div className={styles["invitations"]}>
                  <div className={styles["invitations__tabs"]}>
                    <Tab
                      tabItems={tabItems}
                      tabState={tabState}
                      handleTab={handleTab}
                    />
                  </div>
                  <div className={styles["invitations__main"]}>
                    {tab[tabState]}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};

export default Index;
