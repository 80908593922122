import { useMutation } from "@apollo/client";
import { Form, Formik, FormikProps } from "formik";
import { Dispatch, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Textarea from "src/components/Textarea";
import { UpdateGuestCategory } from "src/graphql/mutations";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import {
  noGuestPerCategory_noGuestPerCategory,
  updateGuestCategory,
  updateGuestCategoryVariables,
} from "src/types/api";
import * as Yup from "yup";
import styles from "./styles.module.scss";

interface Values {
  message: string;
}

const SetMessagesFormSchema = Yup.object().shape({
  message: Yup.string().required("Category message is required"),
});

interface SetMessagesProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  category: noGuestPerCategory_noGuestPerCategory;
}

const SetMessages = ({ setOpen, category }: SetMessagesProps) => {
  const [messageDisplay, setMessageDisplay] = useState<string>(
    category.message ?? ""
  );
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [updateGuestCategoryFn, { loading }] = useMutation<
    updateGuestCategory,
    updateGuestCategoryVariables
  >(UpdateGuestCategory, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">
          You have successfully updated the {category.category_name} category
          message.
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const handleOnChange = (event: any) => {
    if (event.target.name === "message") {
      setMessageDisplay(event.target.value);
    }
  };

  return (
    <div className={`${styles["setMessageModal--content"]}`}>
      <h1> Set Personalized Message </h1>
      <Formik
        initialValues={{
          message: category.message || "",
        }}
        validationSchema={SetMessagesFormSchema}
        onSubmit={(values) => {
          updateGuestCategoryFn({
            variables: {
              eventId: event.id,
              categoryId: category.category_id,
              name: category.category_name,
              message: values.message,
            },
            refetchQueries: [
              {
                query: NumberOfGuestsByCategory,
                variables: { eventId: event.id },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form
            onChange={handleOnChange}
            className={styles["setMessageModal--content-form"]}
          >
            <p>Here's what your invite message will look like:</p>
            <br></br>

            <h5>
              Hi [Insert Name],
              <br></br>
              {messageDisplay !== "" || props.values.message === ""
                ? messageDisplay
                : "Your message goes here."}
              <br></br>
              Click the link below to accept their invitation.
            </h5>
            <br></br>

            <div className={styles["setMessageModal--content-form_container"]}>
              <Textarea
                name="message"
                label=""
                rows={8}
                placeholder="Set a message for this category"
              />
            </div>
            <div className={styles["setMessageModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Save Message"
                  disabled={props.values.message === ""}
                  loader={loading}
                >
                  Save Message
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SetMessages;
