import { FC, useState } from "react";
import NoRSVPPending from "src/assets/icons/NoRSVPPending";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
import { noGuestPerCategory } from "src/types/api";
import AddGuest from "../../../invitations/invite-list/modals/AddGuest";
import styles from "./styles.module.scss";

interface Props {
  categories: noGuestPerCategory | undefined;
  page: number;
  pageSize: number;
}

const EmptyState: FC<Props> = ({ categories, page, pageSize }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        <Icon iconName="bulb" />

        <p className={styles["EmptyState--title"]}>
          Pending shows the guests who haven't responded to your invitation.
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        <NoRSVPPending />

        <p>Your guests haven't reserved a spot at your event yet.</p>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["EmptyState--main__trigger"]}
            >
              <span>Add Guest</span>
            </div>
          }
        >
          <AddGuest
            categories={categories}
            page={page}
            pageSize={pageSize}
            setOpen={setOpen}
          />
        </DialogModal>
      </div>
    </div>
  );
};

export default EmptyState;
