import { useQuery } from "@apollo/client";
import { memo, useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Backdrop from "src/assets/images/bgImage.svg";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import WebsitePreview from "src/components/WebsitePreview";
import { AppContext, ValueProps } from "src/context";
import { GetGeneralInviteToken } from "src/graphql/queries/invitations.queries";
import DeactivateIcon from "src/layouts/dashboard/Icons/DeactivateIcon";
import InstagramIcon from "src/layouts/dashboard/Icons/InstagramIcon";
import useAppStore from "src/store/utils";
import {
  getGeneralInviteToken,
  getGeneralInviteTokenVariables,
} from "src/types/api";
import { copyToClipboard } from "src/utils";
import styles from "./shareInviteCard.module.scss";

interface ShareInviteCardInterface {
  image?: string;
  setToggleModal: (modalActiveStates: boolean) => void;
  customLink?: string;
  customTitle?: string;
  customDescription?: string;
  preview?: boolean;
}

const ShareWithBouncerModal = ({
  image,
  setToggleModal,
  customLink,
  customTitle,
  preview,
  customDescription,
}: ShareInviteCardInterface) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventName = contextValue?.eventName;
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: inviteToken, loading: inviteTokenLoading } = useQuery<
    getGeneralInviteToken,
    getGeneralInviteTokenVariables
  >(GetGeneralInviteToken, {
    skip: !event.id,
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const shareDescription = useMemo(() => {
    const text = `Hello there, ${eventName} invite you to share in their happiness as they walk down the aisle. The couple would love you to be a part of their big day and we’re here to make that happen. Click the link attached to respond to their invitation, have fun, and engage on their wedding website.`;

    return text;
  }, [eventName]);

  return (
    <div className={styles["shareInviteCard"]}>
      <div className={styles["shareInviteCard__heading"]}>
        <h4>Share bouncer link</h4>
      </div>
      <div className={styles["shareInviteCard__subHeading"]}>
        <span>
          Share this link with the people allowing guests into your event venue
        </span>
      </div>
      {inviteTokenLoading ? (
        <div className={styles["shareInviteCard__invitationCon"]}>
          <InnerLoading />
        </div>
      ) : (
        <>
          <div
            className={
              preview
                ? styles["shareInviteCard__invitationCon--isPreview"]
                : styles["shareInviteCard__invitationCon"]
            }
          >
            {preview ? (
              <WebsitePreview
                url={`${
                  customLink ??
                  `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
                }`}
              />
            ) : (
              <LazyLoadImage
                src={image}
                placeholderSrc={Backdrop}
                alt="invitationCard"
                effect="blur"
                className={styles["shareInviteCard__invitationCon__img"]}
              />
            )}
          </div>
          <div className={`mt-4 ${styles["shareInviteCard__linksCon"]}`}>
            <div
              className={styles["shareInviteCard__linksCon__buttons"]}
              onClick={() =>
                copyToClipboard(
                  `${
                    customLink ??
                    `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
                  }`,
                  <p className="toast">
                    {customTitle ?? `Invite`} link has been copied
                  </p>
                )
              }
            >
              <Icon iconName="chainLink" />
              <span>Copy Link</span>
            </div>
            <FacebookShareButton
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
              quote={shareDescription}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="facebook" />
                <span>Facebook</span>
              </div>
            </FacebookShareButton>
            <TwitterShareButton
              title={shareDescription}
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="twitter" />
                <span>Twitter</span>
              </div>
            </TwitterShareButton>
            <InstapaperShareButton
              title={shareDescription}
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <InstagramIcon />
                <span>Instagram</span>
              </div>
            </InstapaperShareButton>
            <WhatsappShareButton
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
              title={shareDescription}
              separator={` `}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="whatsapp" />
                <span>Whatsapp</span>
              </div>
            </WhatsappShareButton>
            <EmailShareButton
              title={eventName}
              body={shareDescription}
              separator={` `}
              url={`${
                customLink ??
                `${process.env.REACT_APP_INAWO_BASE_URL}/${process.env.REACT_APP_INAWO_INVITE_BASE_URL}?key=${inviteToken?.getGeneralInviteToken}`
              }`}
            >
              <div className={styles["shareInviteCard__linksCon__buttons"]}>
                <Icon iconName="email" />
                <span>Email</span>
              </div>
            </EmailShareButton>
          </div>
        </>
      )}
      <div className="mb-[24px] mt-[10px] h-[1px] w-full bg-[#E4E4E7]"></div>
      <Button
        label="Deactivate"
        type="button"
        design="secondary"
        className="mx-auto whitespace-nowrap !text-[12px] sm:!text-[14px]"
        style={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          width: "202px",
          margin: "auto",
          marginBottom: 16,
        }}
      >
        <div className="flex items-center gap-2">
          <DeactivateIcon />
          <p>Deactivate</p>
        </div>
      </Button>
      <p className="text-center text-[12px] text-[#52525B]">
        It deactivates and resets this current link. If you want to share again,
        copy the new link
      </p>
    </div>
  );
};

export default memo(ShareWithBouncerModal);
