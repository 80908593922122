import { Dispatch, useState } from "react";
import toast from "react-hot-toast";
import PinInput from "react-pin-input";
import Button from "src/components/Button";
import ConfirmPinForm from "./ConfirmPinForm";
import styles from "./resetPin.module.scss";

interface ResetPinProps {
  setOpenResetPin: Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  passcode: any;
}

export type setPinModalType = "resetPin" | "confirmPin";

const ResetPin = ({ setOpenResetPin, eventId, passcode }: ResetPinProps) => {
  const [modalActiveState, setModalActiveState] =
    useState<setPinModalType>("resetPin");

  const [pin, setPin] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (pin.length === 4) {
      setModalActiveState("confirmPin");
    } else {
      toast.error(<p className="toast">PIN is too short!</p>);
    }
  };

  const ResetPinForm = (
    <div className={styles["resetPin__main"]}>
      <div className={styles["resetPin__main-container"]}>
        <div className={styles["resetPin__title"]}>
          <h1>Input New PIN</h1>
        </div>
        <form
          onSubmit={handleSubmit}
          className={styles["resetPin__field-form"]}
        >
          <PinInput
            length={4}
            focus
            initialValue=""
            secret
            onChange={(value, index) => {
              setPin(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "12px",
              display: " flex",
              justifyContent: " center",
              alignItems: " center",
              gap: "0.5rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            inputStyle={{
              borderColor: "#808080",
              width: "17.5%",
              minWidth: "2.5rem",
              height: "78px",
              fontSize: "2.25rem",
              borderRadius: "2px",
            }}
            inputFocusStyle={{
              borderColor: "#1fad3e",
              caretColor: "#1fad3e",
            }}
            onComplete={(value, index) => {
              if (value.length === 4) {
                setModalActiveState("confirmPin");
              }
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <div className={styles["resetPin__button"]}>
            <Button type="submit" label="Proceed">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );

  const modal: Record<setPinModalType, JSX.Element> = {
    resetPin: ResetPinForm,
    confirmPin: (
      <ConfirmPinForm
        setOpenResetPin={setOpenResetPin}
        eventId={eventId}
        pin={pin}
        passcode={passcode}
      />
    ),
  };

  return <div>{modal[modalActiveState]}</div>;
};

export default ResetPin;
