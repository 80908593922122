import { useRef } from "react";
import useKeyPress from "src/hooks/useKeyPress";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import Button from "../Button";
import styles from "./deleteModal.module.scss";

interface DeleteModalProps {
  title: string;
  message?: string;
  handleGoBack: () => void;
  handleDelete?: () => void;
  id?: number;
  loading?: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  title,
  message,
  handleDelete,
  handleGoBack,
  loading,
}) => {
  const ref = useRef(null);
  const escPress: boolean = useKeyPress(27);

  if (escPress) {
    handleGoBack();
  }

  useOnClickOutside(ref, handleGoBack);

  return (
    <div className={styles["deleteModal__message"]} ref={ref}>
      <div className={styles["deleteModal__message-container"]}>
        <div className={styles["deleteModal__message-title"]}>
          <h4>{title}</h4>
          <p>{Boolean(message) && message}</p>
        </div>
        <div className={styles["deleteModal__message-buttons"]}>
          <div className={styles["deleteModal__message-button"]}>
            <Button
              loader={loading}
              onClick={handleDelete}
              type="button"
              label="back"
            >
              Yes, Proceed
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
