import { useMutation } from "@apollo/client";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { Dispatch, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Icon from "src/components/Icon";
import { SearchInput } from "src/components/Input";
import { DeleteGuest } from "src/graphql/mutations";
import { InvitationListByCategory } from "src/graphql/queries/invitations.queries";
import useAppStore from "src/store/utils";
import {
  invitationListByCategory,
  noGuestPerCategory_noGuestPerCategory,
  removeFromInvitationList,
} from "src/types/api";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.scss";

interface ViewGuestsProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  category: noGuestPerCategory_noGuestPerCategory;
  guestList: invitationListByCategory | undefined;
}

const ViewGuests = ({ category, setOpen, guestList }: ViewGuestsProps) => {
  const [searchValue, setSearchValue] = useState("");
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const results =
    guestList?.invitationListByCategory?.filter(
      (guest) =>
        guest.first_name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        guest.last_name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
    ) ?? [];

  const [removeFromInvitationListFn, { loading }] =
    useMutation<removeFromInvitationList>(DeleteGuest, {
      onCompleted() {
        toast.success(
          <p className="toast">
            You have successfully removed the guest from the invite list.
          </p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  return (
    <div className={`${styles["viewGuestsModal--content"]}`}>
      <div className={`${styles["viewGuestsModal--content-title"]}`}>
        <h1> {category?.category_name} </h1>
        <p>
          {" "}
          <Icon iconName="users" />
          {guestList?.invitationListByCategory.length}
        </p>
      </div>
      <SearchInput
        icon={<Icon iconName="search" />}
        name="Search Guests"
        placeholder="Search Guests"
        value={searchValue}
        onChange={(e: { target: { value: SetStateAction<string> } }) =>
          setSearchValue(e.target.value)
        }
      />
      <ScrollArea.Root className={styles["viewGuestsModal--content__area"]}>
        <ScrollArea.Viewport
          className={styles["viewGuestsModal--content__list"]}
        >
          {!!guestList &&
          !!guestList?.invitationListByCategory.length &&
          results.length &&
          guestList?.invitationListByCategory.length > 0 ? (
            results.map((guest) => (
              <div
                key={uuidv4()}
                className={styles["viewGuestsModal--content__list__item"]}
              >
                <p>
                  {guest?.first_name} {guest?.last_name}
                </p>

                <button
                  onClick={() =>
                    removeFromInvitationListFn({
                      variables: {
                        event_id: event.id,
                        invitation_list_ids: [guest.id],
                      },
                      refetchQueries: [
                        {
                          query: InvitationListByCategory,
                          variables: {
                            eventId: event.id,
                            categoryId: guest.category_id,
                            page: 1,
                            pageSize: 100,
                          },
                        },
                      ],
                    })
                  }
                  disabled={loading}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p className={styles["viewGuestsModal--content__none"]}>
              {searchValue !== ""
                ? "No guests found"
                : "You've not added guests to this category"}
            </p>
          )}
        </ScrollArea.Viewport>
        {/* <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar> */}
        <ScrollArea.Scrollbar
          className={styles["viewGuestsModal--content__list__scrollbar"]}
          orientation="vertical"
        >
          <ScrollArea.Thumb
            className={styles["viewGuestsModal--content__list__thumb"]}
          />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner />
      </ScrollArea.Root>
    </div>
  );
};

export default ViewGuests;
