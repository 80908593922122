import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { DeleteLiveSchedule } from "src/graphql/mutations/event.mutation";
import { GetLiveSchedules } from "src/graphql/queries/event.queries";
import useAppStore from "src/store/utils";
import {
  deleteLiveSchedule,
  deleteLiveScheduleVariables,
  getLiveSchedule_getLiveSchedule_data,
} from "src/types/api";

interface IDeleteSchedule {
  close: () => void;
  schedule: getLiveSchedule_getLiveSchedule_data;
}

const DeleteSchedule = ({ close, schedule }: IDeleteSchedule) => {
  const navigate = useNavigate();
  const { event } = useAppStore((state) => state);

  const [deleteSchedule, { loading: deleteScheduleLoading }] = useMutation<
    deleteLiveSchedule,
    deleteLiveScheduleVariables
  >(DeleteLiveSchedule, {
    onCompleted: (res) => {
      close();
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className="flex h-screen w-screen flex-col gap-[1.5rem] rounded-[0.5rem] bg-white pt-[2.5rem] md:h-max md:min-h-[5.8rem] md:max-w-[32.5rem]">
      <div className="mb-[1.5rem] flex flex-col gap-4 px-[2.5rem]">
        <h3 className="text-[1.25rem] font-[600] leading-[1.5rem] text-primary-1">
          Delete Schedule
        </h3>
        <p className="text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem] text-dark-1">
          Are you sure you want to delete “{schedule?.event_part?.name}” stream
          from your schedule?{" "}
        </p>
      </div>
      <div className="flex w-full items-center justify-end gap-2 bg-secondary-5 px-[2.5rem] py-[1.5rem]">
        <Button
          onClick={() => close()}
          type="button"
          label="Never Mind"
          className="w-max bg-white"
          wrapperBtnClassName="w-max"
          design="secondary"
        >
          Never Mind
        </Button>
        <Button
          onClick={() => {
            deleteSchedule({
              variables: {
                deleteLiveScheduleId: schedule.id,
              },
              refetchQueries: [
                {
                  query: GetLiveSchedules,
                  variables: {
                    eventUuid: event.uuid,
                    page: 1,
                    pageSize: 50,
                  },
                },
              ],
            });
          }}
          type="button"
          label="Yes, Delete"
          className="w-max"
          wrapperBtnClassName="w-max"
          design="tertiary"
          loader={deleteScheduleLoading}
        >
          Yes, Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteSchedule;
