import React from "react";
import Icon from "src/components/Icon";
import { cn } from "src/lib/utils";

const Setup = ({ addLiveEvent }: { addLiveEvent: () => void }) => {
  const handleGoToSetupYoutubeChannel = () => {
    const youtubeChannelUrl = process.env.REACT_APP_YOUTUBE_CHANNEL_SETUP_LINK;
    window.open(youtubeChannelUrl, "_blank");
  };

  return (
    <div>
      <div className="mx-auto flex w-full gap-2 sm:w-[32.6rem]">
        <div className="grid h-6 w-6 place-items-center rounded-full border border-secondary-6 sm:h-8 sm:w-8">
          <p className="text-base font-medium tracking-[0.12px]">1</p>
        </div>
        <div className="flex w-full flex-1 flex-col gap-[1.5rem] sm:w-[27.8rem] sm:pt-[5px]">
          <div className="flex items-center gap-3">
            <div>
              <h4 className="text-black text-[17px] leading-[22px] tracking-[0.32px]">
                Capture{" "}
                <span className="text-black-5">and stream using youtube</span>
              </h4>
            </div>
          </div>
          <SetupItemWrapper
            onClick={() => handleGoToSetupYoutubeChannel()}
            className="flex flex-col items-center justify-between gap-2 transition-colors duration-300 ease-in-out hover:bg-primary-5/10 sm:flex-row"
          >
            <div className="flex items-center gap-[13px]">
              <Icon iconName="greenYoutube" />
              <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
                Open a YouTube channel
              </span>
            </div>
            <button className="flex w-full items-center justify-center gap-2.5 rounded-[6px] border border-solid border-[#E8E8EB] px-[18px] py-[5px] text-[13px] leading-[20px] -tracking-[0.261px] text-[#27272A] sm:w-fit">
              Open
              <Icon iconName="sm-arrow-angled" />
            </button>
          </SetupItemWrapper>
          <SetupItemWrapper className="flex flex-col gap-[0.5rem]">
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Tap on the video Icon and tap the “Go Live” button
            </span>
            <span className="text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem] text-secondary-3">
              This is located on the top right corner of your screen
            </span>
            <div className="mt-4 flex w-full justify-center">
              <Icon iconName="youtubeLiveDemo" />
            </div>
          </SetupItemWrapper>
          <SetupItemWrapper className="flex flex-col gap-[0.5rem]">
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Request for Access
            </span>
            <span className="text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem] text-secondary-3">
              You would be notified to “Request Access”
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Verify your Phone Number
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              After 24 hours, you get access to your livestream 🎉
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Schedule streams on youtube that match your live days
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Note that you’ll stream with youtube on the d day
            </span>
          </SetupItemWrapper>
        </div>
      </div>
      <div className="mx-auto mt-[55px] flex w-full gap-2 sm:w-[32.6rem]">
        <div className="grid h-6 w-6 place-items-center rounded-full border border-secondary-6 sm:h-8 sm:w-8">
          <p className="text-base font-medium tracking-[0.12px]">2</p>
        </div>
        <div className="flex w-full flex-1 flex-col gap-[1.5rem] sm:w-[27.8rem] sm:pt-[5px]">
          <div className="flex items-center gap-3">
            <div>
              <h4 className="text-black text-[17px] leading-[22px] tracking-[0.32px]">
                Connect youtube
                <span className="text-black-5"> to your Inawo live hall</span>
              </h4>
            </div>
          </div>
          <SetupItemWrapper
            className="flex flex-col items-center justify-between gap-2 transition-colors duration-300 ease-in-out hover:bg-primary-5/10 sm:flex-row"
            onClick={() => addLiveEvent()}
          >
            <div className="flex flex-col gap-2.5">
              <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
                Add days on Inawo that match youtube
              </span>
              <span className="-leaing-[0.281px] text-[14px] font-[400] text-secondary-3">
                And connect to the scheduled youtube stream
              </span>
            </div>
            <button className="w-full rounded-[6px] border border-solid border-[#E8E8EB] px-[18px] py-[5px] text-[13px] leading-[20px] -tracking-[0.261px] text-[#27272A] sm:w-fit">
              Add a day
            </button>
          </SetupItemWrapper>
          <SetupItemWrapper
            className="flex flex-col items-center justify-between gap-2 transition-colors duration-300 ease-in-out hover:bg-primary-5/10 sm:flex-row"
            onClick={() => addLiveEvent()}
          >
            <div className="flex items-center gap-[13px]">
              <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
                Send your live hall link to your guests
              </span>
            </div>
            <button className="w-full rounded-[6px] border border-solid border-[#E8E8EB] px-[18px] py-[5px] text-[13px] leading-[20px] -tracking-[0.261px] text-[#27272A] sm:w-fit">
              Send link
            </button>
          </SetupItemWrapper>
        </div>
      </div>
    </div>
  );
};

export default Setup;

const SetupItemWrapper = ({
  className,
  onClick,
  children,
}: {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div
      onClick={() => onClick?.()}
      className={cn(
        "cursor-pointer rounded-[0.5rem] border border-secondary-6 bg-secondary-5 px-4 py-[0.75rem]",
        className
      )}
    >
      {children}
    </div>
  );
};
