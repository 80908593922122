import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Backdrop from "src/assets/images/bgImage.svg";
import Icon from "src/components/Icon";
import TemplateCard from "./TemplateCard";
import styles from "./templates.module.scss";
// import Button from "src/components/Button";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import InnerLoading from "src/components/InnerLoading";
import useAppStore from "src/store/utils";

type OrientationType = "portrait" | "landscape";

const Templates = () => {
  const [orientation, setOrientation] = useState<OrientationType>("landscape");
  const { event } = useAppStore((state) => state);

  return (
    <div className={styles["templates__container"]}>
      <div className={styles["templates"]}>
        <div className={styles["templates__main"]}>
          <div className={styles["templates__main-content"]}>
            <div className={styles["templates__main-template"]}>
              <div className={styles["templates__main-template-title"]}>
                <h5>Select Template</h5>
              </div>
              <div className={styles["templates__main-template-container"]}>
                <TemplateCard image="" title="Style 1" />
              </div>
            </div>

            <div className={styles["templates__main-preview"]}>
              <div className={styles["templates__backdrop"]}>
                <LazyLoadImage
                  src={Backdrop}
                  placeholderSrc={Backdrop}
                  alt="Backdrop"
                  effect="blur"
                  width="100%"
                  height="100%"
                  className={styles["templates__backdrop__image"]}
                />
              </div>

              <div className={styles["templates__main-preview__top"]}>
                <h2>Preview</h2>

                <div className={styles["templates__main-views"]}>
                  <div className={styles["templates__main-views-box"]}>
                    <button
                      onClick={() => setOrientation("landscape")}
                      className={`${styles["templates__main-views-orientation"]} ${
                        orientation === "landscape" &&
                        styles["templates__main-views-orientation__active"]
                      }`}
                    >
                      <Icon iconName="landscape" />
                    </button>
                    <button
                      onClick={() => setOrientation("portrait")}
                      className={`${styles["templates__main-views-orientation"]} ${
                        orientation === "portrait" &&
                        styles["templates__main-views-orientation__active"]
                      }`}
                    >
                      <Icon iconName="potrait" />
                    </button>
                  </div>
                </div>
                {/* <div className={styles["templates__alert-save-button"]}>
                  <Button type="button" label="select template">
                    Select Template
                  </Button>
                </div> */}
              </div>

              <ScrollArea.Root
                className={styles["templates__main-preview-box-container"]}
              >
                <ScrollArea.Viewport
                // className={styles["templates__main-preview-box-container"]}
                >
                  <div
                    className={` ${styles["templates__main-preview-box"]} ${
                      orientation === "landscape" &&
                      styles["templates__main-preview-box__landscape"]
                    } ${
                      orientation === "portrait" &&
                      styles["templates__main-preview-box__portrait"]
                    }`}
                  >
                    <div
                      className={
                        styles["templates__main-preview-box__showcase"]
                      }
                    >
                      {!event?.slug ? (
                        <div className="flex h-full w-full items-center justify-center">
                          <InnerLoading />
                        </div>
                      ) : (
                        <iframe
                          src={`https://${event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}/`}
                          style={{
                            width:
                              orientation === "landscape" ? "400px" : "300px",
                            height: "57vh",
                          }}
                        ></iframe>
                      )}

                      {/* <LazyLoadImage
                        src={orientation === "landscape" ? ImgTem : ImgTem2}
                        placeholderSrc={
                          orientation === "landscape" ? ImgTem : ImgTem2
                        }
                        alt="Backdrop"
                        effect="blur"
                        width="100%"
                        height="100%"
                      /> */}
                    </div>
                  </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                  className={styles["templates__main-preview-box__scrollbar"]}
                  orientation="horizontal"
                >
                  <ScrollArea.Thumb />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                  className={styles["templates__main-preview-box__scrollbar"]}
                  orientation="vertical"
                >
                  <ScrollArea.Thumb
                    className={styles["templates__main-preview-box__thumb"]}
                  />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner />
              </ScrollArea.Root>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
