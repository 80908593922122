import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import DialogModal from "src/components/Dialog";
import { InawoGreenLuxeIcon } from "src/components/Icons";
import { formatMoney } from "src/helper/functions";
import PaymentFlow from "../../Modals/paymentFlow";
import styles from "./styles.module.scss";

interface StepProps {
  step: number;
  setStep: (value: number) => void;
  discounted_cost: number | undefined;
  undiscounted_cost: number | undefined;
  currency: string | undefined;
}

const Index = ({
  step,
  setStep,
  discounted_cost,
  undiscounted_cost,
  currency,
}: StepProps) => {
  const [openPaymentFlow, setOpenPaymentFlow] = React.useState<boolean>(false);
  return (
    <div className={styles.container}>
      <InawoGreenLuxeIcon />
      <img src="/inawo-luxe-features.png" alt="Inawo luxe features image" />
      <div className={styles.header__text}>
        <h3>Inawo Luxe</h3>
        <p>for premium weddings</p>
      </div>
      <h3>
        goes for{" "}
        <span className={styles.actual__price}>
          {getSymbolFromCurrency(currency ?? "")}
          {formatMoney(undiscounted_cost ?? 0)}
        </span>
        {"  "}
        <span className={styles.discount__price}>
          {getSymbolFromCurrency(currency ?? "")}
          {formatMoney(discounted_cost ?? 0)}
        </span>
      </h3>
      <p>Comes with</p>
      <div className={styles.features__container}>
        <div className={styles.feature}>
          <img src="/mobile-phone.png" alt="mobile phone icon" />
          <p>
            Access control{" "}
            <span>
              make your wedding private and classy with Inawo pass
            </span>{" "}
          </p>
        </div>
        <div className={styles.feature}>
          <img src="/mobile-phone.png" alt="mobile phone icon" />
          <p>
            Custom website{" "}
            <span>
              make your wedding unique by picking a bespoke look and feel
            </span>
          </p>
        </div>
      </div>
      <div className="align-center flex flex-col gap-[16px]">
        <DialogModal
          open={openPaymentFlow}
          onOpenChange={setOpenPaymentFlow}
          trigger={
            <button
              className="mx-auto w-auto rounded-[10px] bg-[#34544F] px-[20px] py-[15px] font-medium text-[#ffffff]"
              type="button"
              onClick={() => setOpenPaymentFlow(true)}
            >
              Pay {getSymbolFromCurrency(currency ?? "")}
              {formatMoney(discounted_cost ?? 0)} to Activate
            </button>
          }
        >
          <PaymentFlow setOpen={setOpenPaymentFlow} />
        </DialogModal>
        <button
          type="button"
          style={{
            background: "none",
            border: "2px solid #9C9C9C",
            padding: "15px 20px",
            borderRadius: "10px",
            width: "220px",
            margin: "auto",
            color: "#292D2D",
          }}
          onClick={() => setStep(step - 1)}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Index;
