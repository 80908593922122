import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import InnerLoading from "src/components/InnerLoading";
import {
  FilterInvitationList,
  GetEventPreferenceById,
  NumberOfGuestsByCategory,
} from "src/graphql/queries";
import useAppStore from "src/store/utils";
import {
  filterInvitationListBy,
  filterInvitationListByVariables,
  getEventPreference,
  invitationListFilter,
  noGuestPerCategory,
} from "src/types/api.d";
import EmptyState from "./EmptyState";
import GuestList from "./components/GuestList/GuestList";
import Header from "./components/Header/Header";

const Activity = () => {
  const [page] = useState(1);
  const { event } = useAppStore((state) => state);
  const [pageSize] = useState(500);
  const [searchValue, setSearchValue] = useState("");
  const [, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filter] = useState<invitationListFilter>(
    invitationListFilter.all ||
      invitationListFilter.not_sent ||
      invitationListFilter.sent
  );

  const { data: inviteList, loading: invitationListLoading } = useQuery<
    filterInvitationListBy,
    filterInvitationListByVariables
  >(FilterInvitationList, {
    variables: {
      eventId: event.id,
      filter: filter,
      page: page,
      pageSize: pageSize,
    },
    skip: !event.id,
    fetchPolicy: "network-only",
    onCompleted(response) {
      if (
        !!response?.filterInvitationListBy &&
        response?.filterInvitationListBy.length
      ) {
        setTotalPages(
          Math.floor(response?.filterInvitationListBy.length / pageSize)
        );
      }
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const { data: eventPreferences, loading: eventPreferencesLoading } =
    useQuery<getEventPreference>(GetEventPreferenceById, {
      variables: {
        eventId: event.uuid,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const results = useMemo(() => {
    return (
      inviteList?.filterInvitationListBy?.filter(
        (guest) =>
          guest?.first_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          guest?.last_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      ) ?? []
    );
  }, [searchValue, inviteList]);

  const { data: categories, loading: categoryListLoading } =
    useQuery<noGuestPerCategory>(NumberOfGuestsByCategory, {
      variables: {
        eventId: event.id,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const guestCount = useMemo(() => {
    const res = categories?.noGuestPerCategory?.reduce((acc, curr) => {
      if (curr?.num_guest) {
        return acc + curr?.num_guest;
      } else {
        return acc;
      }
    }, 0);
    return res;
  }, [categories]);

  const csvData = results?.map((guest: any) => ({
    first_name: guest.first_name,
    last_name: guest.last_name,
    phone: guest.phone,
    email: guest.email,
    category_name: guest.category?.name || "",
    rsvp_status:
      guest.rsvp_status === "yes" ? "Rsvp Confirmed" : "Not Responded",
  }));

  return (
    <div className="flex flex-col items-center px-5 lg:px-0">
      {results?.length > 0 ? (
        <div className="flex w-full flex-col items-center gap-[47px] pb-[50px]">
          <Header guestCount={guestCount} guestsData={csvData} />
          {invitationListLoading ||
          categoryListLoading ||
          eventPreferencesLoading ? (
            <InnerLoading />
          ) : (
            <GuestList
              guestList={results}
              accessControl={
                eventPreferences?.event?.event_preference?.access_control
              }
            />
          )}
        </div>
      ) : (
        <EmptyState />
      )}
      {/* <div className="absolute h-[249px] w-[274px] bg-[#7D918D80] blur-[105px]"></div>
      <div>
        <Icon iconName="activityComingSoon" />
      </div>
      <div className="mt-[38px] w-max rounded-[20px] bg-primary-6 px-5 py-2.5">
        <h4 className="text-sm font-medium tracking-[0.84px] text-white">
          COMING SOON
        </h4>
      </div>
      <div className="mt-[30px] max-w-[250px]">
        <p className="text-center text-base text-[#707070]">
          We’ll be showing all your guests activity here, coming very soon{" "}
        </p>
      </div> */}
    </div>
  );
};
export default Activity;
