import { useMutation } from "@apollo/client";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import DialogModal from "src/components/Dialog";
import { InawoPassSuccessIcon } from "src/components/Icons";
import { Spinner } from "src/components/Spinner";
import { UpdateEventPreference } from "src/graphql/mutations";
import { GetMedia as getMedia } from "src/graphql/queries";
import useCustomQuery from "src/hooks/useCustomQuery";
import DeactivateIcon from "src/layouts/dashboard/Icons/DeactivateIcon";
import useAppStore from "src/store/utils";
import { GetMedia } from "src/types/api";
import { updateEventPreferenceVariables } from "src/types/api.d";
import SelectPassImageModal from "../../Modals/SelectPassImageModal";
import ShareWithBouncerModal from "../../Modals/ShareWithBouncerModal";

const Header = ({ allPassSent }: { allPassSent: boolean }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [shareWithBouncer, setShareWithBouncer] = useState<boolean>(false);
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [updateEventPreference, { loading }] =
    useMutation<updateEventPreferenceVariables>(UpdateEventPreference, {
      onCompleted() {
        setOpen(false);
        toast.success("Access control deactivated successfully");
        // navigate(0);
      },
      onError(error) {
        setOpen(false);
        toast.error(
          <p className="toast">{error?.message ?? "An error occurred"}</p>
        );
      },
    });

  const { data: images, isLoading: getMediaLoading } = useCustomQuery<GetMedia>(
    getMedia,
    {
      eventId: event.uuid,
      filter: { page: 1, pageSize: 200 },
    },
    !event.uuid
  );

  return (
    <div className="mx-auto flex w-[90%] flex-wrap items-center justify-between gap-[25px] lg:w-full lg:gap-0">
      <div className="flex items-center gap-[20px]">
        <h2 className="text-[12px] font-semibold tracking-[3px] text-[#00000099]">
          YOUR INAWO PASS
        </h2>
        {allPassSent && (
          <button
            style={{ border: "1px solid #66666666" }}
            className="flex items-center justify-center rounded-[5px] px-[13px] py-[7px]"
          >
            <InawoPassSuccessIcon />
          </button>
        )}
      </div>
      <div className="flex flex-wrap items-center gap-[6px] lg:flex-nowrap">
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <Button
              label="Set Image"
              type="button"
              design="secondary"
              className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
              style={{ height: "36px" }}
            >
              Set Image
            </Button>
          }
        >
          <SelectPassImageModal
            data={images?.getMedia ?? []}
            loading={getMediaLoading}
            setOpen={setOpen}
          />
        </DialogModal>
        <DialogModal
          open={shareWithBouncer}
          onOpenChange={setShareWithBouncer}
          trigger={
            <Button
              label="Set Image"
              type="button"
              design="secondary"
              className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
              style={{ height: "36px" }}
              disabled={true}
            >
              Share with bouncer
            </Button>
          }
        >
          <ShareWithBouncerModal setToggleModal={setShareWithBouncer} />
        </DialogModal>
        <Button
          label="Deactivate"
          type="button"
          design="secondary"
          className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
          style={{ height: "36px", display: "flex", alignItems: "center" }}
          onClick={() => {
            updateEventPreference({
              variables: {
                input: {
                  event_id: event.uuid,
                  access_control: false,
                },
              },
            });
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <div className="flex items-center gap-2">
              <DeactivateIcon />
              <p>Deactivate</p>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export default Header;
