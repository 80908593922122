import moment from "moment";
import { useContext, useMemo } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Icon from "src/components/Icon";
import { AppContext, ValueProps } from "src/context";
import { copyToClipboard } from "src/utils";

interface IShareLiveSchedule {
  scheduleName?: string;
  link?: string;
  scheduleDate?: string;
}

const ShareLiveSchedule = ({
  link,
  scheduleName,
  scheduleDate,
}: IShareLiveSchedule) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventName = contextValue?.eventName;

  const shareDescription = useMemo(() => {
    const text = `Hello there, ${eventName} invite you to the live stream of thier ${scheduleName} on ${moment(
      scheduleDate
    ).format("lll")}`;

    return text;
  }, [eventName]);

  return (
    <div className="relative flex h-screen w-screen flex-col gap-[1.5rem] overflow-hidden bg-primary-6 p-[2.5rem] md:h-max md:max-w-[32.5rem] md:rounded-[0.5rem]">
      <div className="absolute bottom-0 left-0">
        <Icon iconName="liveScheduleShareBgAssetBL" />
      </div>
      <div className="absolute right-0 top-0">
        <Icon iconName="liveScheduleShareBgAssetTR" />
      </div>
      <h2 className="mb-4 text-center text-[1rem] font-[600] leading-[1.5rem] text-white md:text-[1.25rem]">
        {scheduleName}
      </h2>
      <div className="flex w-full items-center justify-between gap-2 rounded-[0.5rem] bg-white p-2 md:px-[1.56rem] md:py-4">
        <span className="truncate text-ellipsis whitespace-nowrap text-[12px] leading-[1.625rem] tracking-[-0.0225rem] text-dark-1 md:text-[1rem]">
          {link}
        </span>
        <div
          onClick={() => {
            copyToClipboard(
              `${link}`,
              <p className="toast">{scheduleName} link has been copied</p>
            );
          }}
          className="grid h-[2.5rem] min-w-[2.5rem] cursor-pointer place-items-center rounded-[2.5rem] hover:bg-slate-100"
        >
          <Icon iconName="copyText" />
        </div>
      </div>
      <div className="flex w-full items-center justify-center gap-2 md:gap-4">
        <TwitterShareButton title={shareDescription} url={`${link}`}>
          <div className="grid h-[2.875rem] w-[2.875rem] cursor-pointer place-items-center rounded-[2.5rem] border border-white/[0.12] text-warning-1 transition-colors duration-300 ease-in-out hover:bg-white/10">
            <Icon iconName="twitterNaked" />
          </div>
        </TwitterShareButton>
        <FacebookShareButton url={`${link}`} quote={shareDescription}>
          <div className="grid h-[2.875rem] w-[2.875rem] cursor-pointer place-items-center rounded-[2.5rem] border border-white/[0.12] text-warning-1 transition-colors duration-300 ease-in-out hover:bg-white/10">
            <Icon iconName="facebookNaked" />
          </div>
        </FacebookShareButton>
        {/* <div className="w-[2.875rem] h-[2.875rem] text-warning-1 rounded-[2.5rem] grid place-items-center border border-white/[0.12]  cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-300">
          <Icon iconName="instagramNaked" />
        </div> */}
        <WhatsappShareButton
          url={`${link}`}
          title={shareDescription}
          separator={` `}
        >
          <div className="grid h-[2.875rem] w-[2.875rem] cursor-pointer place-items-center rounded-[2.5rem] border border-white/[0.12] text-warning-1 transition-colors duration-300 ease-in-out hover:bg-white/10">
            <Icon iconName="whatsappNaked" />
          </div>
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareLiveSchedule;
