import { useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DialogModal from "src/components/Dialog";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import {
  GetCustomMyPlanTask,
  GetEventPlan,
} from "src/graphql/queries/event.queries";
import DashboardLayout from "src/layouts/dashboard";
import useAppStore from "src/store/utils";
import {
  getCustomMyPlanTask,
  getCustomMyPlanTaskVariables,
  getEventPlan,
  getEventPlanVariables,
} from "src/types/api";
import { v4 as uuidv4 } from "uuid";
import AddTask from "./add-task";
import AddendumCard from "./addendum-card";
import styles from "./myPlan.module.scss";
import PlanCard from "./plan-card";

const MyPlan = () => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const isAdmin = contextValue?.isAdmin;
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const { data: eventPlanData } = useQuery<getEventPlan, getEventPlanVariables>(
    GetEventPlan,
    {
      skip: !event.id,
      variables: {
        eventId: event.id,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const { loading, data: customPlanTaskData } = useQuery<
    getCustomMyPlanTask,
    getCustomMyPlanTaskVariables
  >(GetCustomMyPlanTask, {
    skip: !event.id,
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "cache-first",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const planList = [
    {
      title: "Upload Invitation Card",
      sub: "Upload your invitation card or select from a wide range of templates",
      link: "/invitations?tab=invitationCard",
      done: eventPlanData?.getEventPlan.invitation_card ?? false,
    },

    {
      title: "Update Event Schedule",
      sub: "Fill the event schedule details",
      link: "/event-schedule",
      done: eventPlanData?.getEventPlan.event_details ?? false,
    },

    {
      title: "Update Backstory",
      sub: "How did you meet your partner? Tell us",
      link: "/website?tab=backstory",
      done: eventPlanData?.getEventPlan.add_back_story ?? false,
    },

    {
      title: "Create Categories",
      sub: "Seperate your invite list into different categories",
      link: "/guests?tab=categories",
      done: eventPlanData?.getEventPlan.categories ?? false,
    },

    {
      title: "Build your Invitation List",
      sub: "Build your Invitation list",
      link: "/invitations?tab=sendInvites",
      done: eventPlanData?.getEventPlan.build_invite_list ?? false,
    },

    {
      title: "Add Wishlist!",
      sub: "Tell your guests what type of gifts you will like to see!",
      link: "/gifts?tab=wishlist",
      done: eventPlanData?.getEventPlan.add_wishlist ?? false,
    },

    {
      title: "Photobook Memories",
      sub: "Add pictures of you and your partner",
      link: "/photobook?tab=photos",
      done: eventPlanData?.getEventPlan.upload_pictures ?? false,
    },

    {
      title: "Add Event Vendor",
      sub: "Add vendors to your event",
      link: "/event-vendors",
      done: eventPlanData?.getEventPlan.add_event_vendors ?? false,
    },

    {
      title: "Send Out Your Invites",
      sub: "Send out your Invite list to your guests",
      link: "/invitations?tab=sendInvites",
      done: eventPlanData?.getEventPlan.send_out_invites ?? false,
    },

    {
      title: "Set PIN",
      sub: "A secure PIN for all of your transactions",
      link: "/wallet",
      done: eventPlanData?.getEventPlan.wallet_pin_set ?? false,
    },
    {
      title: "Add Foreign Account Details",
      sub: "Add your Dollar or Pound account details",
      link: "/wallet?action=add_dollar_details",
      done: eventPlanData?.getEventPlan.add_foreign_account ?? false,
    },
    {
      title: "Set up Live hall",
      sub: "Your loved ones can now join your wedding virtually.",
      link: "/live-hall",
      done: eventPlanData?.getEventPlan.add_live_hall ?? false,
    },
  ]
    // ?.filter((item) => {
    //   // remove live feat in prod
    //   return process.env.REACT_APP_INAWO_ENV === "dev"
    //     ? item
    //     : !["Set up Live hall"].includes(item.title);
    // })
    .filter((item) => {
      // remove non admin plans
      return isAdmin
        ? item
        : !["Set PIN", "Add Foreign Account Details"].includes(item.title);
    });

  return (
    <DashboardLayout pageName="Tasks">
      <div className={styles["myPlan"]}>
        <div className={styles["myPlan__container"]}>
          <div className={styles["myPlan__deck"]}>
            <div className={styles["myPlan__row"]}>
              <p className={styles["myPlan__title"]}>Compulsory Tasks</p>
              <div className={styles["myPlan__length"]}>{planList.length}</div>
            </div>
            <div className={styles["myPlan__box"]}>
              {planList.map((item) => (
                <PlanCard
                  key={uuidv4()}
                  fn={() => null}
                  id={item.title}
                  done={item?.done ?? false}
                  link={item.link}
                  subText={item.sub}
                  text={item.title}
                  main
                />
              ))}
            </div>
          </div>
          <div className={styles["myPlan__deck"]}>
            <div className={styles["myPlan__row"]}>
              <p className={styles["myPlan__title"]}>My Tasks</p>
              {!!customPlanTaskData?.getCustomMyPlanTask.length && (
                <div className={styles["myPlan__length"]}>
                  {customPlanTaskData?.getCustomMyPlanTask.length}
                </div>
              )}
            </div>
            <div
              className={`${styles["myPlan__deck"]} ${styles["myPlan__mobile-deck"]}`}
            >
              <DialogModal
                trigger={
                  <div
                    onClick={() => setOpenAdd(true)}
                    className={styles["myPlan__button"]}
                  >
                    <span>+ Add New Task</span>
                  </div>
                }
                open={openAdd}
                onOpenChange={setOpenAdd}
              >
                <AddTask setOpen={setOpenAdd} />
              </DialogModal>

              <div className={styles["myPlan__box-2"]}>
                {loading ? (
                  <InnerLoading />
                ) : (
                  customPlanTaskData?.getCustomMyPlanTask.map((item) => (
                    <AddendumCard
                      key={uuidv4()}
                      id={item?.id ?? ""}
                      done={item?.done ?? false}
                      text={item?.name ?? ""}
                      date={item?.created_at ?? ""}
                      description={item?.description ?? ""}
                      eventUUID={item?.eventUUID ?? ""}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyPlan;
