import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { FieldHookConfig, useField } from "formik";
import _ from "lodash";
import { useCallback, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export const PlacesAutoComplete = ({
  setCoords,
  ...props
}: { setCoords: (value: {}) => void } & FieldHookConfig<string>) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({});

  const [field, meta, helpers] = useField(props);
  const [inputValue, setInputValue] = useState("");

  const handleSelect = async (address: any) => {
    try {
      setValue(address, false);
      clearSuggestions();
      const result = await getGeocode({ address });
      const { lat, lng } = await getLatLng(result[0]);
      setCoords({
        latitude: lat,
        longitude: lng,
        location: address,
        place_id: result[0].place_id,
      });
      helpers.setValue(address);
      helpers.setTouched(true);
      console.log(
        `${address} Coordinates --> lat: ${lat}, lng: ${lng}`,
        result[0],
        address
      );
    } catch (error) {
      console.error("Error in geocoding:", error);
    }
  };

  const debouncedSetValue = useCallback(
    _.debounce((val: string) => {
      setValue(val);
    }, 500),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    helpers.setValue(value);
    debouncedSetValue(value);
  };
  const handleInputSelect = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedValue = e.currentTarget.value;
    handleSelect(selectedValue);
  };

  return (
    <div className="w-[100%]">
      <label className="text-md mb-2 block font-medium text-gray-700">
        Location
      </label>
      <input
        style={{
          width: "100%",
          height: "auto",
          outline: 0,
          padding: "0.8rem 1rem",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "22px",
          paddingRight: "3rem",
          border: "1px solid #F2F2F2",
          borderRadius: "2px",
          backgroundColor: "#FAFAFA",
        }}
        name={field.name}
        className="w-full"
        list="location"
        value={field.value}
        onChange={handleInputChange}
        onInput={handleInputSelect}
        placeholder="Search Your Location...."
      />
      <datalist
        id="location"
        style={{ backgroundColor: "#FAFAFA", width: "100%" }}
      >
        {status === "OK" &&
          data.map(({ description, place_id }) => (
            <option key={place_id} value={description}>
              {description}
            </option>
          ))}
      </datalist>
      {meta.touched && meta.error && (
        <div className="text-sm text-red-500">{meta.error}</div>
      )}
    </div>
  );
};

const MapView = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });

  const onLoadMarker = (marker: any) => {
    console.log("Marker", marker?.position?.lat);
  };

  return (
    <div className="mx-auto h-[300px] w-[100%]">
      {!isLoaded ? (
        <h3>Loading Map.....</h3>
      ) : (
        <div className="h-[100%] w-[100%]">
          <GoogleMap
            center={{ lat: latitude, lng: longitude }}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            zoom={19}
          >
            <MarkerF
              position={{ lat: latitude, lng: longitude }}
              onLoad={onLoadMarker}
            />
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default MapView;
