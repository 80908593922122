import { useMutation } from "@apollo/client";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CreateAppreciation } from "src/graphql/mutations";
import { appreciateGift, appreciateGiftVariables } from "src/types/api";
import styles from "./giftTransferTable.module.scss";

interface GiftTransferTableProps {
  giftsReceived?: string;
  amount?: number;
  appreciated: boolean;
  fullName?: string;
  id?: string | null;
  transactionId?: string;
  phoneNumber: string;
}

const GiftTransferTable: React.FC<GiftTransferTableProps> = ({
  giftsReceived,
  amount,
  fullName,
  id,
  appreciated,
  transactionId,
  phoneNumber,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const [appreciateGiftFn, { loading }] = useMutation<
    appreciateGift,
    appreciateGiftVariables
  >(CreateAppreciation, {
    onCompleted() {
      setOpenModal(false);
      toast.success(
        <p className="toast">
          Your appreciation note has been sent to your guest!
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["table__list-item"]}>
      <div className={styles["table__list-item__left"]}>
        <span className={styles["table__name"]}>
          {!!fullName ? fullName : "Anonymous"}
        </span>{" "}
        sent you <span className={styles["table__amount"]}>NGN {amount}</span>{" "}
        for{" "}
        <span className={styles["table__giftsReceived"]}>{giftsReceived}</span>
      </div>

      <div>
        {phoneNumber && (
          <a
            className={styles["table__btn"]}
            href={
              phoneNumber
                ? `https://wa.me/${phoneNumber.slice(1)}?text=Hi${`%20${
                    fullName ? fullName : ""
                  }`},%20Thank%20you%20for%20the%20gift.`
                : "#"
            }
          >
            Appreciate Gift
          </a>
        )}
        {/*
          <div className={styles["table__action"]}>
            <Button
              label="Appreciate Gift"
              type="button"
              design="secondary"
              onClick={() => {
                toast.error(
                  <p className="toast">{"Phoe number not available"}</p>
                );
              }}
              disabled={false}
            >
              <span>Appreciate Gift</span>
            </Button>
          </div>
					*/}
        {/*
          <Dialog
            open={openModal}
            onOpenChange={setOpenModal}
            trigger={
              <div className={styles["table__action"]}>
                <Button
                  label="Appreciate Gift"
                  type="button"
                  design="secondary"
                  onClick={() => null}
                  disabled={false}
                >
                  <span>Appreciate Gift</span>
                </Button>
              </div>
            }
          >
            <div className={styles["dialog-content"]}>
              <AppreciateGift
                setToggleModal={() => setOpenModal((prev) => !prev)}
                appreciateGift={appreciateGiftFn}
                loading={loading}
                id={transactionId}
              />
            </div>
          </Dialog>
					*/}
      </div>
    </div>
  );
};

export default GiftTransferTable;
