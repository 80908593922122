import Button from "src/components/Button";
import PassDesign from "src/layouts/dashboard/Icons/PassDesign";

const ComingSoonModal = () => {
  return (
    <div className="w-[400px] rounded-[18px] bg-white p-[40px]">
      <div className="flex flex-col items-center">
        <div className="mb-[12px]">
          <PassDesign />
        </div>
        <h3 className="mb-[15px] text-[12px] font-semibold uppercase tracking-[1px] text-[#919191]">
          See More pass designs
        </h3>
        <p className="text-center text-[13px] text-[#898989]">
          See more pass designs that you can use for your wedding to make it
          unique
        </p>
        <Button
          label="coming soon"
          type="button"
          className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
          style={{ height: "36px", marginTop: "20px" }}
        >
          COMING SOON
        </Button>
      </div>
    </div>
  );
};

export default ComingSoonModal;
