import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import Button from "src/components/Button";
import InnerLoading from "src/components/InnerLoading";
import { Spinner } from "src/components/Spinner";
import { SetPassImage } from "src/graphql/mutations";
import { GetEvent } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { setPassImageVariables } from "src/types/api";
import { event } from "src/types/api.d";

const PassImageItem = ({
  image,
  setOpen,
}: {
  image: string;
  setOpen: (value: boolean) => void;
}) => {
  const { event } = useAppStore((state) => state);

  const {
    data,
    loading: eventLoading,
    refetch,
  } = useQuery<event>(GetEvent, {
    variables: {
      eventId: event?.id,
    },
  });

  const [setPassImage, { loading }] = useMutation<setPassImageVariables>(
    SetPassImage,
    {
      onCompleted: () => {
        toast.success(<p className="toast">Successfully set pass image!</p>);
        refetch();
        setOpen(false);
      },
    }
  );
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[10px]">
        <img
          src={image}
          alt=""
          className="h-[80px] w-[80px] rounded-[5px] object-cover"
        />
      </div>
      <Button
        label="Set Image"
        type="button"
        className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
        design="secondary"
        style={{
          height: "35px",
          width: "80px",
          fontWeight: 400,
          fontSize: 13,
        }}
        onClick={() => {
          setPassImage({
            variables: {
              eventId: event.uuid,
              image: image,
            },
          });
        }}
      >
        {loading ? <Spinner /> : "Use"}
      </Button>
    </div>
  );
};

const SelectPassImageModal = ({
  setOpen,
  data,
  loading: albumLoading,
}: {
  setOpen: (value: boolean) => void;
  data: any;
  loading: boolean;
}) => {
  return (
    <div className="flex h-[500px] w-full flex-col gap-[40px] overflow-auto rounded-[5px] bg-white p-[20px] lg:w-[500px] lg:p-[40px]">
      <h2 className="text-[24px] font-medium">Select a pass image</h2>
      <div className="flex flex-col gap-[20px] py-[10px] lg:py-[30px]">
        {albumLoading ? (
          <InnerLoading />
        ) : (
          <>
            {data?.map((item: any, index: number) => (
              <PassImageItem
                key={item?.id}
                image={item?.url}
                setOpen={setOpen}
              />
            ))}
          </>
        )}
      </div>
      {/* <div className="flex items-center justify-end gap-[20px]">
        <Button
          label="Set Image"
          type="button"
          design="secondary"
          className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
          style={{ height: "40px" }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          label="Set Image"
          type="button"
          className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
          style={{ height: "40px" }}
          onClick={() => {
            setPassImage({
              variables: {
                eventId: event.uuid,
                image: selectedImage,
              },
            });
          }}
        >
          {loading ? <Spinner /> : "Set this image"}
        </Button>
      </div> */}
    </div>
  );
};

export default SelectPassImageModal;
