import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { AccessControlIcon } from "src/components/Icons";
import { Spinner } from "src/components/Spinner";
import { UpdateEventPreference } from "src/graphql/mutations";
import {} from "src/graphql/mutations/event.mutation";
import { ActivateAccessControlIcon } from "src/layouts/dashboard/Icons/AccessControlIcon";
import useAppStore from "src/store/utils";
import { updateEventPreferenceVariables } from "src/types/api.d";

const ToggleAccessControlModal = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const [updateEventPreference, { loading }] =
    useMutation<updateEventPreferenceVariables>(UpdateEventPreference, {
      onCompleted() {
        setOpen(false);
        toast.success("Access control activated successfully");
        // navigate(0);
      },
      onError(error) {
        setOpen(false);
        toast.error(
          <p className="toast">{error?.message ?? "An error occurred"}</p>
        );
      },
    });

  return (
    <div className="flex h-auto max-w-[500px] flex-col items-center gap-[30px] rounded-[5px] bg-white px-[20px] py-[25px] lg:px-[45px]">
      <div className="flex items-center gap-[20px]">
        <AccessControlIcon />
        <h3 className="text-[20px] font-medium text-[#298576]">
          Access control
        </h3>
      </div>
      <p className="text-center text-[20px] text-[#707070]">
        Are you sure you want to make this event totally private?
      </p>
      <ActivateAccessControlIcon />
      <div className="flex w-full items-center justify-between gap-[18px]">
        <Button
          label="Yes, Activate"
          type="button"
          onClick={() => {
            updateEventPreference({
              variables: {
                input: {
                  event_id: event.uuid,
                  access_control: true,
                },
              },
            });
          }}
          design="primary"
        >
          {loading ? <Spinner /> : "Yes, Activate"}
        </Button>
        <Button
          label="No, Later"
          type="button"
          design="secondary"
          className="whitespace-nowrap !text-[12px] sm:!text-[14px]"
          onClick={() => setOpen(false)}
        >
          No, Later
        </Button>
      </div>
    </div>
  );
};

export default ToggleAccessControlModal;
