import Icon from "src/components/Icon";
import Info from "src/components/Info";
// import AddCategory from "../../guests/categories/modals/AddCategory";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

function EmptyState() {
  const navigate = useNavigate();
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        <p className={styles["EmptyState--title"]}>
          See all your guest and their information here
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        <Icon iconName="activityComingSoon" />
        <p>
          Seems you have no guests yet, let’s <br /> start sending out some
          invites or links
        </p>
        <div
          onClick={() => navigate("/invitations?tab=sendInvites")}
          className={styles["EmptyState--main__trigger"]}
        >
          <span>Send Invites</span>
        </div>
      </div>
    </div>
  );
}

export default EmptyState;
