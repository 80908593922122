import { useMutation, useQuery } from "@apollo/client";
import * as Popover from "@radix-ui/react-popover";
import { formatDistanceToNow } from "date-fns";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useSearchParams } from "react-router-dom";
import Backdrop from "src/assets/images/bgImage.svg";
import {
  default as Dialog,
  default as DialogModal,
} from "src/components/Dialog";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import { InitiateWalletPinReset } from "src/graphql/mutations/wallets.mutations";
import {
  GetEventWalletsV1,
  GetForeignWalletDetails,
} from "src/graphql/queries/wallets.queries";
import { formatMoney } from "src/helper/functions";
import useAppStore from "src/store/utils";
import {
  Currency,
  getForeignAccountDetails_getForeignAccountDetails,
  getWalletsV1,
  getWalletsV1Variables,
  getWalletsV1_getWalletsV1_transaction_history,
  getWalletsV1_getWalletsV1_transaction_history_user,
  initiateWalletPinReset,
  initiateWalletPinResetVariables,
} from "src/types/api.d";
import { v4 as uuidv4 } from "uuid";
import AddForeignAcct from "../modals/AddForiegnAcct";
import ConfirmTransfer from "../modals/ConfirmTransfer";
import EnterPin from "../modals/EnterPin";
import ForeignAcctDetails from "../modals/ForeignAcctDetails";
import ResetPin from "../modals/ResetPin";
import ResetPinOTP from "../modals/ResetPin/ResetPinOTP";
import SetPin from "../modals/SetPin";
import TransactionDetails from "../modals/TransactionDetails";
import Transfer from "../modals/Transfer";
import TransferOTP from "../modals/TransferOTP";
import styles from "./nairaWallet.module.scss";

type WalletProps = {};

interface BankData {
  __typename: string | null;
  id: number | null;
  name: string | null;
  code: string | null;
}

type HistoryProps = {
  user: getWalletsV1_getWalletsV1_transaction_history_user | null;
  id: number | string;
  amount: number | string;
  credit: boolean;
  stage: string;
  uuid: string | null;
  alias: string | null;
  kind: string | null;
  onClick: () => void;
  narration: string | null;
  time: any | null;
};

function convertToValidDate(value: any): Date | null {
  const date = new Date(value);
  return isNaN(date.getTime()) ? null : date;
}

function formatTransactionTime(t: any | null): string {
  const date = convertToValidDate(t);
  return date ? formatDistanceToNow(date, { addSuffix: true }) : "";
}

const History: FC<HistoryProps> = (props) => {
  return (
    <div
      onClick={() => props?.onClick()}
      key={uuidv4()}
      className={styles["nairaWallet__main__content__item"]}
    >
      <p>
        {props?.narration?.trim()} ({props.stage}){" "}
        <span style={{ color: "#808080" }}>
          {formatTransactionTime(props?.time)}
        </span>
      </p>
      <div
        className={
          props.credit
            ? styles["nairaWallet__main__content__item-credit"]
            : styles["nairaWallet__main__content__item-debit"]
        }
      >
        {}
        {`N${formatMoney(Number(Number(props.amount)?.toFixed(0)))}`}
      </div>
    </div>
  );
};

interface GetForeignWalletDetailsInterface {
  getForeignAccountDetails: getForeignAccountDetails_getForeignAccountDetails[];
}

const NairaWallet: FC<WalletProps> = () => {
  const [open, setOpen] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [openTransferOTP, setOpenTransferOTP] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [openPin, setOpenPin] = useState(false);
  const [openResetPin, setOpenResetPin] = useState(false);
  const [passcode, setPasscode] = useState<any>("");
  const [pin, setPin] = useState("");
  const [openConfirmDetails, setOpenConfirmDetails] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [selectedOption, setSelectedOption] = useState<BankData>(
    {} as BankData
  );
  const [addForiegnAcct, setAddForeignAcct] = useState(false);
  const [viewForiegnAcct, setViewForiegnAcct] = useState(false);
  const [currWalletDetailsItem, setCurrWalletDetailsItem] =
    useState<getWalletsV1_getWalletsV1_transaction_history | null>(null);
  const [walletDetailsModalOpen, setWalletDetailsModalOpen] = useState(false);

  const contextValue = useContext<ValueProps | null>(AppContext);
  const { event } = useAppStore((state) => state);
  // const walletId = contextValue?.walletCode;
  const setWalletCode = contextValue?.setWalletCode;
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  const urlParamAction = searchParams.get("action");

  const {
    data: eventWalletDetails,
    loading: eventWalletDetailsLoading,
    refetch: refetchWalletDetails,
  } = useQuery<getWalletsV1, getWalletsV1Variables>(GetEventWalletsV1, {
    skip: !event.id,
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  // const {
  //   data: walletInformation,
  //   loading: eventWalletLoading,
  //   // refetch: refetchWalletDetails,
  // } = useQuery<getEventWalletVariables>(GetEventWallet, {
  //   skip: !event.id,
  //   variables: {
  //     eventId: event.id,
  //   },
  //   fetchPolicy: "network-only",
  //   onError(error) {
  //     if (error?.message !== "unauthenticated") {
  //       toast.error(
  //         <p className="toast">{error?.message ?? "An error occured"}</p>
  //       );
  //     } else {
  //       navigate("/clear");
  //     }
  //   },
  // });

  // console.log('event wallet', data);

  const { data: foreignWalletDetails, loading: foreignDetailsLoading } =
    useQuery<GetForeignWalletDetailsInterface>(GetForeignWalletDetails, {
      skip: !event.id,
      variables: {
        eventId: event.id,
      },
      fetchPolicy: "network-only",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const [ResetPinFn] = useMutation<
    initiateWalletPinReset,
    initiateWalletPinResetVariables
  >(InitiateWalletPinReset, {
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  // naira account details
  const nariaBalance = useMemo(() => {
    const res = eventWalletDetails?.getWalletsV1.find(
      (item) => !!item && item.currency === Currency.ngn
    );
    return res;
  }, [eventWalletDetails]);

  // foreign account details
  const foreignDetails = useMemo(() => {
    const res = foreignWalletDetails?.getForeignAccountDetails.find(
      (item) => item.currency === Currency.gpb || Currency.usd
    );
    return res;
  }, [foreignWalletDetails]);

  useEffect(() => {
    if (urlParamAction === "add_dollar_details") {
      setAddForeignAcct(true);
    }
  }, [urlParamAction]);

  if (eventWalletDetailsLoading) {
    return (
      <div className={styles["nairaWallet__loading"]}>
        <InnerLoading />
      </div>
    );
  }

  const nairaWallet = eventWalletDetails?.getWalletsV1.find(
    (item) => !!item && item.currency === Currency.ngn
  );

  localStorage.setItem("walletCode", JSON.stringify(nairaWallet?.id));

  setWalletCode && setWalletCode(nairaWallet?.id as string);

  if (nairaWallet?.pin_is_set === false) {
    return (
      <div className={styles["nairaWallet__loading"]}>
        <SetPin setOpenPin={setOpenPin} eventId={event.id} />
      </div>
    );
  }

  return (
    <div className={styles["nairaWallet"]}>
      <DialogModal
        open={walletDetailsModalOpen}
        onOpenChange={setWalletDetailsModalOpen}
        trigger={<></>}
      >
        <TransactionDetails item={currWalletDetailsItem} />
      </DialogModal>
      <header className={styles["nairaWallet__header"]}></header>
      {!!eventWalletDetails && (
        <main className={styles["nairaWallet__main"]}>
          {/* Wallet Details Top Card*/}
          <div className={styles["nairaWallet__main__top"]}>
            <div className={styles["nairaWallet__backdrop"]}>
              <LazyLoadImage
                src={Backdrop}
                placeholderSrc={Backdrop}
                alt="Backdrop"
                effect="blur"
                width="100%"
                height="100%"
                className={styles["nairaWallet__backdrop__image"]}
              />
            </div>

            <div className={styles["nairaWallet__main__block"]}>
              <div className={styles["nairaWallet__main__block__nairaAcct"]}>
                <span>Naira Account</span>
                <h1>
                  {nariaBalance?.balance?.toLocaleString() ?? "0"}{" "}
                  {nariaBalance?.currency?.toUpperCase() ?? "NGN"}
                </h1>
              </div>
              <div className={styles["nairaWallet__main__block__foreignAcct"]}>
                <span
                  className={
                    styles["nairaWallet__main__block__foreignAcct__alert"]
                  }
                >
                  {foreignDetailsLoading
                    ? `Loading..`
                    : foreignDetails
                      ? `${
                          foreignDetails.currency === Currency.usd
                            ? `Dollar`
                            : `Pound`
                        } Account Details`
                      : `No Foreign Account`}
                </span>
                <div className={styles["nairaWallet__main__dialog"]}>
                  {!foreignDetailsLoading && foreignDetails ? (
                    <DialogModal
                      open={viewForiegnAcct}
                      onOpenChange={setViewForiegnAcct}
                      trigger={
                        <span
                          className={
                            styles["nairaWallet__main__foreignAcct__btn"]
                          }
                        >
                          View
                        </span>
                      }
                    >
                      <ForeignAcctDetails
                        foreignDetails={foreignDetails}
                        setOpen={setViewForiegnAcct}
                      />
                    </DialogModal>
                  ) : (
                    <DialogModal
                      open={addForiegnAcct}
                      onOpenChange={setAddForeignAcct}
                      trigger={
                        <span
                          className={
                            styles["nairaWallet__main__foreignAcct__btn"]
                          }
                        >
                          Add
                        </span>
                      }
                    >
                      <AddForeignAcct
                        setOpen={(i: boolean) => {
                          setAddForeignAcct(!AddForeignAcct);
                        }}
                      />
                    </DialogModal>
                  )}
                </div>
              </div>

              {/* <div className={styles["nairaWallet__main__block__acc-number"]}>
                <div className={styles["nairaWallet__main__block__group"]}>
                  <h3>
                    {eventWalletDetails?.getWalletsV1.find(
                      (item) => !!item && item.currency === "ngn"
                    )?.name ?? ""}
                  </h3>
                  <p>Account Name</p>
                  <h4>
                    {eventWalletDetails?.getWalletsV1.find(
                      (item) => !!item && item.currency === "ngn"
                    )?.virtual_account_number ?? ""}
                  </h4>
                  <p>
                    {eventWalletDetails?.getWalletsV1.find(
                      (item) => !!item && item.currency === "ngn"
                    )?.virtual_bank_name ?? ""}
                  </p>
                </div>
                <div
                  className={styles["nairaWallet__main__block__group-copy"]}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `Account Name: ${
                          eventWalletDetails?.getWalletsV1
                            .find((item) => !!item && item.currency === "ngn")
                            ?.name?.toUpperCase() ?? ""
                        }, Bank Name: ${
                          eventWalletDetails?.getWalletsV1
                            .find((item) => !!item && item.currency === "ngn")
                            ?.virtual_bank_name.toUpperCase() ?? ""
                        }, Account Number: ${
                          eventWalletDetails?.getWalletsV1.find(
                            (item) => !!item && item.currency === "ngn"
                          )?.virtual_account_number ?? ""
                        }`
                      )
                      .then(() => {
                        toast.success("Copied account details to clipboard");
                      })
                  }
                >
                  <Icon iconName="copy" />
                </div>
              </div> */}
            </div>
          </div>

          {/*Wallet Details Bottom Card  */}
          <div className={styles["nairaWallet__main__context"]}>
            {/* Transfer Funds Modal */}
            <div className={styles["nairaWallet__main__dialog"]}>
              <DialogModal
                open={open}
                onOpenChange={setOpen}
                trigger={
                  <div
                    onClick={() => {
                      setOpen(true);
                      setAccountNumber("");
                      setAmount("");
                      setSelectedOption({} as BankData);
                      setAccountName("");
                      setPasscode("");
                    }}
                    className={styles["nairaWallet__header__trigger"]}
                  >
                    <span>Transfer Funds</span>
                  </div>
                }
              >
                <Transfer
                  setOpen={setOpen}
                  setOpenConfirmDetails={setOpenConfirmDetails}
                  setAccountNumber={setAccountNumber}
                  setAmount={setAmount}
                  accountNumber={accountNumber}
                  amount={amount}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setAccountName={setAccountName}
                />
              </DialogModal>
            </div>
            {/* Dot Menu Popover */}
            <div className={styles["nairaWallet__main__dialog-pop"]}>
              <Popover.Root>
                <Popover.Trigger className={styles["nairaWallet__main__icon"]}>
                  <Icon iconName="kebab" />
                  <span className="visually-hidden">open dropdown</span>
                </Popover.Trigger>
                <Popover.Content className={styles["nairaWallet__dropdown"]}>
                  <Dialog
                    open={openOTP}
                    onOpenChange={setOpenOTP}
                    trigger={
                      <div
                        className={styles["nairaWallet__dropdown__button"]}
                        onClick={() => {
                          setOpenOTP(true);
                          ResetPinFn({
                            variables: {
                              eventId: event.id,
                            },
                          });
                        }}
                      >
                        Reset PIN
                      </div>
                    }
                  >
                    <ResetPinOTP
                      setOpenOTP={setOpenOTP}
                      eventId={event.id}
                      setPasscode={setPasscode}
                      setOpenResetPin={setOpenResetPin}
                    />
                  </Dialog>
                  {!foreignDetailsLoading && foreignDetails ? (
                    <DialogModal
                      open={viewForiegnAcct}
                      onOpenChange={setViewForiegnAcct}
                      trigger={
                        <span
                          className={styles["nairaWallet__dropdown__button"]}
                        >
                          {`View ${
                            foreignDetails.currency === Currency.usd
                              ? `Dollar`
                              : `Pound`
                          } Account`}
                        </span>
                      }
                    >
                      <ForeignAcctDetails
                        foreignDetails={foreignDetails}
                        setOpen={setViewForiegnAcct}
                      />
                    </DialogModal>
                  ) : (
                    <DialogModal
                      open={addForiegnAcct}
                      onOpenChange={setAddForeignAcct}
                      trigger={
                        <span
                          className={styles["nairaWallet__dropdown__button"]}
                        >
                          Add Foreign Account
                        </span>
                      }
                    >
                      <AddForeignAcct setOpen={setAddForeignAcct} />
                    </DialogModal>
                  )}
                </Popover.Content>
              </Popover.Root>
            </div>
          </div>

          {/* Wallet Transactions section */}
          <>
            <div className={styles["nairaWallet__main__content__text"]}>
              <p>Wallet Transactions</p>
            </div>
            <div className={styles["nairaWallet__main__content"]}>
              {/*
                A bunch of null checks to make the compiler happy. Change when you have time.
                ===============================================================
                Filtering out the curency because we can only show ngn transactions for now.
              */}
              {!!eventWalletDetails.getWalletsV1 &&
              !!eventWalletDetails.getWalletsV1.find(
                (item) => !!item && item.currency === Currency.ngn
              ) &&
              !!eventWalletDetails.getWalletsV1?.find(
                (item) => !!item && item.currency === Currency.ngn
              )?.transaction_history &&
              eventWalletDetails.getWalletsV1.find(
                (item) => !!item && item.currency === Currency.ngn
              )?.transaction_history?.length! > 0 ? (
                // sutff actually starts here
                eventWalletDetails.getWalletsV1
                  .find((item) => !!item && item.currency === Currency.ngn)
                  ?.transaction_history!.map((item) =>
                    !!item ? (
                      <History
                        key={uuidv4()}
                        user={item.user}
                        id={item.id}
                        time={item.created_at}
                        amount={item.amount}
                        credit={item.type === "credit"}
                        stage={item.stage}
                        uuid={item.user_uuid}
                        alias={item.full_name}
                        kind={item?.kind}
                        narration={item?.narration}
                        onClick={() => {
                          setCurrWalletDetailsItem(item);
                          setWalletDetailsModalOpen(true);
                        }}
                      />
                    ) : (
                      <></>
                    )
                  )
              ) : (
                <div className={styles["nairaWallet__main__empty"]}>
                  <Icon iconName="wallet" />
                  <p className={styles["nairaWallet__main__p"]}>
                    You do not have any transactions yet.
                  </p>{" "}
                </div>
              )}
              <>
                <DialogModal
                  open={openConfirmDetails}
                  onOpenChange={setOpenConfirmDetails}
                  trigger={<></>}
                >
                  <ConfirmTransfer
                    setOpenConfirmDetails={setOpenConfirmDetails}
                    amount={amount}
                    accountNumber={accountNumber}
                    accountBank={selectedOption.code}
                    bankName={selectedOption.name}
                    name={accountName}
                    bankCode={selectedOption.code}
                    walletPin={pin}
                    setOpenPin={setOpenPin}
                  />
                </DialogModal>
                <DialogModal
                  open={openPin}
                  onOpenChange={setOpenPin}
                  trigger={<></>}
                >
                  <EnterPin
                    setOpenPin={setOpenPin}
                    eventId={event.id}
                    setPin={setPin}
                    amount={amount}
                    accountNumber={accountNumber}
                    accountBank={selectedOption.code}
                    bankName={selectedOption.name}
                    name={accountName}
                    bankCode={selectedOption.code}
                    walletPin={pin}
                    walletId={nairaWallet?.id}
                    currency={Currency.ngn}
                    openTransferOTP={openTransferOTP}
                    setOpenTransferOTP={setOpenTransferOTP}
                    onTransferComplete={() =>
                      refetchWalletDetails({ eventId: event.id })
                    }
                  />
                </DialogModal>
                <DialogModal
                  open={openResetPin}
                  onOpenChange={setOpenResetPin}
                  trigger={<></>}
                >
                  <ResetPin
                    setOpenResetPin={setOpenResetPin}
                    eventId={event.id}
                    passcode={passcode}
                  />
                </DialogModal>
                <DialogModal
                  open={openTransferOTP}
                  onOpenChange={setOpenTransferOTP}
                  trigger={<></>}
                >
                  <TransferOTP
                    eventId={event.id}
                    setOpenTransferOTP={setOpenTransferOTP}
                    setOpenPin={setOpenPin}
                    amount={amount}
                    onTransferComplete={() =>
                      refetchWalletDetails({ eventId: event.id })
                    }
                  />
                </DialogModal>
              </>
            </div>
          </>
        </main>
      )}
    </div>
  );
};

export default NairaWallet;
