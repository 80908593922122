// export function getTimestamp(date: any, time: any) {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");

//   const [hours, minutes] = time.split(":");
//   const combinedDateTime = new Date(
//     `${year}-${month}-${day}T${hours}:${minutes}:00Z`
//   );

//   return Math.floor(combinedDateTime.getTime() / 1000);
// }

export function getTimestamp(date: any, time: any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const [hours, minutes] = time.split(":");

  // Create a date string in the local timezone
  const localDateTime = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:00`
  );

  // Convert to UTC timestamp
  return Math.floor(localDateTime.getTime() / 1000);
}
