interface IconProps {
  fill?: string;
}
const AccessControl = ({ fill }: IconProps) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.05 1.17202L10.9137 0.673116C6.64104 -0.337478 4.09537 0.494023 2.59867 3.58976C2.2149 4.3701 1.90788 5.31673 1.65204 6.40408L0.398391 11.7641C-0.855256 17.1112 0.794953 19.7465 6.12935 21.0129L8.27846 21.5246C9.02041 21.7037 9.7112 21.8188 10.3508 21.87C14.342 22.2538 16.4655 20.3861 17.5401 15.7681L18.7937 10.4209C20.0474 5.07367 18.41 2.42566 13.05 1.17202ZM10.645 14.1178C10.5299 14.5528 10.1461 14.8342 9.7112 14.8342C9.63445 14.8342 9.55769 14.8214 9.46815 14.8086L5.74558 13.862C5.23389 13.7341 4.92687 13.2096 5.0548 12.6979C5.18272 12.1862 5.7072 11.8792 6.2189 12.0071L9.94146 12.9537C10.4659 13.0817 10.773 13.6062 10.645 14.1178ZM14.3932 9.79404C14.2781 10.229 13.8943 10.5104 13.4593 10.5104C13.3826 10.5104 13.3058 10.4976 13.2163 10.4848L7.01202 8.91137C6.50033 8.78345 6.19331 8.25896 6.32124 7.74727C6.44916 7.23558 6.97364 6.92856 7.48534 7.05648L13.6896 8.62994C14.2141 8.74507 14.5211 9.26956 14.3932 9.79404Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1021_16647"
          x1="-2.46416e-07"
          y1="16.4873"
          x2="19"
          y2="5.48731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34544F" />
          <stop offset="1" stopColor="#5E978E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { AccessControl };
