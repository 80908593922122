import * as Popover from "@radix-ui/react-popover";
import React, { useState } from "react";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import RemoveStreamer from "../modals/RemoveStreamer";
import styles from "./styles.module.scss";

interface LiveHallListProps {
  name: string;
  id: string | undefined;
  status: "online" | "offline";
}

const LiveHallList: React.FC<LiveHallListProps> = ({ id, name, status }) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <div className={styles["liveHallList"]}>
      <p>{name ?? "Unavailable"}</p>
      <p
        className={
          status === "offline"
            ? styles["liveHallList__offline"]
            : styles["liveHallList__online"]
        }
      >
        {status === "offline" ? (
          <Icon iconName="offline" />
        ) : (
          <Icon iconName="online" />
        )}{" "}
        {status}
      </p>
      <div
        className={`${styles["liveHallList__dropdown-container"]} ${styles["liveHallList__dropdown-end"]}`}
      >
        <Popover.Root>
          <Popover.Trigger
            className={styles["liveHallList__dropdown-container-button"]}
          >
            <Icon iconName="kebab" />
            <span className="visually-hidden">open dropdown</span>
          </Popover.Trigger>
          <Popover.Content className={styles["liveHallList__dropdown"]}>
            <div className={styles["liveHallList__dropdown-list"]}>
              <DialogModal
                trigger={
                  <div className={styles["liveHallList__remove"]}>
                    Remove streamer
                  </div>
                }
                open={openDelete}
                onOpenChange={setOpenDelete}
              >
                <RemoveStreamer id={id} setOpen={setOpenDelete} />
              </DialogModal>
            </div>
          </Popover.Content>
        </Popover.Root>
      </div>
    </div>
  );
};

export default LiveHallList;
