import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import useAppStore from "src/store/utils";
import styles from "./templateCard.module.scss";

interface TemplateCardProps {
  title: string;
  image: string;
}

type OrientationType = "portrait" | "landscape";

const TemplateCard: React.FC<TemplateCardProps> = ({ title, image }) => {
  const [orientation, setOrientation] = useState<OrientationType>("portrait");
  const [show, setShow] = useState(false);
  const { event } = useAppStore((state) => state);

  return (
    <div className={styles["templateCard"]}>
      <div className={styles["templateCard__container"]}>
        <div className={styles["templateCard__image"]}>
          {image ? (
            <LazyLoadImage
              src={image}
              placeholderSrc={image}
              alt="template"
              effect="blur"
              width="100%"
              height="100%"
            />
          ) : (
            <></>
          )}
        </div>
        <h3 className={styles["templateCard__heading"]}>{title}</h3>
        <div className={styles["templateCard__icon"]}>
          <Icon iconName="templateArrowRight" />
        </div>
        <div className={styles["templateCard__preview"]}>
          <DialogModal
            open={show}
            onOpenChange={setShow}
            trigger={
              <div className={styles["templateCard__preview-button"]}>
                <p>Preview</p>
                <span>
                  <Icon iconName="preview" />
                </span>
              </div>
            }
          >
            <div className={styles["templateCard__mobile-preview"]}>
              <div className={styles["templateCard__mobile-preview-title"]}>
                <h2>Preview</h2>

                <div className={styles["templateCard__mobile-views"]}>
                  <div className={styles["templateCard__mobile-views-box"]}>
                    {/* <button
                      className={`${
                        styles["templateCard__mobile-views-orientation"]
                      }
                        ${
                          orientation === "landscape" &&
                          styles[
                            "templateCard__mobile-views-orientation__active"
                          ]
                        }`}
                      onClick={() => setOrientation("landscape")}
                    >
                      <Icon iconName="landscape" />
                    </button> */}
                    <button
                      className={`${
                        styles["templateCard__mobile-views-orientation"]
                      } ${
                        orientation === "portrait" &&
                        styles["templateCard__mobile-views-orientation__active"]
                      }`}
                      onClick={() => setOrientation("portrait")}
                    >
                      <Icon iconName="potrait" />
                    </button>
                  </div>
                </div>
              </div>

              <ScrollArea.Root
                className={styles["templateCard__mobile-preview-box-container"]}
              >
                <ScrollArea.Viewport
                // className={styles["templates__main-preview-box-container"]}
                >
                  <div
                    className={`${styles["templateCard__mobile-preview-box"]} ${
                      orientation === "landscape" &&
                      styles["templateCard__mobile-preview-box__landscape"]
                    } ${
                      orientation === "portrait" &&
                      styles["templateCard__mobile-preview-box__portrait"]
                    } `}
                  >
                    <div
                      className={
                        styles["templateCard__mobile-preview-box__showcase"]
                      }
                    >
                      {!event?.slug ? (
                        <div className="flex h-full w-full items-center justify-center">
                          <InnerLoading />
                        </div>
                      ) : (
                        <iframe
                          src={`https://${event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}/`}
                          style={{
                            width:
                              orientation === "landscape" ? "100%" : "100%",
                            height: "75vh",
                          }}
                        ></iframe>
                      )}
                      {/* <LazyLoadImage
                        src={orientation === "landscape" ? ImgTem : ImgTem2}
                        placeholderSrc={
                          orientation === "landscape" ? ImgTem : ImgTem2
                        }
                        alt="Template"
                        effect="blur"
                        width="100%"
                        height="100%"
                      /> */}
                    </div>
                  </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                  className={styles["templates__main-preview-box__scrollbar"]}
                  orientation="horizontal"
                >
                  <ScrollArea.Thumb />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                  className={styles["templates__main-preview-box__scrollbar"]}
                  orientation="vertical"
                >
                  <ScrollArea.Thumb
                    className={styles["templates__main-preview-box__thumb"]}
                  />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner />
              </ScrollArea.Root>
            </div>
          </DialogModal>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
