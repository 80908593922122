import { useMutation } from "@apollo/client";
import * as Popover from "@radix-ui/react-popover";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { trackWindowScroll } from "react-lazy-load-image-component";
import MenuHorizontal from "src/assets/icons/menuHorizontal";
import PlayIcon from "src/assets/icons/play";
import AddFileIcon from "src/assets/svg/add";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import StaticImage from "src/components/StaticImage";
import { AppContext } from "src/context";
import { DeleteMedia, SetPassImage } from "src/graphql/mutations";
import { SetHeroImage } from "src/graphql/mutations/event.mutation";
import { GetMedia } from "src/graphql/queries";
import useCustomMutation from "src/hooks/useCustomMutation";
import useAppStore from "src/store/utils";
import {
  deleteMedia,
  deleteMediaVariables,
  setHeroImage,
  setHeroImageVariables,
  setPassImageVariables,
} from "src/types/api";
import blurDataUrl from "src/utils/generateBlurPlaceholder";
import { SelectedAlbumType, photoBookTabTypes } from "src/utils/types";
import { v4 as uuidv4 } from "uuid";
import styles from "../../photobook.module.scss";
import AddPictures from "../AddPictures";
import Album from "../Album";

type Props = {
  scrollPosition: { x: number; y: number };
  groupedMedia: any[];
  showSelected: boolean;
  selectedAlbum?: SelectedAlbumType;
  setSelectedAlbum?: (album: any) => void;
  setTabState: (tab: photoBookTabTypes) => void;
  selected: string[];
  handleChange: (id: string) => void;
};

const ImageList = ({
  scrollPosition,
  groupedMedia,
  showSelected,
  selected,
  handleChange,
  selectedAlbum,
  setSelectedAlbum,
  setTabState,
}: Props) => {
  const { event } = useAppStore((state) => state);
  const page = 1;
  const pageSize = 200;
  const isLuxeEvent = useContext(AppContext)?.isLuxeEvent;
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const { mutate: deleteMediaFn } = useCustomMutation<
    deleteMedia,
    deleteMediaVariables
  >(DeleteMedia, {
    onSuccess: () => {
      toast.success(<p className="toast">Successfully deleted!</p>);
    },
  });

  const { mutate: setHeroImageFn } = useCustomMutation<
    setHeroImage,
    setHeroImageVariables
  >(SetHeroImage, {
    onSuccess: () => {
      toast.success(<p className="toast">Successfully set hero image!</p>);
    },
  });

  const handleSetHeroImage = (url: string) => {
    setHeroImageFn({
      variables: {
        image: url,
        setHeroImageEventId2: event.id,
      },
    });
  };

  const handleDelete = (mediaId: string) => {
    deleteMediaFn({
      variables: { eventId: event.uuid, mediaId },
      refetchQueries: [
        {
          query: GetMedia,
          variables: {
            eventId: event.uuid,
            filter: {
              no_album: true,
              page,
              pageSize,
            },
          },
        },
      ],
    });
  };

  const [setPassImage, { loading }] = useMutation<setPassImageVariables>(
    SetPassImage,
    {
      onCompleted: () => {
        toast.success(<p className="toast">Successfully set pass image!</p>);
      },
    }
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    setIsVideoLoading(true);

    const handleCanPlay = () => {
      console.log("Video can play");
      setIsVideoLoading(false);
    };

    const handleLoadedMetadata = () => {
      console.log("Metadata loaded");
      if (videoElement.readyState >= 2) {
        setIsVideoLoading(false);
      }
    };

    const handleLoadedData = () => {
      console.log("Loaded data");
      setIsVideoLoading(false);
    };

    const handleError = (e: Event) => {
      console.error("Video loading error", e);
      setIsVideoLoading(false);
    };

    videoElement.addEventListener("canplay", handleCanPlay);
    videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    videoElement.addEventListener("loadeddata", handleLoadedData);
    videoElement.addEventListener("error", handleError);

    if (videoElement.readyState >= 2) {
      setIsVideoLoading(false);
    }

    return () => {
      videoElement.removeEventListener("canplay", handleCanPlay);
      videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.removeEventListener("loadeddata", handleLoadedData);
      videoElement.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <div className={`${styles["photobook__container"]} pb-8`}>
      {groupedMedia?.map((element: any, index: number) => {
        return (
          <div className={styles["photobook__card__container"]} key={uuidv4()}>
            {showSelected && !element.isAlbum && (
              <div className={styles["photobook__card__checkbox"]}>
                <input
                  type="checkbox"
                  id={`checkbox-${element.id}`}
                  checked={selected.includes(element.id)}
                  onChange={() => handleChange(element.id)}
                />
                <label htmlFor={`checkbox-${element.id}`}></label>
              </div>
            )}
            {element?.type?.includes("image/") ? (
              <div className={styles["photobook__card__picture"]}>
                <Icon iconName="picture" />
              </div>
            ) : null}

            {element.isAlbum ? (
              <Album
                element={element}
                setSelectedAlbum={(album) => {
                  setSelectedAlbum?.({
                    id: album?.id,
                    name: album!.name,
                  });
                  setTabState("albums");
                }}
              />
            ) : (
              <div
                className={`${styles["photobook__card"]} relative !h-[260px] max-h-full w-full !overflow-hidden`}
              >
                {!element.isAlbum && (
                  <div className={styles["photobook__card__delete"]}>
                    <Popover.Root>
                      <Popover.Trigger
                        className={
                          styles["photobook__dropdown-container-button"]
                        }
                      >
                        <MenuHorizontal />
                        <span className="visually-hidden">open dropdown</span>
                      </Popover.Trigger>
                      <Popover.Content
                        className={styles["photobook__dropdown"]}
                      >
                        <div className={styles["photobook__dropdown-list"]}>
                          <div
                            onClick={() => handleSetHeroImage(element.url)}
                            className={styles["photobook__dropdown-list__btn"]}
                          >
                            Set as hero image
                          </div>
                          {isLuxeEvent && (
                            <div
                              onClick={() => {
                                console.log("pass image");
                                setPassImage({
                                  variables: {
                                    eventId: event.uuid,
                                    image: element.url,
                                  },
                                });
                              }}
                              className={
                                styles["eventGallery__dropdown-list__btn"]
                              }
                            >
                              Set as pass image
                            </div>
                          )}

                          <div
                            onClick={() => handleDelete(element.id)}
                            className={
                              styles["eventGallery__dropdown-list__remove"]
                            }
                          >
                            Delete
                          </div>
                        </div>
                      </Popover.Content>
                    </Popover.Root>
                  </div>
                )}
                <DialogModal
                  key={uuidv4()}
                  disabled={showSelected}
                  className="!w-full"
                  trigger={
                    <>
                      {element?.type?.includes("video/") ? (
                        <>
                          {isVideoLoading ? (
                            <>
                              <img src={blurDataUrl()} />
                              <div className="bg-black/30 absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-[5.48px]">
                                <PlayIcon />
                              </div>
                            </>
                          ) : (
                            <>
                              <video
                                className="inline-block h-[260px] w-[260px] rounded-[5.48px] !object-cover"
                                controls={false}
                                autoPlay={false}
                                ref={videoRef}
                                src={element?.url}
                                preload="metadata"
                              >
                                {/* <source src={element?.url} type="video/mp4" /> */}
                                Your browser does not support the video tag.
                              </video>
                              <div className="bg-black/30 absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-[5.48px]">
                                <PlayIcon />
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <label htmlFor={`checkbox-${element.id}`}>
                          <StaticImage
                            src={element?.url}
                            alt={`event-${index + 1}`}
                            imageClassName="!object-cover rounded-[5.48px]"
                            className="!h-full !w-full"
                            scrollPosition={scrollPosition}
                          />

                          <img
                            src={element?.url}
                            alt={`mage`}
                            className="h-[184px] rounded-[5.48px] object-cover sm:w-[260px]"
                          />
                        </label>
                      )}
                    </>
                  }
                >
                  <div className="h-[591px] overflow-hidden rounded-md bg-[white]/30 backdrop-blur-3xl">
                    {element?.type?.includes("video/") ? (
                      <video
                        id="video-thumbnail"
                        preload="none"
                        className="h-full w-full rounded-md object-cover transition-all duration-300"
                        controls
                        autoPlay
                      >
                        <source src={element?.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <StaticImage
                        src={element?.url}
                        alt={`img-${index + 1}`}
                        imageClassName="!object-cover"
                        className="!flex h-full !w-auto items-center justify-center"
                      />
                    )}
                  </div>
                </DialogModal>
              </div>
            )}
          </div>
        );
      })}
      {selectedAlbum && (
        <AddPictures
          trigger={
            <div className="flex h-[260px] min-h-[160px] w-full cursor-pointer flex-col items-center justify-center rounded-[6px] border border-dashed border-[#616161] px-[22px] sm:w-[260px] sm:px-[35px]">
              <AddFileIcon />
              <p className="mt-[14px] text-center text-[8px] font-medium leading-[9px] text-[#919191] sm:mt-[23px] sm:text-[11px] sm:leading-[13px]">
                ADD A NEW PHOTO/VIDEO
              </p>
              <p className="mt-[8px] text-center text-[10px] font-normal leading-[12px] text-[#898989] sm:mt-[13px] sm:text-[12px] sm:leading-[18px]">
                Organize your people and guests, and start treating them as
                groups e.g brides family
              </p>
            </div>
          }
          album={selectedAlbum}
          type="photo"
        />
      )}
    </div>
  );
};

export default trackWindowScroll(ImageList);
