import { cn } from "src/utils";
import { Spinner } from "../Spinner";
import styles from "./button.module.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  loader?: boolean;
  design?: "orange" | "primary" | "secondary" | "tertiary";
  fontSize?: string;
  wrapperBtnClassName?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  label,
  onClick,
  disabled,
  loader,
  design = "primary",
  className,
  wrapperBtnClassName,
  fontSize,
  style,
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={label}
      type={type}
      className={cn(
        design === "primary" && styles["button"],
        design === "tertiary" && styles["button--tertiary"],
        design === "secondary" && styles["button--secondary"],
        design === "orange" && styles["button--orange"],
        wrapperBtnClassName && wrapperBtnClassName
      )}
      disabled={loader || disabled}
      style={style}
    >
      <span
        className={cn(fontSize && `text-${fontSize}`, className && className)}
      >
        {loader ? <Spinner /> : children}
      </span>
    </button>
  );
};

export default Button;
