import Icon from "src/components/Icon";

interface CostDisplayProps {
  cost: number | undefined;
}
const CostDisplay = ({ cost }: CostDisplayProps) => {
  return (
    <div className="flex justify-between rounded border-[0.5px] border-dashed border-[#F59E0B] bg-[#FFFBEB] px-4 py-3 text-sm">
      <div className="flex items-center gap-2">
        <p>Amount</p>
        <div>
          <Icon iconName="roundCaution" />
        </div>
      </div>
      <p className="font-medium">₦{cost?.toLocaleString()}</p>
    </div>
  );
};

export default CostDisplay;
