interface IconProps {
  fill?: string;
}

const Calendar = ({ fill }: IconProps) => {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2608 2.60165H23.1569C23.8703 2.60165 24.5536 2.88502 25.0576 3.38905C25.5616 3.89307 25.845 4.57629 25.845 5.28977V17.8343C25.845 18.5478 25.5616 19.231 25.0576 19.7351C24.5536 20.2391 23.8703 20.5225 23.1569 20.5225H3.44398C2.7305 20.5225 2.04728 20.2391 1.54326 19.7351C1.03924 19.231 0.755859 18.5478 0.755859 17.8343V5.28977C0.755859 4.57629 1.03923 3.89307 1.54326 3.38905C2.04728 2.88503 2.7305 2.60165 3.44398 2.60165H4.34002V1.70561C4.34002 1.21054 4.74099 0.80957 5.23606 0.80957C5.73113 0.80957 6.1321 1.21054 6.1321 1.70561V2.60165H12.4044V1.70561C12.4044 1.21054 12.8054 0.80957 13.3004 0.80957C13.7955 0.80957 14.1965 1.21054 14.1965 1.70561V2.60165H20.4688V1.70561C20.4688 1.21054 20.8697 0.80957 21.3648 0.80957C21.8599 0.80957 22.2608 1.21054 22.2608 1.70561V2.60165Z"
        fill={fill}
      />
      <path
        d="M17.9187 12.1356L15.2817 15.2597C14.7692 15.8757 14.0092 16.2321 13.2077 16.2321C12.4062 16.2321 11.6462 15.8757 11.1335 15.2597L8.60806 12.2169C8.15573 11.6744 7.92547 10.9806 7.96293 10.2754C8.00062 9.5699 8.30345 8.90486 8.81106 8.41357C9.36018 7.9075 10.0955 7.65385 10.8394 7.7137C11.5867 7.76821 12.2758 8.13674 12.7361 8.7279L13.2635 9.35673L13.7098 8.81908C14.1486 8.26563 14.781 7.89916 15.4794 7.79366C16.1777 7.68848 16.8901 7.85173 17.4726 8.25109C18.0474 8.70749 18.4132 9.3771 18.4868 10.1073C18.5825 10.8326 18.3773 11.5656 17.9188 12.1356H17.9187Z"
        fill="white"
      />
    </svg>
  );
};

export { Calendar };
