import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import Dialog from "src/components/Dialog";
import Icon from "src/components/Icon";
import { eventVendor_eventVendor } from "src/types/api";
import EditPlanner from "../modals/EditPlanner";
import RemovePlanner from "../modals/RemovePlanner";
import vendorCard from "./plannerCard.module.scss";

interface PlannerCardProps {
  page: number;
  pageSize: number;
  vendor: eventVendor_eventVendor | null;
}

const PlannerCard: React.FC<PlannerCardProps> = ({
  page,
  pageSize,
  vendor,
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <div className={vendorCard["planner__card"]}>
      <p>{vendor?.name}</p>
      <p>{vendor?.role}</p>
      <p>{vendor?.instagram_handle ?? "Not available"}</p>
      <p>{vendor?.phone_number}</p>
      <div className={vendorCard["planner__dropdown-container"]}>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <div className={vendorCard["planner__dropdown__icon"]}>
              <Icon iconName="kebab" />
              <span className="visually-hidden">open dropdown</span>
            </div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content
            className={vendorCard["planner__dropdown__content"]}
          >
            {!!vendor && (
              <>
                <Dialog
                  trigger={
                    <div className={vendorCard["planner__edit"]}>
                      Edit Vendor
                    </div>
                  }
                  open={openEdit}
                  onOpenChange={setOpenEdit}
                >
                  <EditPlanner
                    vendor={vendor}
                    setOpen={setOpenEdit}
                    page={page}
                    pageSize={pageSize}
                  />
                </Dialog>
                <Dialog
                  trigger={
                    <div className={vendorCard["planner__remove"]}>
                      Delete Vendor
                    </div>
                  }
                  open={openDelete}
                  onOpenChange={setOpenDelete}
                >
                  <RemovePlanner
                    vendor={vendor}
                    setOpen={setOpenDelete}
                    page={page}
                    pageSize={pageSize}
                  />
                </Dialog>
              </>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

export default PlannerCard;
