import { useMutation } from "@apollo/client";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DialogModal from "src/components/Dialog";
import { Spinner } from "src/components/Spinner";
import { UpdateDoneStatus } from "src/graphql/mutations/event.mutation";
import { GetCustomMyPlanTask } from "src/graphql/queries/event.queries";
import useAppStore from "src/store/utils";
import { updateDoneStatus, updateDoneStatusVariables } from "src/types/api";
import ViewTask from "../view-task";
import styles from "./addendumCard.module.scss";

type Props = {
  done: boolean;
  description: string;
  text: string;
  date: string;
  eventUUID?: string;
  id: number | string;
};

const AddendumCard: React.FC<Props> = (props) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const { event } = useAppStore((state) => state);

  const navigate = useNavigate();
  const [updateDoneFn, { loading }] = useMutation<
    updateDoneStatus,
    updateDoneStatusVariables
  >(UpdateDoneStatus, {
    onCompleted() {
      toast.success(<p>Successfully updated task.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div
      className={`${styles["addendumCard__container"]} ${styles["addendumCard__container__added"]}`}
    >
      <input
        checked={props.done}
        onChange={() =>
          updateDoneFn({
            variables: {
              eventId: event.id,
              isDone: !props.done,
              taskId: props.id,
            },
            refetchQueries: [
              {
                query: GetCustomMyPlanTask,
                variables: {
                  eventId: event.id,
                },
              },
            ],
          })
        }
        type="checkbox"
        className={`${styles["addendumCard__checkbox"]} ${
          props.done ? styles["addendumCard__checkbox__checked"] : ""
        }`}
      />
      <div className={styles["addendumCard__deck"]}>
        <DialogModal
          trigger={
            <p
              className={`${styles["addendumCard__text"]} ${
                props.done ? styles["addendumCard__text__checked"] : ""
              }`}
            >
              {loading && (
                <span>
                  <Spinner />
                </span>
              )}
              {props.text}
            </p>
          }
          open={openEdit}
          onOpenChange={setOpenEdit}
        >
          <ViewTask
            done={props.done}
            text={props.text}
            date={props.date}
            description={props.description}
            setOpen={setOpenEdit}
            id={`${props.id}`}
          />
        </DialogModal>
      </div>
    </div>
  );
};

export default AddendumCard;
