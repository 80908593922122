import { Form, Formik, FormikProps } from "formik";
import Button from "src/components/Button";
import Input from "src/components/Input";
import * as Yup from "yup";
import styles from "./styles.module.scss";
// import { AddPartType } from "src/utils/types";
import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { EditEventPart } from "src/graphql/mutations";
import { EventParts } from "src/graphql/queries";
import MapView, {
  PlacesAutoComplete,
} from "src/pages/event-schedule/modals/AddPart/Map";
import useAppStore from "src/store/utils";
import {
  eventParts_eventParts,
  updateEventPart,
  updateEventPartVariables,
} from "src/types/api";
import { getUtcOffset } from "src/utils";
import { getTimestamp } from "src/utils/GetTimeStamp";

interface Values {
  name: string;
  location: string;
  date: string;
  time: string;
  geo_position: {
    geo_position_id: string;
    latitude: string;
    longitude: string;
  };
}

const EditPartSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  location: Yup.string().required("Please input your event location"),
  date: Yup.date()
    .min(new Date().toISOString(), "You cannot choose a past date")
    .required("Date is required"),
  time: Yup.string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/,
      "Time must be in HH:mm format"
    )
    .required("Time is required"),
});

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  part: eventParts_eventParts;
}

function EditPart({ setOpen, part }: Props) {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();
  const [coords, setCoords] = useState<any>({
    latitude: Number(part?.geo_position?.latitude),
    longitude: Number(part?.geo_position?.longitude),
    place_id: part?.geo_position?.geo_position_id,
  });

  const [updateEventPart, { loading: updateEventPartLoading }] = useMutation<
    updateEventPart,
    updateEventPartVariables
  >(EditEventPart, {
    onCompleted() {
      toast.success(<p>Successfully Updated</p>);
      setOpen(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error occurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["editPart"]}>
      <h1>Edit Part</h1>
      <Formik
        enableReinitialize
        initialValues={{
          name: part?.name || "",
          location: part?.address || "",
          date:
            getUtcOffset(part?.time as unknown as Date).format(
              "YYYY-MM-DDTHH:mm"
            ) || "",
          time:
            getUtcOffset(part?.time as unknown as Date).format("HH:mm") || "",
          geo_position: {
            geo_position_id: part?.geo_position?.geo_position_id,
            latitude: part?.geo_position?.latitude,
            longitude: part?.geo_position?.longitude,
          },
        }}
        validationSchema={EditPartSchema}
        onSubmit={(values, actions) => {
          const combinedValue = getTimestamp(
            new Date(values.date),
            values.time
          );
          updateEventPart({
            variables: {
              input: {
                event_id: event.id,
                id: part.id,
                time: combinedValue.toString(),
                name: values.name,
                part: values.name,
                address: values.location,
                geo_position: {
                  geo_position_id: coords.place_id,
                  latitude: coords.latitude,
                  longitude: coords.longitude,
                },
              },
            },
            refetchQueries: [
              {
                query: EventParts,
                variables: { eventId: event.id, page: 1, pageSize: 50 },
              },
            ],
          });
          actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["editPart-form"]}>
            <div className={styles["editPart-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Name"
                placeholder="e.g Mike and Ife’s Engagement"
              />
              {/* <Input
                type="text"
                name="location"
                label="Location"
                placeholder="Enter Location"
              /> */}
              <PlacesAutoComplete setCoords={setCoords} name="location" />
              <MapView
                latitude={coords?.latitude}
                longitude={coords?.longitude}
              />
              {/* <Input
                type="datetime-local"
                name="date"
                label="Date"
                placeholder="Select Date"
                min={new Date().toISOString()}
              /> */}
              <div
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                <label htmlFor="date">Date</label>
                <DatePicker
                  name="date"
                  placeholder="Enter Date"
                  defaultValue={new Date()}
                  valueFormat={{ dateStyle: "medium" }}
                  value={props.values.date ? new Date(props.values.date) : null}
                  onChange={(value) => props.setFieldValue("date", value)}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                <label htmlFor="date">Time</label>
                <input
                  type="time"
                  name="time"
                  placeholder="Enter Time"
                  style={{
                    backgroundColor: "#FAFAFA",
                    border: "1px solid #f2f2f2",
                    borderRadius: "5px",
                    height: "47px",
                    padding: "0 10px",
                  }}
                  min={new Date().toISOString()}
                  value={props.values.time}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className={styles["editPart-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Update Part"
                  disabled={
                    props.values.name === "" ||
                    props.values.location === "" ||
                    props.values.date === "" ||
                    !props.isValid ||
                    props.isSubmitting
                  }
                  loader={updateEventPartLoading}
                >
                  Update Part
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditPart;
