import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { EstimateInvitationCost } from "src/graphql/mutations/invitation.mutation";
import useAppStore from "src/store/utils";
import {
  Channel,
  estimateInvitationCost,
  estimateInvitationCostVariables,
  invitationListFilter,
} from "src/types/api.d";
import DeliveryMethodSelector from "./components/DeliveryMethodSelector";
import InviteSent from "./components/InviteSent";
import PaymentOptions from "./components/PaymentOptions";
import SendInvitesPrompt from "./components/SendInvitesPrompt";

interface SendInvitesOptionsModalProps {
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  noOfGuests: number | undefined;
  invitationIDs: string[];
  invitationFilter: invitationListFilter;
}

export type SendInvitesStepsTypes =
  | "devliveryMethod"
  | "sendInvitesPrompt"
  | "paymentOptions"
  | "inviteSent";

const SendInvitesOptionsModal = ({
  setOpenInviteModal,
  invitationIDs,
  noOfGuests,
  invitationFilter,
}: SendInvitesOptionsModalProps) => {
  const [step, setStep] = useState<SendInvitesStepsTypes>("devliveryMethod");
  const [cost, setCost] = useState<number>(0);
  const [channel, setChannel] = useState<string>("email");
  const navigate = useNavigate();
  const { event } = useAppStore((state) => state);

  const [estimateInvitationCostFn, { loading: estimateInvitationLoader }] =
    useMutation<estimateInvitationCost, estimateInvitationCostVariables>(
      EstimateInvitationCost,
      {
        onCompleted(res) {
          setCost(res?.estimateInvitationCost?.cost ?? 0);
        },
        onError(error) {
          if (error?.message !== "unauthenticated") {
            toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
          } else {
            navigate("/clear");
          }
        },
      }
    );

  const [
    estimateWhatsappInvitationCostFn,
    { loading: estimateWhatsappInvitationLoader, data: whatsappEstimate },
  ] = useMutation<estimateInvitationCost, estimateInvitationCostVariables>(
    EstimateInvitationCost,
    {
      onCompleted(res) {
        setCost(res?.estimateInvitationCost?.cost ?? 0);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  useEffect(() => {
    if (!!channel.length) {
      estimateInvitationCostFn({
        variables: {
          eventUuid: event.uuid,
          req: {
            all: false,
            pending: false,
            channel: channel as Channel,
            number_of_guests: noOfGuests!,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, noOfGuests, event.uuid]);

  useEffect(() => {
    estimateWhatsappInvitationCostFn({
      variables: {
        eventUuid: event.uuid,
        req: {
          all: false,
          pending: false,
          channel: Channel.whatsapp,
          number_of_guests: noOfGuests!,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfGuests, event.uuid]);

  return (
    <div className="w-[100%] rounded-lg bg-white lg:w-[470px]">
      {step === "devliveryMethod" && (
        <DeliveryMethodSelector
          whatsappRateDesc={`Whatsapp charges a fee of $ ${
            whatsappEstimate?.estimateInvitationCost?.usd_rate?.toFixed(2) ?? 0
          } ~ ₦ ${
            whatsappEstimate?.estimateInvitationCost?.rate?.toFixed(2) ?? 0
          }  per message`}
          setOpenInviteModal={setOpenInviteModal}
          setStep={setStep}
          estimateInvitationLoader={
            estimateInvitationLoader || estimateWhatsappInvitationLoader
          }
          setChannel={setChannel}
          channel={channel}
        />
      )}
      {step === "sendInvitesPrompt" && (
        <SendInvitesPrompt
          setStep={setStep}
          noOfGuests={noOfGuests}
          cost={channel === "email" ? 0 : cost}
          channel={channel}
          invitationIDs={invitationIDs}
          estimateInvitationLoader={estimateInvitationLoader}
        />
      )}
      {step === "paymentOptions" && (
        <PaymentOptions
          invitationFilter={invitationFilter}
          cost={cost}
          channel={channel}
          setOpenInviteModal={setOpenInviteModal}
          setStep={setStep}
          invitationIDs={invitationIDs}
        />
      )}
      {step === "inviteSent" && (
        <InviteSent channel={channel} setOpenInviteModal={setOpenInviteModal} />
      )}
    </div>
  );
};

export default SendInvitesOptionsModal;
