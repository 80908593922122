import { useMutation } from "@apollo/client";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SendInvitations } from "src/graphql/mutations";
import { FilterInvitationList } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import {
  Channel,
  invitationListFilter,
  sendInvitations,
  sendInvitationsVariables,
} from "src/types/api.d";
import { SendInvitesStepsTypes } from "..";
import CostDisplay from "./CostDisplay";
import SendInvitesModalFooter from "./SendInvitesModalFooter";

interface SendInvitesPromptProps {
  setStep: React.Dispatch<React.SetStateAction<SendInvitesStepsTypes>>;
  noOfGuests: number | undefined;
  cost: number | undefined;
  invitationIDs: string[];
  estimateInvitationLoader: boolean;
  channel: string;
}

const SendInvitesPrompt = ({
  setStep,
  noOfGuests,
  cost,
  invitationIDs,
  estimateInvitationLoader,
  channel,
}: SendInvitesPromptProps) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [sendInvitation, { loading }] = useMutation<
    sendInvitations,
    sendInvitationsVariables
  >(SendInvitations, {
    onCompleted() {
      setStep("inviteSent");
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const handleClick = () => {
    if (cost === 0) {
      sendInvitation({
        variables: {
          eventId: event.uuid,
          req: {
            all: false,
            pending: false,
            channel: channel as Channel,
            list_id: invitationIDs,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: FilterInvitationList,
            fetchPolicy: "network-only",
            variables: {
              eventId: event.id,
              page: 1,
              pageSize: 1000,
              filter: invitationListFilter.not_sent,
            },
          },
        ],
      });
    } else {
      setStep("paymentOptions");
    }
  };
  return (
    <>
      <div className="flex flex-col gap-6 px-5 pb-4 pt-10 lg:px-10">
        <h1 className="text-2xl font-medium">Send Invites</h1>
        <CostDisplay cost={cost} />
        <p>
          You are about to send invites to {noOfGuests} guests. Do you wish to
          proceed?
        </p>
      </div>
      <SendInvitesModalFooter
        firstButtonText={"Go Back"}
        firstButtonFunction={() => setStep("devliveryMethod")}
        secondButtonText={"Continue"}
        secondButtonLoader={loading}
        secondButtonFunction={() => handleClick()}
        disableSecondButton={estimateInvitationLoader}
      />
    </>
  );
};

export default SendInvitesPrompt;
