import { useQuery } from "@apollo/client";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import { GetEventInfo } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { GetEventInfo as GetEventInfoType } from "src/types/api";
import AddDetails from "../modals/AddDetails";
import EditDetails from "../modals/EditDetails";
import styles from "./styles.module.scss";

const GeneralInformation: React.FC = () => {
  const { event } = useAppStore((state) => state);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: event_details, loading } = useQuery<GetEventInfoType>(
    GetEventInfo,
    {
      variables: {
        eventEventId2: event.id,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error.message ?? "No Tags Set"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  return (
    <div className={styles["general-information"]}>
      {loading || !event_details ? (
        <div className={styles["coming__loading"]}>
          <InnerLoading />
        </div>
      ) : (
        <div className={`${styles["general-information__wrapper"]}`}>
          <div>
            <DialogModal
              trigger={
                <div
                  onClick={() => setOpenEdit(true)}
                  className={styles["general-information--btn__trigger"]}
                >
                  <span>Edit Information</span>
                </div>
              }
              open={openEdit}
              onOpenChange={setOpenEdit}
            >
              <EditDetails details={event_details} setOpen={setOpenEdit} />
            </DialogModal>
          </div>

          <div className={styles["general-information--box"]}>
            <h1>{`${event_details?.event?.name}`}</h1>

            {!!event_details?.event?.welcome_msg ? (
              <div className={styles["general-information--box__msg"]}>
                {`"${event_details?.event?.welcome_msg}"`}
              </div>
            ) : (
              <div className={styles["general-information--box__add"]}>
                You haven't written a welcome message yet
                <div>
                  <DialogModal
                    trigger={
                      <div
                        onClick={() => setOpenAdd(true)}
                        className={
                          styles["general-information--box__add__trigger"]
                        }
                      >
                        <span>Add message</span>
                      </div>
                    }
                    open={openAdd}
                    onOpenChange={setOpenAdd}
                  >
                    <AddDetails details={event_details} setOpen={setOpenAdd} />
                  </DialogModal>
                </div>
              </div>
            )}
            <div className={styles["general-information--hashtag"]}>
              {`${event_details?.event?.hash_tag}` || "Add a hashtag"}
            </div>
            {!!event_details?.event?.slug ? (
              <a
                target="_blank"
                href={`https://${event_details?.event?.slug}.${process.env.REACT_APP_INAWO_WEDDING_SITE_END}/`}
                rel="noreferrer"
              >
                <div className={styles["general-information--slug"]}>
                  <Icon iconName="link" />{" "}
                  {event_details?.event?.slug ?? "Add a slug"}
                </div>
              </a>
            ) : (
              <div className={styles["general-information--slug"]}>
                <Icon iconName="link" />{" "}
                {event_details?.event?.slug ?? "Add a slug"}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralInformation;
