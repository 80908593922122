import { cn } from "src/lib/utils";
const PlayIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cn("h-12 w-12 cursor-pointer text-white", className)}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.752 11.168l-6.076-3.477A1 1 0 007 8.56v6.878a1 1 0 001.676.819l6.076-3.477a1 1 0 000-1.638z"
    />
  </svg>
);
export default PlayIcon;
