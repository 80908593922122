import { QRCodeCanvas } from "qrcode.react";
import { InawoPassIcon } from "src/layouts/dashboard/Icons/PassDesign";

interface PassImagePreviewProps {
  passImage?: string;
  eventSlug?: string;
  guestCode?: string;
  qrCodeValue?: string;
}

const PassImagePreview = ({
  passImage,
  eventSlug,
  guestCode,
  qrCodeValue,
}: PassImagePreviewProps) => {
  return (
    <div className="rounded-0 mx-auto flex h-[100vh] w-full flex-col items-center justify-center gap-[20px] bg-gradient-to-br from-[#026051] via-[#47A092CC] to-[#3B5F5900] px-[50px] lg:h-[500px] lg:w-[450px] lg:rounded-[8px] lg:bg-none">
      <div
        className="flex h-[400px] w-[280px] flex-col items-center justify-between rounded-[21px] border border-[#D9D9D9] px-[25px] py-[9px]"
        style={{
          backgroundImage: `url(${passImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="my-[11px] text-[11px] font-medium text-white">
          #{eventSlug}
        </p>
        <div
          className="mx-auto flex h-[245px] w-[80%] flex-col items-center justify-between rounded-[22px] bg-[#FFFFFFCC] px-[10px] py-[10px]"
          style={{ backdropFilter: "blur(3px)" }}
        >
          <div>
            <p className="mb-[2px] text-[9px] text-[#4D4D4DE8]">Guest code</p>
            <p className="text-[10px] text-[#4D4D4D4D]">
              {guestCode || "TWE2110"}
            </p>
          </div>
          <div className="h-[105px] w-[105px] rounded-[20px] border border-[#3D516080] bg-gradient-to-b from-[#E6FA5200] to-[#E6FA5233] p-[20px]">
            <QRCodeCanvas value="TWE2110" fgColor="#4F7770" size={65} />
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="mb-[2px] text-center text-[10px] text-[#4D4D4D99]">
              This is your pass to the wedding
            </p>
            <InawoPassIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassImagePreview;
