import { motion } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import CloseV2 from "src/assets/icons/closeV2";
import RetryIcon from "src/assets/icons/retryIcon";
import { UploadValue } from "src/utils/types";
import Dancer from "../../../assets/images/01.png";

const UploadingView = ({
  fileUploaded,
  setFileUploaded,
  setStep,
  percentageCompleted,
  totalTimeLeft,
  isCompleted,
  setRetryUploads,
  onClose,
}: {
  fileUploaded: UploadValue;
  setFileUploaded: Dispatch<SetStateAction<UploadValue>>;
  setStep: Dispatch<SetStateAction<number>>;
  percentageCompleted: number;
  totalTimeLeft: number;
  isCompleted: boolean;
  setRetryUploads: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  return (
    <motion.div className="flex w-full flex-col px-6 md:px-[81px]">
      <p className="mb-[15px] text-[15px] font-[500] text-[#71717A]">
        Uploading {fileUploaded?.length} items
      </p>
      <div className="flex flex-wrap gap-[6px]">
        {fileUploaded.slice(0, 8).map(({ file, preview }: any, idx: number) => (
          <div
            key={idx}
            className="relative flex h-[55px] w-[55px] items-center justify-center overflow-hidden rounded-[9px]"
          >
            {file?.type?.includes("video/") ? (
              <video preload="none" className="h-full w-full object-cover">
                <source src={preview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={preview || Dancer}
                alt={`pov ${idx}`}
                className="h-full w-full object-cover"
              />
            )}
            {fileUploaded?.length > 8 && idx === 7 && (
              <div className="dark:text-black absolute left-0 top-0 z-[1] grid h-full w-full place-items-center bg-[rgba(0,0,0,0.3)] text-lg font-semibold text-white">
                <span>+{fileUploaded?.length - 3}</span>
              </div>
            )}
          </div>
        ))}
        <div className="relative mt-3 h-[3.28px] w-full rounded-[6px] bg-[#F2F2F2]">
          <span
            className={`absolute h-full rounded-[6px] bg-[#4E7E76]`}
            style={{
              width: `${percentageCompleted}%`,
            }}
          />
        </div>
        <div>
          <p className="mb-[18px] mt-3 !text-[14px] text-xs font-[500] text-[rgba(113,113,122,0.51)] dark:text-[rgba(113,113,122,1)]">
            Time remaining:{" "}
            <span className="text-[#4E7E76]">{totalTimeLeft}sec</span>
          </p>
          <div className="flex items-center gap-[5px]">
            {percentageCompleted === 100 && !isCompleted && (
              <div
                className="flex h-[29px] w-[29px] items-center justify-center rounded-[10px] bg-[#F2F4F3] text-[#71717A]"
                role="button"
                onClick={() => {
                  setRetryUploads(true);
                }}
              >
                <RetryIcon className="text-[#FFF1F1]" />
              </div>
            )}
            <div
              className="flex h-[29px] w-[29px] items-center justify-center rounded-[10px] bg-[#FFF1F1] text-[#DC2626]"
              role="button"
              onClick={onClose}
            >
              <CloseV2 className="stroke-[#DC2626]" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UploadingView;
