import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { AccessControlIcon } from "src/components/Icons";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import { UpgradeToLuxe, VerifyTransaction } from "src/graphql/mutations";
import { GetUsername } from "src/graphql/queries";
import { GetEventWalletsV1 } from "src/graphql/queries/wallets.queries";
import useAppStore from "src/store/utils";
import {
  getUsername,
  getWalletsV1,
  getWalletsV1Variables,
  upgradeToLuxevariables,
  verifyTransaction,
  verifyTransactionVariables,
} from "src/types/api.d";
import SendInvitesModalFooter from "./PayForLuxeModalFooter";
import PaymentOption from "./PaymentOption";

interface PaymentOptionsProps {
  setOpen: (value: boolean) => void;
  cost: number | undefined;
}

const PaymentOptions = ({ setOpen, cost }: PaymentOptionsProps) => {
  const [active, setActive] = useState<string>("");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<getUsername>();
  const contextValue = useContext<ValueProps | null>(AppContext);
  const isAdmin = contextValue?.isAdmin;
  const { event } = useAppStore((state) => state);

  useQuery<getUsername>(GetUsername, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setUserDetails(response);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const fullName = `${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`;

  const updateActiveElement = (id: string) => {
    setActive(active !== id ? id : "");
  };

  const [payForLuxe, { loading }] = useMutation<upgradeToLuxevariables>(
    UpgradeToLuxe,
    {
      onCompleted() {
        toast.success(
          <p className="toast">{"Inawo Luxe payment successful"}</p>
        );
        setOpen(false);
        navigate(0);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const upgradeToLuxe = () => {
    payForLuxe({
      variables: {
        eventId: event.uuid,
      },
    });
  };

  const [verifyTransaction] = useMutation<
    verifyTransaction,
    verifyTransactionVariables
  >(VerifyTransaction, {
    onCompleted: (res) => {
      if (res.verifyTransaction.reference) {
        setTimeout(() => {
          upgradeToLuxe();
        }, 100);
      }
    },
    onError(error) {
      console.log(error);
      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  const paystackCharge = React.useMemo(() => {
    const preTotal = parseInt(`${cost}`);
    const percentCharge = 0.015 * preTotal;
    const fullCharge = percentCharge + 100;

    if (preTotal < 2500) {
      return { charge: percentCharge };
    } else if (fullCharge > 2000) {
      return { charge: 2000, with100: true };
    } else {
      return { charge: fullCharge, with100: true };
    }
  }, [cost]);

  const payStackConfigMetaData = {
    user_id: userDetails?.user?.id ?? "",
    event_id: event.id,
    trans_type: "credit",
    currency: "NGN",
    description: "Upgrade to luxe",
    creditor_alias: fullName,
    charges: paystackCharge?.charge,
    amount: cost,
    kind: "luxe-fee",
  };

  let paystackconfigData = {
    reference: new Date().getTime().toString(),
    email: userDetails?.user?.email ?? "",
    amount: parseInt(`${Number(cost) * 100}`),
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || "",
    metadata: {
      ...payStackConfigMetaData,
      custom_fields: Object.entries(payStackConfigMetaData)?.map(([k, v]) => {
        return {
          display_name: k,
          variable_name: k,
          value: v,
        };
      }),
    },
  };

  const onSuccessWithPaystack = () => {
    verifyTransaction({
      variables: {
        reference: paystackconfigData.reference,
      },
    });
  };

  let initializePaymentWithPaystack = usePaystackPayment(paystackconfigData);

  // continue with pastack payment option
  const handleContinue = () => {
    if (active === "paystack") {
      setOpen(false);
      initializePaymentWithPaystack(onSuccessWithPaystack);
    } else {
      upgradeToLuxe();
    }
  };

  // fetch event wallet details
  const { data: eventWalletDetails, loading: eventWalletLoading } = useQuery<
    getWalletsV1,
    getWalletsV1Variables
  >(GetEventWalletsV1, {
    skip: !event.id || !isAdmin,
    variables: {
      eventId: event.id,
    },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const inawaoNairaBalance = eventWalletDetails?.getWalletsV1[1]?.balance ?? 0;

  return (
    <div className="flex h-auto max-w-[500px] flex-col items-center gap-[30px] rounded-[5px] bg-white">
      <div className="flex w-full flex-col justify-center pb-1 pt-10 lg:px-10">
        <div className="mx-auto mb-7 flex items-center gap-[20px]">
          <AccessControlIcon />
          <h3 className="text-[20px] font-medium text-[#298576]">
            Access control
          </h3>
        </div>
        <h1 className="mb-5 text-center text-xl font-medium">
          How would you want to pay?
        </h1>
        {/* <CostDisplay cost={cost} /> */}
        {eventWalletLoading ? (
          <div className="flex w-full justify-center py-8">
            <InnerLoading />
          </div>
        ) : (
          <div className="mb-4 flex w-full flex-col gap-4 pt-3">
            {!!inawaoNairaBalance && isAdmin ? (
              <PaymentOption
                active={active === "inawo Wallet"}
                onClick={() => updateActiveElement("inawo Wallet")}
                type="Inawo Wallet"
                description={`N${inawaoNairaBalance.toLocaleString()}`}
              />
            ) : (
              <></>
            )}
            <PaymentOption
              type="Paystack"
              active={active === "paystack"}
              onClick={() => updateActiveElement("paystack")}
            />
          </div>
        )}
      </div>
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          handleContinue();
        }}
        className="w-full"
      >
        <SendInvitesModalFooter
          firstButtonText={"Cancel"}
          firstButtonFunction={() => setOpen(false)}
          secondButtonText={"Continue"}
          secondButtonFunction={handleContinue}
          disableSecondButton={active === "" || eventWalletLoading}
          secondButtonLoader={active === "inawo Wallet" && loading}
        />
      </form>
    </div>
  );
};

export default PaymentOptions;
