import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { DeleteMessageById } from "src/graphql/mutations";
import { GetMessage } from "src/graphql/queries";
import useAppStore from "src/store/utils";
import { getMessageByUser_getMessageByUser } from "src/types/api";
import removeMessages from "./removeMessages.module.scss";

interface RemoveMessagesProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  page: number;
  pageSize: number;
  message: getMessageByUser_getMessageByUser | null;
}

const RemoveMessages = ({
  setOpen,
  message,
  page,
  pageSize,
}: RemoveMessagesProps) => {
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const [deleteMessageFn, { loading }] = useMutation(DeleteMessageById, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully deleted this message.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div
      className={`${removeMessages["removeMessages--content"]} ${removeMessages["show"]}`}
    >
      <h1>Delete Message</h1>
      <p>You are about to delete this message. Do you wish to proceed?</p>
      <span className={removeMessages["removeMessages--content-btnContainer"]}>
        <div>
          <Button
            type="submit"
            onClick={() =>
              deleteMessageFn({
                variables: {
                  eventId: event.id,
                  messageId: message?.id,
                },
                refetchQueries: [
                  {
                    query: GetMessage,
                    variables: {
                      eventId: event.id,
                      page: page,
                      pageSize: pageSize,
                    },
                  },
                ],
              })
            }
            label="Yes, Proceed"
            loader={loading}
          >
            Yes, Delete
          </Button>
        </div>
      </span>
    </div>
  );
};

export default RemoveMessages;
