import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";
import styles from "./styles.module.scss";

const qrCode = new QRCodeStyling({
  width: 85,
  height: 85,
  type: "canvas",
  data: "",
  image: "",
  dotsOptions: {
    color: "#4F7770",
    type: "rounded",
  },
  cornersSquareOptions: {
    color: "#4F7770",
    type: "extra-rounded",
  },
  cornersDotOptions: {
    color: "#4F7770",
    type: "dot",
  },
  backgroundOptions: {
    color: "transparent",
  },
  qrOptions: {
    errorCorrectionLevel: "H",
  },
});

const CustomQrCode = ({ code }: { code?: string }) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: code,
    });
  }, [code]);

  return (
    <div className={styles.qrCodeContainer}>
      <div className={styles.qrCode}>
        <div ref={ref} />
      </div>
    </div>
  );
};

export default CustomQrCode;
