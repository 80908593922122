import Button from "src/components/Button";

interface SendInvitesModalFooterProps {
  firstButtonText: string;
  firstButtonFunction: () => void;
  secondButtonText: string;
  secondButtonFunction: () => void;
  disableSecondButton?: boolean;
  secondButtonLoader?: boolean;
}

const SendInvitesModalFooter = ({
  firstButtonText,
  firstButtonFunction,
  secondButtonText,
  secondButtonFunction,
  disableSecondButton,
  secondButtonLoader,
}: SendInvitesModalFooterProps) => {
  return (
    <div className="flex justify-between rounded-b-lg bg-[#FAFAFA] px-5 py-6 lg:px-10">
      <div>
        <Button
          label={firstButtonText}
          type="button"
          design="secondary"
          className="text-sm"
          onClick={firstButtonFunction}
        >
          {firstButtonText}
        </Button>
      </div>
      <div>
        <Button
          label={secondButtonText}
          type="button"
          design="primary"
          className="text-sm font-extrabold"
          onClick={secondButtonFunction}
          disabled={disableSecondButton || secondButtonLoader}
          loader={secondButtonLoader}
        >
          {secondButtonText}
        </Button>
      </div>
    </div>
  );
};

export default SendInvitesModalFooter;
