import { useMutation } from "@apollo/client";
import { Form, Formik, FormikProps } from "formik";
import { Dispatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import { EditCustomMyPlanTask } from "src/graphql/mutations/event.mutation";
import { GetCustomMyPlanTask } from "src/graphql/queries/event.queries";
import useAppStore from "src/store/utils";
import {
  editCustomMyPlanTask,
  editCustomMyPlanTaskVariables,
} from "src/types/api";
import * as Yup from "yup";
import editTask from "./editTask.module.scss";

interface Values {
  name: string;
  description: string;
}

const GeneralFormSchema = Yup.object().shape({
  name: Yup.string().required("Task name is required"),
  description: Yup.string(),
});

interface EditTaskProps {
  id: string;
  text: string;
  done: boolean;
  description: string;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  setOpenView: Dispatch<React.SetStateAction<boolean>>;
}

const EditTask = ({
  id,
  text,
  description,
  done,
  setOpen,
  setOpenView,
}: EditTaskProps) => {
  const navigate = useNavigate();
  const [updateCustomMyPlanFn, { loading }] = useMutation<
    editCustomMyPlanTask,
    editCustomMyPlanTaskVariables
  >(EditCustomMyPlanTask, {
    onCompleted() {
      setOpen(false);
      setOpenView(false);
      toast.success(<p>Successfully edited task.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const { event } = useAppStore((state) => state);

  return (
    <div className={editTask["editTask--content"]}>
      <div className={editTask["editTask--content__top"]}>
        <h1>Edit Task</h1>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          name: text,
          description: description,
        }}
        validationSchema={GeneralFormSchema}
        onSubmit={(values) => {
          updateCustomMyPlanFn({
            variables: {
              description: values.description,
              eventId: event.id,
              taskId: id,
              taskName: values.name,
            },
            refetchQueries: [
              {
                query: GetCustomMyPlanTask,
                variables: {
                  eventId: event.id,
                },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={editTask["editTask--content-form"]}>
            <div className={editTask["editTask--content-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Task Name"
                placeholder="e.g Visit the Caterer"
              />
              <Textarea
                rows={4}
                name="description"
                label="Brief Description"
                placeholder="e.g Check on the caterer to see the progress of the cakes"
              />
            </div>
            <div className={editTask["editTask--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Save Changes"
                  loader={loading}
                  disabled={props.values.name === ""}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditTask;
