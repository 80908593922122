import { format } from "date-fns";
import { useMemo, useState } from "react";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import InnerLoading from "src/components/InnerLoading";
import Media from "src/components/Media";
import { GetGuestUploads } from "src/graphql/queries";
import { getFullName } from "src/helper/functions";
import useCustomQuery from "src/hooks/useCustomQuery";
import useAppStore from "src/store/utils";
import { GetPov } from "src/types/api";
import GalleryModal from "./gallery";

const GuestsUploads = () => {
  const { event } = useAppStore((state) => state);
  const [selectedPov, setSelectedPov] =
    useState<Partial<GetPov["getPov"][0]>>();
  const [selectedItem, setSelectedItem] = useState<{
    item: GetPov["getPov"][0]["media"][0];
    group: GetPov["getPov"][0]["media"];
  }>();

  const closeModal = () => {
    setSelectedItem(undefined);
    setSelectedPov(undefined);
  };

  const { data, isLoading: loading } = useCustomQuery<GetPov>(
    GetGuestUploads,
    { eventId: event.uuid },
    !event.uuid
  );

  const povs = useMemo(() => {
    const povs = data?.getPov ?? [];
    if (povs?.length) {
      povs.sort((a, b) => {
        const dateA = new Date(a.media[0].created_at);
        const dateB = new Date(b.media[0].created_at);
        return dateB.getTime() - dateA.getTime();
      });
    }
    const allPOvs = povs.map((item) => ({
      username: getFullName(item.guest.first_name, item.guest.last_name),
      created_at: item.media[0].created_at,
      media: item.media,
    }));

    const groupedPovs = allPOvs.reduce(
      (acc: Record<string, typeof allPOvs>, curr) => {
        const date = new Date(curr.created_at).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(curr);
        return acc;
      },
      {}
    );
    return groupedPovs;
  }, [data?.getPov]);

  return (
    <div className="mad:gap-8 flex flex-col gap-5">
      <GalleryModal
        closeModal={closeModal}
        setSelectedItem={setSelectedItem}
        selectedPov={selectedPov}
        selectedItem={selectedItem}
      />
      <div className="flex w-full items-center justify-between">
        {data?.getPov?.length ? (
          <div className="flex items-center justify-center gap-[3px] rounded-md border border-secondary-6 px-2.5 text-[#787777]">
            <Icon iconName="thumbnail" />
            <p>{data?.getPov.length}</p>
          </div>
        ) : (
          ""
        )}
        {/* <div className="flex items-center gap-[5.62px]">
          <div>
            <Button
              label="select"
              type="button"
              design="secondary"
              style={{
                borderRadius: "9.12px",
                height: "38px",
              }}
              className="text-[13.97px]"
            >
              <span> Share upload link</span>
            </Button>
          </div>
          <div>
            <Button
              label="delete"
              type="button"
              design="secondary"
              className="flex items-center gap-[5px] text-[13.97px]"
              style={{
                borderRadius: "9.12px",
                height: "38px",
              }}
            >
              <Icon iconName="capture" />
              <span className="hidden sm:block"> Generate Code</span>
            </Button>
          </div>
        </div> */}
      </div>
      {loading ? (
        <InnerLoading className="!h-[50vh]" />
      ) : (
        Object.keys(povs).map((item, index) => {
          return (
            <div key={index}>
              <div className="flex flex-col gap-4 md:gap-7">
                <p className="text-[13px] font-semibold uppercase leading-[15px] text-[#000]/60">
                  {format(new Date(item), "EEEE, dd MMM") ===
                  format(new Date(), "EEEE, dd MMM")
                    ? "Today"
                    : ""}{" "}
                  {format(new Date(item), "EEEE, dd MMM")}
                </p>

                {povs[item].map((pov, index) => (
                  <div key={index} className="max-w-[840px]">
                    <div className="flex flex-col gap-5 rounded-[18px] border border-[#CBCACA] px-4 py-[15px]">
                      <div className="flex h-[56.81px] items-center">
                        {pov.media.slice(0, 3).map((media, index) => (
                          <div key={index} className="relative h-full">
                            <Media
                              media={{
                                type: media.type ?? "",
                                url: media.url ?? "",
                              }}
                              className="h-full w-[58.77px] overflow-hidden rounded-l-[6.93px]"
                              imageClassName="object-cover h-full w-full"
                            />
                            <div className="absolute inset-y-0 right-0 w-[2px] rounded-bl-[6.93px] rounded-tl-[6.93px] bg-white"></div>
                          </div>
                        ))}
                        {pov.media.length > 3 && (
                          <div className="relative h-full">
                            <Media
                              media={{
                                type: pov.media[3].type ?? "",
                                url: pov.media[3].url ?? "",
                              }}
                              className="h-full w-[58.77px] overflow-hidden rounded-l-[6.93px]"
                              imageClassName="object-cover h-full w-full"
                            />
                            <p className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-[6.93px] bg-[#000]/40 text-center text-[17px] font-normal leading-[21px] text-white">
                              {pov.media.length - 3}+
                            </p>
                          </div>
                        )}
                      </div>
                      <p className="text-[14px] font-normal leading-[17px] text-[#000]">
                        {pov.username}{" "}
                        <span className="text-[#000]/50">
                          just uploaded {pov.media?.length} pictures
                        </span>
                      </p>
                      <div className="flex items-center gap-[5.47px]">
                        <div>
                          <Button
                            label="select"
                            type="button"
                            design="secondary"
                            style={{
                              borderRadius: "8.87px",
                              height: "30px",
                            }}
                            className="text-[13.58px]"
                            onClick={() => {
                              setSelectedPov({
                                guest: {
                                  last_name: pov.username,
                                } as GetPov["getPov"][0]["guest"],
                                media: pov.media,
                              });
                              setSelectedItem({
                                item: pov.media[0],
                                group: pov.media,
                              });
                            }}
                          >
                            <span>View</span>
                          </Button>
                        </div>
                        <div>
                          <a
                            href={`https://${event.slug}.${process.env.REACT_APP_INAWO_ALBUM_SITE_END}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              label="select"
                              type="button"
                              design="secondary"
                              className="text-[13.58px]"
                              style={{
                                borderRadius: "8.87px",
                                height: "30px",
                              }}
                            >
                              <span>Show on Photobook</span>
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
export default GuestsUploads;
