import { gql } from "@apollo/client";

const REFRESH_TOKEN = gql`
  query refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      refresh_token
      access_token
      access_expiry
    }
  }
`;

const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

// Get the refresh expiry resolved and move on to other tasks
export { LOGOUT_USER, REFRESH_TOKEN };
